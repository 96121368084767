import { Grid, Spinner } from "@chakra-ui/react";

import { AccountApi, AccountGroupApi, AccountGroupMembership } from "@loryth/api";
import { AlertApiError, ConfirmationModal } from "@loryth/components";
import { DeleteButton } from "@loryth/components/buttons/DeleteButton";

import { AccountAvatar, AccountSignature } from "../account";


export interface AccountGroupMemberPreviewProps {
    membership: AccountGroupMembership
}

export function AccountGroupMemberPreview({ membership }: AccountGroupMemberPreviewProps) {
    const {
        isUninitialized,
        isLoading,
        isError,
        data: account,
        error,
    } = AccountApi.useGetQuery(membership.account_id)
    const [deleteMembership] = AccountGroupApi.useDeleteMembershipMutation()

    const handleOnDelete = async () => {
        try {
            await deleteMembership({
                accountGroupId: membership.account_group_id,
                accountId: membership.account_id,
            }).unwrap()
        } catch (error){
            console.error("failed to delete membership", error)
        }
    }

    if (isUninitialized || isLoading) {
        return <Spinner/>
    }

    if (isError) {
        return <AlertApiError error={error}/>
    }

    return (
        <Grid
            rowGap={1}
            columnGap={4}
            gridTemplateColumns="auto 1fr auto"
        >
            <AccountAvatar account={account}/>
            <AccountSignature account={account}/>
            <ConfirmationModal
                title="Remove member"
                message="Are you sure?"
                trigger={<DeleteButton aria-label="Remove member"/>}
                onConfirm={handleOnDelete}
            />
        </Grid>
    )
}