import { ReactNode, useCallback, useRef, useState } from "react";
import { useLocation } from "wouter";

import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
} from "@chakra-ui/react";

import { useCommand } from "@loryth/commons/command";

import { useDeviceMode } from "../../index";
import { useWatch } from "../../useWatch";
import { Sidebar } from "../Sidebar";

import { ShellToggleSidebarCommand } from "./commands";

import { SidebarContext } from "../Sidebar/SidebarContext";
import { useShellContext } from "./ShellContext";

export interface ShellSidebarProps {
    autoCollapse?: boolean
    children: ReactNode
}

export function ShellSidebar({ autoCollapse = false, children }: ShellSidebarProps) {
    const [, forceUpdate] = useState({})
    const headerRef = useRef<HTMLElement | null>(null)
    const bodyRef = useRef<HTMLElement | null>(null)
    const footerRef = useRef<HTMLElement | null>(null)

    const { isDesktop } = useDeviceMode()
    const {
        sidebar: { isOpen, close: onClose, toggle }
    } = useShellContext()

    useCommand(ShellToggleSidebarCommand, () => {
        toggle()
    })

    // Collapse menu when location changes...
    const [location] = useLocation()
    const handleOnLocationChange = useCallback(() => {
        if (autoCollapse) {
            onClose()
        }
    }, [autoCollapse, onClose])
    useWatch(handleOnLocationChange, [location])

    if (isDesktop) {
        return (
            <Sidebar borderRightWidth={1}>
                {children}
            </Sidebar>
        )
    }

    return (
        <SidebarContext.Provider value={{ headerRef, bodyRef, footerRef }}>
            <Drawer
                isOpen={isOpen}
                onClose={onClose}
                placement="left"
                size="full"
            >
                <DrawerOverlay/>
                <DrawerContent>
                    <DrawerCloseButton/>
                    <DrawerHeader ref={ref => {
                        if (!ref) {
                            return
                        }

                        if (headerRef.current !== ref) {
                            headerRef.current = ref;
                            forceUpdate({})
                        }
                    }}/>
                    <DrawerBody ref={ref => {
                        if (!ref) {
                            return
                        }
                        if (bodyRef.current !== ref) {
                            bodyRef.current = ref;
                            forceUpdate({})
                        }
                    }}/>
                    <DrawerFooter ref={ref => {
                        if (!ref) {
                            return
                        }
                        if (footerRef.current !== ref) {
                            footerRef.current = ref;
                            forceUpdate({})
                        }
                    }}/>
                </DrawerContent>
            </Drawer>
            {children}
        </SidebarContext.Provider>
    )
}
