import { createContext } from "react";

export type MarkdownEditorMode = "source" | "preview"

export interface MarkdownEditorContextType {
    mode: MarkdownEditorMode

    setMode(value: MarkdownEditorMode): void
}

export const MarkdownEditorContext = createContext<MarkdownEditorContextType | undefined>(undefined)

