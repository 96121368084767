import { Grid, GridItem } from "@chakra-ui/react";

import { Dayjs } from "dayjs";
import { ReactNode, useMemo } from "react";

import { rotate } from "@loryth/commons/array";
import { Days } from "@loryth/commons/time";

export interface CalendarItem {
    date: Dayjs

    /** Week of calendar. DOES NOT match week of year or week of month. */
    week: number

    /** Day of week using js values (Monday=0, ..., Sunday=6). */
    day: number
}

export interface CalendarProps {
    start: Dayjs
    end: Dayjs

    /** Indicate the start of the week. Defaults to Monday (1). */
    startOfWeek?: number

    renderItem: (date: Dayjs) => ReactNode
}

// TODO(souperk): Rename, while this component is indeed a calendar, it's specifically a calendar
//  that puts dates on a week-day x month grid.
export function Calendar({ start, end, startOfWeek = 1, renderItem }: CalendarProps) {

    const weeksInPeriod = useMemo(() => {
        let current = start.add(1, "day")
        let weeks = 1
        while (current.isBefore(end) || current.isSame(end)) {
            if (current.day() === startOfWeek) {
                weeks += 1
            }
            current = current.add(1, "day")
        }

        return weeks
    }, [startOfWeek, start, end])

    const items = useMemo(() => {
        const results: CalendarItem[] = []
        let current = start
        let currentWeek = 1
        while (current.isBefore(end) || current.isSame()) {
            const currentDayOfWeek = (current.day() + 7 - startOfWeek) % 7
            results.push({ date: current, week: currentWeek, day: currentDayOfWeek })

            if (currentDayOfWeek === 6) {
                // current is the last day of the week, increase the week count
                //  so next values fall onto the next week.
                currentWeek += 1
            }

            current = current.add(1, "day")
        }
        return results
    }, [startOfWeek, start, end])

    const months = useMemo(() => {
        const results: { month: number; name: string; start: number; end: number }[] = []

        for (const item of items) {
            if (item.date.date() !== 1) {
                continue
            }
            if (results.length !== 0) {
                results[results.length - 1].end = item.week - 1
            }
            results.push({
                month: item.date.month(),
                name: item.date.format("MMMM"),
                start: item.week,
                end: item.week
            })
        }

        if (results.length !== 0) {
            const lastMonth = results[results.length - 1]
            if (lastMonth.start === lastMonth.end) {
                // The lastMonth was not completed, match its end to the last item of the calendar.
                const lastItem = items[items.length - 1]
                lastMonth.end = lastItem.week
            }
        }

        return results
    }, [items])

    const days = useMemo(() => rotate(Days, startOfWeek), [startOfWeek])

    return (
        <Grid
            gridTemplateRows="repeat(7, 1fr)"
            gridTemplateColumns={`min-content repeat(${weeksInPeriod}, min-content)`}
            alignItems="center"
        >
            {months.map(monthItem => (
                <GridItem
                    key={monthItem.month}
                    gridRow={1}
                    gridColumnStart={monthItem.start + 1}
                    gridColumnEnd={monthItem.end + 2}
                >
                    {monthItem.name}
                </GridItem>
            ))}

            {days.map((day, i) => (
                <GridItem key={`${day}/${i}`} gridRow={i + 2} gridColumn={1}>{day.slice(0, 3)}</GridItem>
            ))}

            {items.map(item => (
                <GridItem
                    key={item.date.format("DD-MM-YYYY")}
                    gridRow={item.day + 2}
                    gridColumn={item.week + 1}
                >
                    {renderItem(item.date)}
                </GridItem>
            ))}

        </Grid>
    )
}



