import { createCommandKey } from "@loryth/commons/command";

export const MarkdownFormatBoldCommand = createCommandKey("markdown:format:bold")
export const MarkdownFormatItalicCommand = createCommandKey("markdown:format:italic")
export const MarkdownFormatStrikethroughCommand = createCommandKey("markdown:format:strikethrough")
export const MarkdownFormatUnorderedListCommand = createCommandKey("markdown:format:ordered-list")
export const MarkdownFormatOrderedListCommand = createCommandKey("markdown:format:unordered-list")
export const MarkdownFormatCodeCommand = createCommandKey("markdown:format:code")
export const MarkdownFormatQuoteCommand = createCommandKey("markdown:format:quote")
export const MarkdownEditorIndentCommand = createCommandKey("markdown:format:indent")
export const MarkdownEditorIndentReverseCommand = createCommandKey("markdown:format:indent-reverse")