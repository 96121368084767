import { Spinner, Stack } from "@chakra-ui/react";

import { Task, TaskActivity, TaskApi } from "@loryth/api";
import { TimeElapsed } from "@loryth/components";
import { CommentEditor } from "../comment-editor/CommentEditor";
import { CommentPreview } from "../comment-preview/CommentPreview";

import { TaskStatusTag } from "../task-listing/TaskStatusTag";


export interface TaskActivitiesProps {
    task: Task
}

export function TaskActivities({ task }: TaskActivitiesProps) {
    const { isUninitialized, isLoading, data, isError,  } = TaskApi.useGetActivitiesQuery({
        taskId: task.id,
        commentScopeId: task.comment_scope_id,
    })
    if (isUninitialized || isLoading) {
        return <Spinner/>
    }

    if (isError) {
        return <>Oops, something went wrong...</>
    }

    return (
        <Stack gap={2}>
            <CommentEditor scopeId={task.comment_scope_id}/>
            {data.results.map(activity => (
                <TaskActivityPreview key={activity.id} task={task} activity={activity}/>
            ))}
        </Stack>
    )
}

export interface TaskActivityPreviewProps {
    task: Task
    activity: TaskActivity
}

function TaskActivityPreview({ activity }: TaskActivityPreviewProps) {
    if (activity.type === "status") {
        return (
            <Stack direction="row" justifyContent="space-between">
                <TimeElapsed
                    timestamp={activity.timestamp}
                    options={{ format: "compact", singleUnit: true }}
                    fontSize="xs"
                    fontFamily="Roboto Mono"
                />
                <TaskStatusTag status={activity.metadata.value}/>
            </Stack>
        )
    }
    if (activity.type === "comment") {
        return (
            <Stack>
                <TimeElapsed
                    timestamp={activity.timestamp}
                    options={{ format: "compact", singleUnit: true }}
                    fontSize="xs"
                    fontFamily="Roboto Mono"
                />
                <CommentPreview comment={activity.metadata}/>
            </Stack>
        )
    }

    return <></>
}