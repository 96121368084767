import { Journal } from "@loryth/api";
import { useState } from "react";
import { Button, Icon, IconButton, Input, InputGroup, InputLeftAddon, Stack, StackDivider } from "@chakra-ui/react";

import { Page, PageLayout, Section, SectionBody, SectionTitle } from "@loryth/components";
import { FilterIcon, NewIcon, SearchIcon } from "@loryth/components/icons";
import { JournalPreview } from "../journal-preview/JournalPreview";


const Journals: Omit<Journal, "created_at" | "updated_at">[] = [
    {
        id: "1",
        title: "Focus",
        description: "A journal that tracks the time you spend focused on various projects.",
        icon: "focus",
        color: "purple",
    },
    { id: "2", title: "Therapy", description: "", icon: null, color: null },
    { id: "3", title: "Sleep", description: "", icon: null, color: null },
    { id: "4", title: "Workout", description: "", icon: null, color: null },
]


export function JournalListingPage() {
    const [query, setQuery] = useState("")

    return (
        <Page>
            <PageLayout>
                <Section>
                    <SectionTitle>Journals</SectionTitle>
                    <SectionBody variant="toolbar">
                        <Button
                            variant="solid" colorScheme="teal"
                            leftIcon={<Icon as={NewIcon}/>}
                        >
                            New
                        </Button>

                        <InputGroup>
                            <InputLeftAddon>
                                <Icon as={SearchIcon}/>
                            </InputLeftAddon>
                            <Input
                                placeholder="Search..."
                                value={query}
                                onChange={event => setQuery(event.target.value)}
                            />
                        </InputGroup>

                        <IconButton
                            variant="ghost"
                            aria-label="filter"
                            icon={<Icon as={FilterIcon}/>}
                        />
                    </SectionBody>
                    <SectionBody variant="elevated">
                        <Stack spacing={2} divider={<StackDivider/>}>
                            {Journals.map(journal => (
                                <JournalPreview key={journal.id} journal={journal as Journal}/>
                            ))}
                        </Stack>
                    </SectionBody>
                </Section>
            </PageLayout>
        </Page>
    )
}