import { Redirect, Route, Router } from "wouter";

import { AccountAdminPage } from "../account-admin-page/AccountAdminPage";
import { CollectionItemListingPage } from "../collection-item-listing/CollectionItemListingPage";
import { FilesystemRoute } from "../filesystem/FilesystemRoute";

import { HomePage } from "../home/HomePage";
import { JournalListingPage } from "../journal-listing-page/JournalListingPage";
import { JournalPageRoute } from "../journal-page/JournalPageRoute";
import { PasswordRecoveryPage } from "../login/PasswordRecoveryPage";
import { LoginPage } from "../login/LoginPage";
import { RegistrationPage } from "../login/RegistrationPage";
import { ProjectListPage } from "../project-list/ProjectListPage";
import { ProjectRoute } from "../project-page/ProjectRoute";
import { AdminSettingsRoute } from "../settings-admin-page/AdminSettingsRoute";
import { TaskRoute } from "../task-page/TaskRoute";
import { TimerPageRoute } from "../timer-page/TimerPageRoute";

import { AuthenticatedRoute } from "./AuthenticatedRoute";


export function AppRouter() {
    return (
        <Router>
            <Route path="/login" component={LoginPage}/>
            <Route path="/register" component={RegistrationPage}/>
            <Route path="/password-recovery" component={PasswordRecoveryPage}/>
            <AuthenticatedRoute path="/home" children={<HomePage/>}/>
            <AuthenticatedRoute path="/project" component={ProjectListPage}/>
            <AuthenticatedRoute path="/project/:projectId/:tab?" component={ProjectRoute}/>
            <AuthenticatedRoute path="/task/:taskId" component={TaskRoute}/>
            <AuthenticatedRoute path="/timer/:timerId" component={TimerPageRoute}/>
            <AuthenticatedRoute path="/files" component={FilesystemRoute}/>
            <AuthenticatedRoute path="/files/:nodeId" component={FilesystemRoute}/>
            <AuthenticatedRoute path="/journal" component={JournalListingPage}/>
            <AuthenticatedRoute path="/journal/:journalId" component={JournalPageRoute}/>
            <AuthenticatedRoute path="/collection" component={CollectionItemListingPage}/>
            <AuthenticatedRoute path="/admin/account" component={AccountAdminPage}/>
            <AuthenticatedRoute path="/admin/settings" component={AdminSettingsRoute}/>
            <AuthenticatedRoute children={<Redirect to="/home"/>}/>
        </Router>
    )
}