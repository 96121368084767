import { useState } from "react"

import { Box, Center, Spinner, useMultiStyleConfig } from "@chakra-ui/react"

import { FilesystemApi, FilesystemNode } from "@loryth/api"
import {
    AlertApiError,
    Collection,
    CollectionItem,
    ViewMode,
    ViewModeInput
} from "@loryth/components"

import { FilesystemBreadcrumb, FilesystemNodePreview } from "@loryth/features/filesystem"


export interface FilesystemExplorerProps {
    initialLocation?: FilesystemNode | null
    location?: FilesystemNode | null
    onLocationChange?: (location: FilesystemNode | null) => void

    initialSelection?: FilesystemNode | null
    selection?: FilesystemNode | null
    onSelectionChange?: (selection: FilesystemNode | null) => void

    allowedTypes?: string[]
}

export function FilesystemExplorer({
    initialLocation,
    location: propLocation,
    onLocationChange,
    initialSelection,
    selection: propSelection,
    onSelectionChange,
    allowedTypes
}: FilesystemExplorerProps) {
    const [stateLocation, setLocation] = useState<FilesystemNode | null>(initialLocation ?? null)
    const [stateSelection, setSelection] = useState<FilesystemNode | null>(initialSelection ?? null)

    const location = propLocation !== undefined ? propLocation : stateLocation
    const selection = propSelection !== undefined ? propSelection : stateSelection

    const {
        isUninitialized: treeIsUninitialized,
        isLoading: treeIsLoading,
        isError: treeIsError,
        error: treeError,
        isSuccess: treeIsSuccess,
        data: treeData
    } = FilesystemApi.useGetTreeQuery({
        parentId: location?.id ?? null,
    })

    const [viewMode, setViewMode] = useState<ViewMode>("list")


    const handleOnNodeSelected = (node: FilesystemNode | null) => {
        if (node === null || node.type === "folder") {
            setLocation(node)
            onLocationChange?.(node)
        }
        if (matchNode(node, { allowedTypes })) {
            setSelection(node)
            onSelectionChange?.(node)
        }
    }

    const styles = useMultiStyleConfig("FilesystemExplorer")


    return (
        <Box
            className="loryth-filesystem-explorer"
            __css={styles.container}
        >
            <Box __css={styles.header}>
                <FilesystemBreadcrumb
                    nodeId={location?.id ?? null}
                    onItemClick={(_, node) => handleOnNodeSelected(node)}
                />
                <ViewModeInput
                    value={viewMode}
                    onChange={setViewMode}
                    ml="auto"
                    variant="ghost"
                    sx={{
                        ".chakra-button[data-active]": {
                            color: "teal.400",
                            bg: "transparent"
                        }
                    }}
                />
            </Box>

            <Box __css={styles.body}>
                {(treeIsUninitialized || treeIsLoading) && (
                    <Center>
                        <Spinner/>
                    </Center>
                )}
                {treeIsError && (
                    <AlertApiError error={treeError}/>
                )}
                {treeIsSuccess && (
                    <Collection variant={viewMode}>
                        {treeData.tree.filter(
                            node => matchNode(node, { includeFolders: true, allowedTypes })
                        ).map(node => (
                            <CollectionItem key={node.id}>
                                <FilesystemNodePreview
                                    node={node}
                                    isActive={node.id === selection?.id}
                                    onClick={() => handleOnNodeSelected(node)}
                                    cursor="pointer"
                                    paddingX={4}
                                    paddingY={2}
                                />
                            </CollectionItem>
                        ))}
                    </Collection>
                )}
            </Box>
        </Box>
    )
}

interface NodeCriteria {
    allowedTypes?: string[]
    includeFolders?: boolean
}

function matchNode(node: FilesystemNode | null, {
    allowedTypes,
    includeFolders = false
}: NodeCriteria) {
    const nodeType = node?.type ?? "folder"
    if (includeFolders && (nodeType) === "folder") {
        return true
    }

    if (allowedTypes && !allowedTypes.includes(nodeType)) {
        return false
    }

    return true
}
