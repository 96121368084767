import { Box, Button, Icon } from "@chakra-ui/react";

import { useCommandTrigger } from "@loryth/commons/command";
import { ShellToggleSidebarCommand } from "@loryth/components";
import { MenuIcon } from "@loryth/components/icons";


export function AppHeader() {
    const toggleMenu = useCommandTrigger(ShellToggleSidebarCommand)

    return (
        <Box
            className="loryth-header"
            bg="teal.600"
            color="white"
            px="2"
            py="1"
        >
            <Button
                variant="ghost"
                color="inherit"
                onClick={toggleMenu}
                _hover={{
                    bg: "rgba(255, 255, 255, 0.25)"
                }}
            >
                <Icon as={MenuIcon}/>
            </Button>
        </Box>
    )
}