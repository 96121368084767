import { Box, Stack, StackDivider } from "@chakra-ui/react";

import { Task } from "@loryth/api";
import { SectionBody } from "@loryth/components";
import { SectionBodyProps } from "@loryth/components/layout/Section/SectionBody";

import { TaskPreview } from "./TaskPreview";

export interface TaskListingView {
    mode: "list" | "cards"
}

const defaultView: TaskListingView = {
    mode: "list"
}

export interface TaskListingSectionBodyProps extends SectionBodyProps {
    tasks: Task[]
    view?: TaskListingView
}

export function TaskListingSectionBody({ tasks, view = defaultView, ...props }: TaskListingSectionBodyProps) {
    if (view.mode === "list") {
        return (
            <SectionBody variant="elevated" {...props}>
                <Stack divider={<StackDivider/>} spacing={0}>
                    {tasks.map(task => (
                        <Box
                            key={task.id}
                            px={4}
                            py={2}
                            _hover={{ bg: "teal.50" }}
                        >
                            <TaskPreview task={task}/>
                        </Box>
                    ))}
                </Stack>
            </SectionBody>
        )
    }

    return (
        <SectionBody {...props}>
            <Stack gap={2}>
                {tasks.map(task => (
                    <TaskPreview key={task.id} task={task} viewMode={view.mode}/>
                ))}
            </Stack>
        </SectionBody>
    )
}