import { createContext, useContext } from "react"
import { UseTimerWidgetChart } from "./useTimerWidgetChart"


export const TimerWidgetChartContext = createContext<UseTimerWidgetChart | null>(null)

export function useTimerWidgetChartContext() {
    const context = useContext(TimerWidgetChartContext)
    if (!context) {
        throw new Error("TimerWidgetChartContext is not initialized.")
    }
    return context
}

export const TimerWidgetChartProvider = TimerWidgetChartContext.Provider
