import { useState } from "react"
import { FaCheck } from "react-icons/fa6";
import {
    Grid,
    Icon,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Stack,
    Text,
} from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system"

import { IconBox } from "../IconBox"
import { findIconByName, groupIconsByCategory, searchIcons } from "../icons";
import { ColorOptions } from "./ColorInput";
import { SearchInput } from "./SearchInput"


export interface IconInputProps extends Omit<HTMLChakraProps<"div">, "color"> {
    "aria-label"?: string
    icon?: string | null
    onIconChange?: (icon: string | null) => void

    useColor?: boolean
    color?: string | null
    onColorChange?: (color: string | null) => void
}

export function IconInput({
    "aria-label": ariaLabel = "Icon",
    icon = null,
    onIconChange,
    useColor = false,
    color = null,
    onColorChange,
    ...props
}: IconInputProps) {
    // TODO(souperk): Add clear option.
    // TODO(souperk): Add search.
    // TODO(souperk): Close popover on select.
    // TODO(souperk): On hover display tooltip with icon name.
    // TODO(souperk): Group icons by category.
    // TODO(souperk): Support color options.
    const [query, setQuery] = useState<string>("")
    const iconCategories = groupIconsByCategory(searchIcons(query))

    return (
        <Popover>
            <PopoverTrigger>
                <IconBox
                    icon={icon}
                    colorScheme={color ?? "gray"}
                    as="button"
                    aria-label={ariaLabel}
                    _hover={{
                        backgroundColor: `${color ?? "gray"}.200`,
                        borderColor: `${color ?? "gray"}.500`,
                    }}
                    title={icon ?? undefined}
                    {...props}
                />
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow/>
                <PopoverCloseButton/>
                {useColor && (
                    <>
                        <PopoverHeader>Color</PopoverHeader>
                        <PopoverBody>
                            <Grid
                                gridTemplateColumns="repeat(5, max-content)"
                                rowGap={4}
                                columnGap={4}
                                justifyItems="center"
                                justifyContent="center"
                            >
                                {ColorOptions.map(option => (
                                    <IconButton
                                        key={option}
                                        variant="solid"
                                        colorScheme={option}
                                        onClick={() => onColorChange?.(option)}
                                        size="xs"
                                        icon={
                                            color === option ? <Icon as={FaCheck}/> : undefined
                                        }
                                        aria-label={`${option}`}
                                    />
                                ))}
                            </Grid>
                        </PopoverBody>
                    </>
                )}
                <PopoverHeader>Icon</PopoverHeader>
                <PopoverBody as={Stack} gap={2} maxHeight={320} overflow="auto">
                    <SearchInput value={query} onChange={event => setQuery(event.target.value)}/>
                    {iconCategories.map(category => (
                        <Stack gap={1}>
                            <Text
                                borderBottomColor="blackAlpha.200"
                                borderBottomWidth={1}
                            >
                                {category.category}
                            </Text>
                            <Grid gridTemplateColumns="repeat(auto-fill, minmax(2rem, 1fr))" gap={2}>
                                {category.icons.map(option => (
                                    <IconButton
                                        key={option.name}
                                        aria-label={option.name}
                                        onClick={() => onIconChange?.(option.name)}
                                        icon={<Icon as={findIconByName(option.name)}/>}
                                        isActive={option.name === icon}
                                        borderWidth={1}
                                        borderColor="transparent"
                                        _active={{
                                            color: "teal.400",
                                            borderColor: "teal.400",
                                            backgroundColor: "teal.50",
                                        }}
                                        title={option.name}
                                    />
                                ))}
                            </Grid>
                        </Stack>
                    ))}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}



