import { useCallback, useEffect, useState } from "react";

import { Button, Icon, Stack } from "@chakra-ui/react";

import { FilesystemNode, FilesystemApi } from "@loryth/api";
import { EditIcon, SaveIcon } from "@loryth/components/icons";
import { Page, MarkdownEditor, MarkdownText } from "@loryth/components";

import { FilesystemBreadcrumb } from "../filesystem/FilesystemBreadcrumb";

export interface NotebookNotePageProps {
    node: FilesystemNode
}

export function NotebookNotePage({ node }: NotebookNotePageProps) {
    const [editMode, setEditMode] = useState(false)
    const [content, setContent] = useState("")

    const { data, isSuccess } = FilesystemApi.useDownloadQuery(node.id)
    const [upload] = FilesystemApi.useUploadMutation()

    useEffect(() => {
        if (isSuccess) {
            setContent(data)
        }
    }, [isSuccess, data]);

    const handleSave = async () => {
        try {
            const blob = new Blob([content], { type: "text/plain" })
            const file = new File([blob], node.name, { type: "text/plain" })

            await upload({ nodeId: node.id, contents: file }).unwrap()
        } catch (error) {
            console.error("failed to upload file", error)
            return
        }

        setEditMode(false)
    }

    const handleContentChange = useCallback((value: string) => setContent(value), [])

    return (
        <Page title={`${node.name} - Notebook`} height="100%">
            <Stack gap={2} height="100%">
                <FilesystemBreadcrumb
                    nodeId={node.id}
                    to={node => node ? `/files/${node.id}` : "/files"}
                />
                <Stack direction="row">
                    {!editMode && (
                        <Button
                            colorScheme="teal"
                            leftIcon={<Icon as={EditIcon}/>}
                            onClick={() => setEditMode(true)}
                        >
                            Edit
                        </Button>
                    )}
                    {editMode && (
                        <Button colorScheme="teal" leftIcon={<Icon as={SaveIcon}/>} onClick={handleSave}>
                            Save
                        </Button>
                    )}
                </Stack>
                {!editMode && (
                    <MarkdownText>
                        {content}
                    </MarkdownText>
                )}
                {editMode && (
                    <MarkdownEditor
                        defaultValue={content}
                        onChange={handleContentChange}
                        flex="1"
                    />
                )}
            </Stack>
        </Page>
    )

}