import { Box, Icon, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";

import { ProjectApi, Project } from "@loryth/api";
import { ConfirmationModal, FormDialog } from "@loryth/components";
import { DeleteIcon, EditIcon, OptionsIcon } from "@loryth/components/icons";


import { ProjectForm } from "../project-form";


export interface ProjectActionsProps {
    project: Project
}

export function ProjectActions({ project }: ProjectActionsProps) {
    const [projectDelete] = ProjectApi.useDeleteMutation()

    const handleOnDelete = async () => {
        await projectDelete(project.id)
    }

    return (
        <Menu>
            <MenuButton>
                <Icon as={OptionsIcon}/>
            </MenuButton>
            <MenuList>
                <FormDialog
                    title="Edit Project"
                    form={<ProjectForm project={project}/>}
                    trigger={
                        <MenuItem icon={<Icon as={EditIcon}/>} color="teal">
                            Edit
                        </MenuItem>
                    }
                />
                <ConfirmationModal
                    title="Delete Project"
                    message={
                        <Box>
                            <Text>
                                Are you sure you want to delete <Text as="strong">{project.name}</Text>?
                            </Text>
                        </Box>
                    }
                    onConfirm={handleOnDelete}
                    trigger={
                        <MenuItem icon={<Icon as={DeleteIcon}/>} color="red">
                            Delete
                        </MenuItem>
                    }
                />
            </MenuList>
        </Menu>
    )
}