import { chakra, Theme, ThemeTypings, useTheme } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"


export interface ProgressCircleProps extends HTMLChakraProps<"svg"> {
    progress?: number
    circleGap?: number
    colorScheme?: ThemeTypings["colorSchemes"]
}

export function ProgressCircle({
    progress = 0,
    circleGap = 0.25,
    colorScheme = "teal",
    ...props
}: ProgressCircleProps) {
    const theme = useTheme<Theme>()
    const bgColor = theme.colors["gray"][400]
    const fgColor = theme.colors[colorScheme as keyof Theme["colors"]][500]
    const dotFillColor = theme.colors[colorScheme as keyof Theme["colors"]][400]

    // circumference = 2 * pi * radius
    const circumference = 236 * Math.PI
    const offset = circumference * circleGap
    const body = circumference - offset
    const rotation = -90 - (180 * offset / circumference)

    const dotAngle = circleGap + progress * (1 - circleGap)
    const dotX = 128 + (Math.sin((2 * Math.PI * -dotAngle) + Math.PI / 2) * 118)
    const dotY = 128 + (Math.cos((2 * Math.PI * -dotAngle) + Math.PI / 2) * 118)

    return (
        <chakra.svg viewBox="0 0 256 256" {...props}>
            <circle
                cx="128"
                cy="128"
                r="118"
                style={{
                    fill: "none",

                    stroke: bgColor,
                    strokeWidth: "10",
                    strokeLinecap: "butt",
                    strokeDasharray: `0 ${offset} ${body}`,
                    transform: `rotate(${rotation}deg)`,
                    transformOrigin: "50% 50%",
                }}
            />
            <circle
                cx="128"
                cy="128"
                r="118"
                style={{
                    fill: "none",

                    stroke: fgColor,
                    strokeWidth: "10",
                    strokeLinecap: "butt",
                    strokeDasharray: `0 ${offset} ${body * progress} ${body * (1 - progress)}`,
                    transform: `rotate(${rotation}deg)`,
                    transformOrigin: "50% 50%",
                }}
            />
            <circle
                cx={dotX}
                cy={dotY}
                r="5"
                style={{
                    fill: dotFillColor,

                    transform: `rotate(${rotation}deg)`,
                    transformOrigin: "50% 50%",
                }}
            />

        </chakra.svg>
    )
}