import { skipToken } from "@reduxjs/toolkit/query";

import { Breadcrumb, Skeleton } from "@chakra-ui/react";

import { ProjectApi, Task, TaskApi } from "@loryth/api";

import { ProjectBreadcrumbItem } from "../project-page/ProjectBreadcrumbItem";
import { TaskBreadcrumbItem } from "./TaskBreadcrumbItem";

type TaskOrTaskId = (
    { task: Task; taskId?: never }
    | { task?: never; taskId: string }
    )

export type TaskBreadcrumbProps = TaskOrTaskId

export function TaskBreadcrumb({ task, taskId }: TaskBreadcrumbProps) {
    const {
        isUninitialized,
        isLoading,
        data,
        isError
    } = TaskApi.useGetQuery(taskId ?? skipToken, { skip: !!task })
    const {
        isUninitialized: isProjectUninitialized,
        isLoading: isProjectLoading,
        data: project,
        isError: isProjectError,
    } = ProjectApi.useGetQuery(task?.project_id ?? data?.project_id ?? skipToken)


    if (!task) {
        if (isUninitialized || isLoading) {
            return <Skeleton noOfLines={2}/>
        }
        if (isError) {
            return (
                <>Oops, something went wrong...</>
            )
        }
        task = data
    }

    if (isProjectUninitialized || isProjectLoading) {
        return <Skeleton noOfLines={2}/>
    }

    if (isProjectError) {
        return (
            <>Oops, something went wrong...</>
        )
    }

    return (
        <Breadcrumb>
            <ProjectBreadcrumbItem project={project}/>
            <TaskBreadcrumbItem task={task}/>
        </Breadcrumb>
    )
}
