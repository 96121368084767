import { FilesystemNode } from "@loryth/api";

export function nodeExtension(node: FilesystemNode): string | null {
    const index = node.name.lastIndexOf(".")
    if (index === -1) {
        return null
    }
    return node.name.substring(index + 1)
}

export function nodeIsImage(node: FilesystemNode): boolean {
    if (node.type !== "file") {
        return false
    }
    const extension = nodeExtension(node)
    return extension !== null && ImageExtensions.includes(extension)
}

export const ImageExtensions = ["jpg", "png", "svg"]