import { Box, BoxProps, Heading, Text } from "@chakra-ui/react";

import { Account } from "@loryth/api";


export interface AccountSignatureProps extends BoxProps {
    account: Account
}

export function AccountSignature({ account, ...props }: AccountSignatureProps) {
    return (
        <Box {...props}>
            <Heading fontSize="md">
                {account.username}
            </Heading>
            <Text fontWeight="light" fontSize="xs" color="gray.500">
                {account.email}
            </Text>
        </Box>
    )
}