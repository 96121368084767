import { combineReducers, configureStore, UnknownAction, } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { LorythApi } from "@loryth/api";
import { TaskFeatureReducer } from "@loryth/features/task/task-feature.slice";

const combinedReducer = combineReducers({
    features: combineReducers({
        task: TaskFeatureReducer,
    }),
    [LorythApi.internal.reducerPath]: LorythApi.internal.reducer,
})

export type RootState = ReturnType<typeof combinedReducer>


export const store = configureStore({
    reducer: (state: Partial<RootState> = {}, action: UnknownAction) => {
        if (LorythApi.internal.util.resetApiState.match(action)) {
            const { [LorythApi.internal.reducerPath]: api } = state
            state = { [LorythApi.internal.reducerPath]: api }
        }
        return combinedReducer(state, action)
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(LorythApi.internal.middleware),
    devTools: true,
})

setupListeners(store.dispatch)

// export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector