import { useRef, useState } from "react";

import { Button, Icon, Spinner, useDisclosure } from "@chakra-ui/react";

import { Account, AccountApi } from "@loryth/api";
import {
    AlertApiError,
    SearchInput,
    Select,
    SelectContent,
    SelectTrigger,
    useControlledInput
} from "@loryth/components";
import { ExpandIcon } from "@loryth/components/icons";

import { AccountAvatar } from "./AccountAvatar";
import { AccountTag } from "./AccountTag";

export interface AccountSelectProps {
    value?: Account
    onChange?: (value: Account) => void
}

export function AccountSelect({ value: controlledValue, onChange }: AccountSelectProps) {
    const [value, setValue] = useControlledInput<Account>(controlledValue, onChange)
    const searchRef = useRef(null)
    const [query, setQuery] = useState<string>()

    const { isUninitialized, isLoading, isError, error, isSuccess, data: accounts } = AccountApi.useSearchQuery({
        query: query,
    })

    const { isOpen, onOpen, onClose } = useDisclosure()

    // TODO(souperk): Open popover in fullscreen mode on mobile devices.
    return (
        <Select isOpen={isOpen} onClose={onClose} initialFocusRef={searchRef}>
            <SelectTrigger>
                <Button
                    colorScheme="teal"
                    width="full"
                    onClick={onOpen}
                >
                    {value && (
                        <AccountAvatar
                            account={value}
                            size="xs"
                            ml={-1}
                            mr={2}
                        />
                    )}
                    {value?.username || "Select a user to invite"}
                    <Icon as={ExpandIcon} ml="auto" mr={-1}/>
                </Button>
            </SelectTrigger>
            <SelectContent title="Select a user to invite">
                <SearchInput
                    ref={searchRef}
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                />
                {(isUninitialized && isLoading) && (
                    <Spinner/>
                )}
                {isError && (
                    <AlertApiError error={error}/>
                )}
                {isSuccess && (
                    accounts.results.map(acc => (
                        <AccountTag
                            key={acc.id}
                            account={acc}
                            as={Button}
                            justifyContent="left"
                            onClick={() => {
                                setValue(acc)
                                onClose()
                            }}
                        />
                    ))
                )}
            </SelectContent>
        </Select>
    )
}