import { Box } from "@chakra-ui/react";
import { Dayjs } from "dayjs";
import { useMemo } from "react";
import { HabitHeatmapPopover } from "./HabitHeatmapPopover";

export interface HabitHeatmapItemProps {
    date: Dayjs
}

export function HabitHeatmapItem({ date }: HabitHeatmapItemProps) {
    const isDone = useMemo(() => Math.random() <= 0.2, [])
    return (
        <Box
            padding={0.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <HabitHeatmapPopover date={date}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"

                    width={8}
                    height={8}

                    cursor="pointer"
                    _hover={{
                        bg: isDone ? "gray.400" : "green.200"
                    }}

                    borderRadius={4}
                    bg={isDone ? "green.400" : "gray.200"}
                />
            </HabitHeatmapPopover>
        </Box>
    )
}