import { Button, Center, FormControl, FormHelperText, FormLabel, Heading, Input, Stack, Text } from "@chakra-ui/react";
import { isValidEmail } from "@loryth/commons/format";
import { Link, Page } from "@loryth/components";
import { useState } from "react";


export function PasswordRecoveryPage() {
    const [email, setEmail] = useState("")
    return (
        <Page title="Registration - Loryth" height="full">
            <Center paddingTop={{ lg: 160 }} height={{ base: "full", lg: "auto" }}>
                <Stack w={420}  maxW="100%">
                    <Heading>Password Recovery</Heading>
                    <Text>
                        We are sad to hear you are experiencing issues,
                        don't worry thought! <Text as="strong" textDecoration="underline">
                        We got you covered!</Text>
                    </Text>
                    <Text>Fill in the your email address below and a password
                        reset link will be sent to right in your inbox.</Text>
                    <FormControl>
                        <FormLabel>Email:</FormLabel>
                        <Input value={email} onChange={event => setEmail(event.target.value)}/>
                        <FormHelperText>Make sure the address matches your accounts address.</FormHelperText>
                    </FormControl>
                    <Button variant="solid" colorScheme="teal" isDisabled={!isValidEmail(email)}>
                        Submit
                    </Button>
                    <Text>Don't have an account yet? <Link to="/register" color="teal.500">Sign up</Link></Text>
                </Stack>
            </Center>
        </Page>
    )

}

