import { Card, CardBody, CardHeader, Grid, GridItem, Stack, Text } from "@chakra-ui/react";

import { Timer, TimerApi } from "@loryth/api";
import { Timespan } from "@loryth/components";

export interface TimerPageActivityProps {
    timer: Timer
}

export function TimerPageActivity({ timer }: TimerPageActivityProps) {
    const { isSuccess, data } = TimerApi.useSearchLogsQuery({
        timerId: timer.id
    })

    return (
        <Card>
            <CardHeader>
                Activity:
            </CardHeader>
            <CardBody>
                {isSuccess && (
                    <Stack gap={2}>
                        {data.results.map(log => (
                            <Grid
                                key={log.id}
                                gridTemplateAreas={`
                                    "status duration"
                                `}
                                gridTemplateColumns="1fr auto"
                            >
                                <GridItem gridArea="status">
                                    <Text variant="h6">{log.status}</Text>
                                </GridItem>

                                <GridItem gridArea="duration">
                                    <Timespan value={log.duration * 1000}/>
                                </GridItem>
                            </Grid>
                        ))}
                    </Stack>
                )}
            </CardBody>
        </Card>
    )
}
