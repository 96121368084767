import { lorythApi } from "./_api";
import { Task, TaskActivity, TaskOrder, TaskStatus } from "./task";

export interface TaskSearchRequest {
    projects?: string[]
    query?: string
    status?: TaskStatus[]
    order?: TaskOrder
    types?: string[]
}

export interface TaskListingResponse {
    results: Task[]
    page_number: number
    page_size: number
    total: number
}

export interface TaskUpsertRequest {
    taskId?: string
    data: {
        project_id: string
        type_id: string
        title: string
        description: string
        status: TaskStatus
    }
}

export interface TaskActivitiesRequest {
    taskId: string
    commentScopeId?: string
}

export interface TaskActivitiesResponse {
    results: TaskActivity[]
    page_size: number
    page_number: number
    total: number
}

const directionMap = {
    ascending: "asc",
    descending: "desc",
}

const taskApi = lorythApi.enhanceEndpoints({
    addTagTypes: ["task"]
}).injectEndpoints({
    endpoints: builder => ({
        taskSearch: builder.query<TaskListingResponse, TaskSearchRequest>({
            query: ({ projects, query, status, types, order }) => ({
                url: `/api/v1/task`,
                params: {
                    query: query,
                    ...(!projects?.length ? {} : { project: projects }),
                    ...(!status?.length ? {} : { status: status }),
                    ...(!types?.length ? {} : { type: types }),
                    ...(!order ? {} : { order_by: `${order.property}+${directionMap[order.direction]}` }),
                }
            }),
            providesTags: (response) =>
                response ? [
                    ...response.results.map(task => ({ type: "task" as const, id: task.id })),
                    "task",
                ] : ["task"],
        }),
        taskGet: builder.query<Task, string>({
            query: taskId => `/api/v1/task/${taskId}`,
            providesTags: response =>
                response ? [{ type: "task", id: response.id }, "task"]
                    : ["task"]
        }),
        taskUpsert: builder.mutation<Task, TaskUpsertRequest>({
            query: request => ({
                url: !request.taskId
                    ? "/api/v1/task"
                    : `/api/v1/task/${request.taskId}`,
                method: !request.taskId ? "post" : "put",
                body: request.data,
            }),
            invalidatesTags: response =>
                response ? [
                    "task",
                    { type: "task", id: response.id }
                ] : ["task"],
        }),
        taskDelete: builder.mutation<void, string>({
            query: taskId => ({
                url: `/api/v1/task/${taskId}`,
                method: "delete"
            }),
            invalidatesTags: (response, _, request) =>
                response ? [{ type: "task", id: request }, "task"]
                    : ["task"],
        }),
        taskGetActivities: builder.query<TaskActivitiesResponse, TaskActivitiesRequest>({
            query: ({ taskId }) => ({
                url: `/api/v1/task/${taskId}/activity`
            }),
            providesTags: (_response, _error, request) => [{
                type: "task" as const,
                id: request.taskId,
            }, ...(request.commentScopeId ? [{
                type: "scope" as const,
                id: `comment/${request.commentScopeId}`,
            }] : [])],
        })
    })
})

export const TaskApi = {
    _internal: taskApi,
    useSearchQuery: taskApi.useTaskSearchQuery,
    useGetQuery: taskApi.useTaskGetQuery,
    useUpsertMutation: taskApi.useTaskUpsertMutation,
    useDeleteMutation: taskApi.useTaskDeleteMutation,
    useGetActivitiesQuery: taskApi.useTaskGetActivitiesQuery,
}
