import { Card, CardBody, Stack } from "@chakra-ui/react";

import { SectionTitle } from "@loryth/components";

import { HabitPicker } from "../habit-picker/HabitPicker";
import { HabitHeatmap } from "./HabitHeatmap";


export function HabitWidget() {

    return (
        <Stack gap={2}>
            <SectionTitle>Habits</SectionTitle>

            <Card>
                <CardBody>
                    <HabitPicker/>
                    <HabitHeatmap/>
                </CardBody>
            </Card>
        </Stack>
    )
}

