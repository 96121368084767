/**
 * Extends selection so that starts at the start-of-line and ends at end-of-line.
 *
 * @param text
 * @param start
 * @param end
 */
function selectLines(text: string, start: number, end: number): [number, number, undefined] {
    // Find the start of the first line selected.
    let newStart = text.substring(0, start).lastIndexOf("\n");
    if (newStart === -1) {
        newStart = 0;
    } else {
        // Point `newStart` to the first character of the first line selected.
        newStart += 1;
    }

    // Find the end of the last line selected.
    let newEnd = end;
    const index = text.substring(newEnd).indexOf("\n");
    if (index === -1) {
        newEnd = text.length;
    } else {
        // Point `newEnd` to the last character of the last line selected.
        newEnd = newEnd + index;
    }

    return [newStart, newEnd, undefined];
}

/**
 * Creates a transformation that will wrap any input text with specified prefix and/or suffix. If the input
 * text is already wrapped with the prefix/suffix, then they will be removed instead.
 * @param prefix
 * @param suffix - Optional, if not provided the prefix value will be used.
 */
function wrap(prefix: string, suffix?: string) {
    return (text: string) => {
        if (suffix === undefined) {
            suffix = prefix;
        }

        if (text.startsWith(prefix) && text.endsWith(suffix)) {
            return text.substring(prefix.length, text.length - suffix.length);
        } else {
            return prefix + text + suffix;
        }
    }
}

interface NewlineContext {
    isSelection: boolean
    original: { start: number; end: number }
}

function newlineSelection(text: string, start: number, end: number): [number, number, NewlineContext] {
    if (start !== end) {
        // Selections are not supported.
        return [start, end, {
            isSelection: true,
            original: { start, end }
        }]
    }
    const [newStart, newEnd] = selectLines(text, start, end)
    return [newStart, newEnd, {
        isSelection: false,
        original: { start: start - newStart, end: end - newStart }
    }]
}

function newlineReplace(text: string, { isSelection, original }: NewlineContext) {
    if (isSelection) {
        // Operation is not supported, replace selection with newline (default behavior).
        return "\n"
    }

    let insertion = "\n"
    let match
    if ((match = text.match(/^(\s*)(\d+)\.\s+(.*)$/))) {
        // Matched ordered list item.
        const [, ident, num, rest] = match
        if (!rest) {
            // If the list item is empty, empty the line.
            return ""
        }
        const next = parseInt(num, 10) + 1
        insertion = "\n" + ident + next.toString() + ". "
    } else if ((match = text.match(/^(\s*)(\*|-)\s+(.*)$/))) {
        // Matched unordered list item.
        const [, ident, char, rest] = match
        if (!rest) {
            // If the list item is empty, empty the line.
            insertion = ""
        } else {
            insertion = "\n" + ident + char + " "
        }
    }

    return (
        text.substring(0, original.start)
        + insertion
        + text.substring(original.end)
    )
}

/**
 * Creates a transformation that will prefix each line of the input text with
 *  the specified prefix. If the mode is "toggle" and each line of the input text contains
 *  the prefix, then it will be removed instead.
 *
 * @param prefix
 * @param mode
 */
function prefixLines(prefix: string, mode: "add" | "remove" | "toggle" = "toggle") {
    return (text: string) => {
        const lines = text.split("\n");
        const isQuoted = lines.findIndex(x => !x.startsWith(prefix)) === -1;

        if (isQuoted && (mode === "remove" || mode === "toggle")) {
            // remove 1st character (which is ">")
            return lines.map(x => x.substring(prefix.length)).join("\n");
        } else if (mode === "add" || mode === "toggle") {
            // append decorator to each line
            return lines.map(x => prefix + x).join("\n");
        } else {
            return text;
        }
    }
}

interface OperationOptions<Context> {
    /**
     * Affects how the selection will be updated after operation has completed. The start/end
     *  values place the cursor at the start/end of the new text, select marks the whole text.
     */
    displacement?: "start" | "end" | "middle" | "select" | number;

    insert?: string

    replace(text: string, context?: Context): string;

    selection?(text: string, start: number, end: number): [number, number, Context];
}

function operation<Context>(options: OperationOptions<Context>) {
    return (element: HTMLTextAreaElement) => {
        // Retrieve textarea selection.
        const text = element.value
        const selectionStart = element.selectionStart
        const selectionEnd = element.selectionEnd

        // If available use operation selection override.
        let opSelectionStart = selectionStart
        let opSelectionEnd = selectionEnd
        let opContext: Context | undefined = undefined
        if (options.selection) {
            [opSelectionStart, opSelectionEnd, opContext] = options.selection(
                text,
                selectionStart,
                selectionEnd,
            )
        }

        // TODO(souperk): Include support for operations that don't require a selection.
        //  Examples:
        //      1. Starting a list
        //      2. Starting a border text.
        const selectedText = text.substring(
            opSelectionStart,
            opSelectionEnd
        )

        let replaceText: string
        if (opSelectionStart === opSelectionEnd) {
            if (!options.insert) {
                return
            }
            replaceText = options.insert
        } else {
            replaceText = options.replace(selectedText, opContext)
        }

        const newText = text.substring(0, opSelectionStart)
            + replaceText
            + text.substring(opSelectionEnd)


        // Update selection based on displacement option.
        let newSelectionStart = selectionStart
        let newSelectionEnd = selectionEnd
        const displacement = replaceText.length - selectedText.length
        if (options.displacement === "select" || selectionStart !== selectionEnd) {
            // If there is a selection from the user, modify it to contain the new text.
            newSelectionStart = opSelectionStart
            newSelectionEnd = opSelectionEnd + displacement
        } else if (options.displacement === "start") {
            // Move the cursor to the start of the updated text.
            newSelectionStart = (selectionStart || 0) + displacement
            newSelectionEnd = (selectionStart || 0) + displacement
        } else if (options.displacement === "end") {
            newSelectionStart = (selectionEnd || 0) + displacement
            newSelectionEnd = (selectionEnd || 0) + displacement
        } else if (typeof options.displacement === "number" && options.displacement > 0) {
            newSelectionStart = (selectionStart || 0) + options.displacement
            newSelectionEnd = (selectionStart || 0) + options.displacement
        } else if (typeof options.displacement === "number" && options.displacement < 0) {
            newSelectionStart = (selectionEnd || 0) - options.displacement
            newSelectionEnd = (selectionEnd || 0) - options.displacement
        }

        return {
            text: newText,
            selectionStart: newSelectionStart,
            selectionEnd: newSelectionEnd,
        }
    }
}

export type TextareaOperation = ReturnType<typeof operation>;
export const MarkdownOperations = {
    Indent: operation({
        selection: selectLines,
        replace: prefixLines("   ", "add"),
        displacement: "start",
    }),

    IndentReverse: operation({
        replace: prefixLines("   ", "remove"),
        selection: selectLines,
        displacement: "start",
    }),

    Bold: operation({
        displacement: -2,
        insert: "****",
        replace: wrap("**")
    }),
    Italic: operation({
        displacement: -1,
        insert: "**",
        replace: wrap("*")
    }),
    Strikethrough: operation({
        displacement: -2,
        insert: "~~~~",
        replace: wrap("~~")
    }),

// TODO(souperk): Improve code action to support:
//      1. inline code (single backtick quotes)
//      2. block code (three backtick quotes)
//          a. minimize selection to non-empty lines
//     Code: operation({ replace: wrap("\n```\n") }),
    CodeBlock: operation({
        selection: selectLines,
        replace(text: string): string {
            if (text.startsWith("```\n") && text.endsWith("```\n")) {
                // remove first and last line
                const lines = text.split("\n");
                return lines.slice(1, lines.length - 2).join("\n");
            } else {
                return "```\n" + text + "\n```\n";
            }
        },
    }),
    CodeInline: operation({
        displacement: -1,
        insert: "``",
        replace: wrap("`"),
    }),

    BlockQuotes: operation({
        displacement: 2,
        selection: selectLines,
        insert: "> ",
        replace: prefixLines("> "),
    }),

    OrderedList: operation({
        selection: selectLines,
        replace: prefixLines("1. "),
    }),
    UnorderedList: operation({
        selection: selectLines,
        replace: prefixLines("* "),
    }),

    Newline: operation({
        displacement: "start",
        selection: newlineSelection,
        insert: "\n",
        replace: newlineReplace,
    })
}