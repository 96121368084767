import { TimerApi } from "@loryth/api";
import { LoadingPage, Page } from "@loryth/components";
import { TimerPage } from "./TimerPage";


export interface TimerPageRouteProps {
    params: {
        timerId: string
    }
}

export function TimerPageRoute({ params: { timerId } }: TimerPageRouteProps) {
    const { isUninitialized, isLoading, isError, data: timer } = TimerApi.useGetQuery(timerId)

    if (isUninitialized || isLoading) {
        return <LoadingPage/>
    }

    if (isError) {
        return (
            <Page title="Not found - Loryth">
                Oops, something went wrong....
            </Page>
        )
    }

    return <TimerPage timer={timer}/>
}