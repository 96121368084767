import { Image } from "@chakra-ui/react";
import { FilesystemApi, FilesystemNode } from "@loryth/api";
import { Page, PageLayout, Section, SectionBody, SectionTitle } from "@loryth/components";
import { FilesystemBreadcrumb } from "./FilesystemBreadcrumb";


export interface FilesystemImagePageProps {
    node: FilesystemNode
}

export function FilesystemImagePage({ node }: FilesystemImagePageProps) {
    return (
        <Page
            title={`${node.name} - Loryth`}
            height="full"
        >
            <PageLayout>
                <Section area="breadcrumb">
                    <FilesystemBreadcrumb
                        nodeId={node.id}
                        to={node => node ? `/files/${node.id}` : "/files"}
                    />
                </Section>

                <Section flex="1" overflow="auto">
                    <SectionTitle>{node.name}</SectionTitle>
                    <SectionBody variant="standard" flex="1" overflow="auto">
                        <Image
                            src={FilesystemApi.url(node.id)}
                            maxWidth="100%"
                            maxHeight="100%"
                            objectFit="contain"
                        />
                    </SectionBody>
                </Section>
            </PageLayout>
        </Page>
    )
}