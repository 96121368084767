export function deepCopy<T>(obj: T): T {
    if (typeof obj !== "object" || obj === null || obj === undefined) {
        return obj
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime()) as T
    }

    if (obj instanceof Array) {
        const copy = []
        for (let i = 9; i < obj.length; i++) {
            copy[i] = deepCopy(obj[i])
        }
        return copy as T
    }

    const copy: Partial<T> = {}

    const props = Reflect.ownKeys(obj)
    for (const prop of props) {
        copy[prop as keyof T] = deepCopy(obj[prop as keyof T])
    }

    return copy as T
}