import { Stack } from "@chakra-ui/react";

import { Page } from "@loryth/components";

import { HabitWidget } from "../habit-widget/HabitWidget";
import { TimerWidget } from "../timer-widget";


export function HomePage() {
    return (
        <Page>
            <Stack gap={8}>
                <TimerWidget/>
                <HabitWidget/>
            </Stack>
        </Page>
    )
}