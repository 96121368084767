import { Journal } from "@loryth/api";
import { JournalPage } from "./JournalPage";


export interface JournalPageRouteProps {
    params: {
        journalId: string
    }
}

const Journals: Omit<Journal, "created_at" | "updated_at">[] = [
    {
        id: "1",
        title: "Focus",
        description: "A journal that tracks the time you spend focused on various projects.",
        icon: "focus",
        color: "purple",
    },
    { id: "2", title: "Therapy", description: "", icon: null, color: null },
    { id: "3", title: "Sleep", description: "", icon: null, color: null },
    { id: "4", title: "Workout", description: "", icon: null, color: null },
]

export function JournalPageRoute({ params: { journalId } }: JournalPageRouteProps) {
    return <JournalPage journal={Journals.find(x => x.id === journalId) as Journal}/>
}