import { defineStyleConfig } from "@chakra-ui/react";


export const SectionBodyStyle = defineStyleConfig({
    baseStyle: {
        display: "flex",
        flexDirection: "column",
        gap: 2,
        flex: 1,
    },
    variants: {
        standard: {},
        elevated: {
            px: 4,
            py: 2,
            bg: "white",
            borderRadius: 4,
            borderWidth: 1,
        },
        toolbar: {
            flex:0,

            flexDirection: "row",
        },
    },
    defaultProps: {
        variant: "standard",
    }
})