import { Spinner } from "@chakra-ui/react";

import { AccountGroupApi } from "@loryth/api";
import {
    AlertApiError,
    FormDialog,
    NewButton,
    SearchInput,
    Section,
    SectionBody,
    SectionTitle
} from "@loryth/components";

import { AccountGroupMemberList } from "./AccountGroupMemberList";
import { AddGroupMemberForm } from "./AddGroupMemberForm";

export interface AccountGroupMembersSectionProps {
    title?: string
    accountGroupId: string
}

export function AccountGroupMembersSection({
    accountGroupId,
}: AccountGroupMembersSectionProps) {
    const groupQuery = AccountGroupApi.useGetQuery(accountGroupId)
    const membershipsQuery = AccountGroupApi.useGetMembershipsQuery(accountGroupId)

    if (groupQuery.isUninitialized || groupQuery.isLoading || membershipsQuery.isUninitialized || membershipsQuery.isLoading) {
        return (
            <Section>
                <SectionTitle icon={<Spinner/>}>
                    Members
                </SectionTitle>
            </Section>
        )
    }

    if (groupQuery.isError) {
        return (
            <Section>
                <SectionTitle>Members</SectionTitle>
                <SectionBody>
                    <AlertApiError error={groupQuery.error}/>
                </SectionBody>
            </Section>
        )
    }

    if (membershipsQuery.isError) {
        return (
            <Section>
                <SectionTitle>Members</SectionTitle>
                <SectionBody>
                    <AlertApiError error={membershipsQuery.error}/>
                </SectionBody>
            </Section>
        )
    }

    return (
        <Section>
            <SectionTitle>
                Members
            </SectionTitle>
            <SectionBody variant="toolbar">
                <FormDialog
                    title="Add group member"
                    form={<AddGroupMemberForm accountGroup={groupQuery.data}/>}
                    trigger={<NewButton>Invite</NewButton>}
                />
                <SearchInput/>
            </SectionBody>
            <SectionBody variant="elevated">
                <AccountGroupMemberList memberships={membershipsQuery.data.results}/>
            </SectionBody>
        </Section>)
}