import { createElement, ReactNode, useEffect } from "react";
import { DefaultParams, Path, Route, RouteProps, useLocation } from "wouter";

import { Center, Stack, Text } from "@chakra-ui/react";

import { AccountApi } from "@loryth/api";
import { LoadingPage } from "@loryth/components";

export function AuthenticatedRoute<
    T extends DefaultParams | undefined = undefined,
    RoutePath extends Path = Path
>({ component, children, ...props }: RouteProps<T, RoutePath>) {
    if (component) {
        return <Route
            {...props}
            component={
                props => (
                    <AuthGuard>
                        {createElement(component, props as unknown as any)}
                    </AuthGuard>
                )
            }
        />
    }
    if (children instanceof Function) {
        return <Route
            {...props}
            children={
                props => (
                    <AuthGuard>
                        {children(props as unknown as any)}
                    </AuthGuard>
                )
            }
        />

    }

    return (
        <Route
            {...props}
        >
            <AuthGuard>
                {children}
            </AuthGuard>
        </Route>
    )

}


interface AuthGuardProps {
    children: ReactNode
}

export function AuthGuard({ children }: AuthGuardProps) {
    const [location, navigate] = useLocation()
    const { isUninitialized, isLoading, isFetching, isError, error } = AccountApi.useMeQuery()

    useEffect(() => {
        if (isFetching) {
            return
        }
        if (isError && "status" in error && error.status === 401) {
            // TODO(souperk): Setup returnUrl
            navigate("/login")
        }
    }, [isFetching, isError, error, location, navigate]);


    if (isUninitialized || isLoading) {
        return (
            <LoadingPage/>
        )
    }

    if (isError) {
        return (
            <Center>
                <Stack spacing={4}>
                    <Text>Error: </Text>
                    <Text>Please try again later.</Text>
                </Stack>
            </Center>
        )
    }

    return children
}