import { lorythApi } from "./_api";
import { TaskType } from "./task-type";

interface TaskTypeListingResponse {
    results: TaskType[]
    page_size: number
    page_number: number
    total: number
}

interface TaskTypeSearchRequest {
}

interface TaskTypeCreateRequest {
    taskTypeId?: undefined
    data: {
        title: string
        description: string
        icon: string | null
        color: string | null
        allow_children: boolean
    }
}

interface TaskTypeUpdateRequest {
    taskTypeId: string
    data: {
        title: string
        description: string
        icon: string | null
        color: string | null
        allow_children: boolean
    }
}

type TaskTypeUpsertRequest = TaskTypeCreateRequest | TaskTypeUpdateRequest

interface TaskTypeDeleteRequest {
    taskTypeId: string
}


const taskTypeApi = lorythApi.enhanceEndpoints({
    addTagTypes: ["task-type"],
}).injectEndpoints({
    endpoints: builder => ({
        taskTypeGet: builder.query<TaskType, string>({
            query: (request) => ({
                url: `/api/v1/task-type/${request}`,
                method: "get",
            }),
            providesTags: ["task-type"],
        }),
        taskTypeSearch: builder.query<TaskTypeListingResponse, TaskTypeSearchRequest>({
            query: () => ({
                url: "/api/v1/task-type",
                method: "get",
            }),
            providesTags: ["task-type"],
        }),
        taskTypeUpsert: builder.mutation<TaskType, TaskTypeUpsertRequest>({
            query: request => ({
                url: !request.taskTypeId
                    ? `/api/v1/task-type`
                    : `/api/v1/task-type/${request.taskTypeId}`,
                method: !request.taskTypeId ? "post" : "put",
                body: request.data,
            }),
            invalidatesTags: ["task-type"],
        }),
        taskTypeDelete: builder.mutation<void, TaskTypeDeleteRequest>({
            query: request => ({
                url: `/api/v1/task-type/${request.taskTypeId}`,
                method: "delete"
            }),
            invalidatesTags: ["task-type"],
        })
    })
})

export const TaskTypeApi = {
    _internal: taskTypeApi,
    useGetQuery: taskTypeApi.useTaskTypeGetQuery,
    useSearchQuery: taskTypeApi.useTaskTypeSearchQuery,
    useUpsertMutation: taskTypeApi.useTaskTypeUpsertMutation,
    useDeleteMutation: taskTypeApi.useTaskTypeDeleteMutation,
}