import { useCallback, useRef, useState } from "react";

type DebounceFn = (callback: () => void) => void

export interface UseDebounceOptions {
    delay?: number
}

export function useDebounce({ delay = 200 }: UseDebounceOptions = {}): [DebounceFn, boolean] {
    const ref = useRef<number>()
    const [pending, setPending] = useState(false)
    const debounce = useCallback((callback: () => void) => {
        if (ref.current) {
            clearTimeout(ref.current)
        }
        setPending(true)
        ref.current = setTimeout(() => {
            setPending(false)
            callback()
        }, delay)
    }, [delay])

    return [debounce, pending]
}