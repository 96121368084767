import { useBreakpointValue } from "@chakra-ui/react";


export type DeviceMode = "mobile" | "tablet" | "desktop"


type UseDeviceModeResult = {
    mode: DeviceMode
} & ({
    isMobile: true
    isTablet: false
    isDesktop: false
} | {
    isMobile: false
    isTablet: true
    isDesktop: false
} | {
    isMobile: false
    isTablet: false
    isDesktop: true
})

export function useDeviceMode(): UseDeviceModeResult {
    const mode = useBreakpointValue<DeviceMode>({
        base: "mobile",
        md: "tablet",
        lg: "desktop",
    })

    return {
        mode: mode,
        isMobile: mode === "mobile",
        isTablet: mode === "tablet",
        isDesktop: mode === "desktop"
    } as UseDeviceModeResult
}
