import {
    ChangeEvent,
    forwardRef,
    KeyboardEvent,
    useCallback,
    useContext, useEffect,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import { Box, BoxProps, Textarea, useStyleConfig } from "@chakra-ui/react";

import { CommandContext, useCommand, useCommandRegistry } from "@loryth/commons/command";
import { useHistory, HistoryChangeEvent } from "@loryth/components";

import {
    MarkdownEditorIndentCommand,
    MarkdownEditorIndentReverseCommand,
    MarkdownFormatBoldCommand,
    MarkdownFormatItalicCommand,
    MarkdownFormatOrderedListCommand,
    MarkdownFormatStrikethroughCommand,
    MarkdownFormatUnorderedListCommand
} from "./commands";
import { MarkdownEditorActions } from "./MarkdownEditorActions";
import { MarkdownEditorContext, MarkdownEditorMode } from "./MarkdownEditorContext";
import { MarkdownText } from "./MarkdownText";
import { MarkdownOperations, TextareaOperation } from "./transformations";

export interface MarkdownEditorRef {
    value: string
    reset: (value?: string) => void
}

export interface MarkdownEditorProps extends Omit<BoxProps, "ref" | "value" | "onChange"> {
    defaultValue?: string
    onChange?: (value: string) => void

    isDisabled?: boolean
    allowResize?: boolean
}

export const MarkdownEditor = forwardRef<MarkdownEditorRef, MarkdownEditorProps>(function MarkdownEditor({
    defaultValue = "",
    onChange,
    isDisabled = false,
    allowResize = false,
    ...props
}, ref) {
    const registry = useCommandRegistry({ create: true })

    const [_value] = useState<string>(defaultValue)
    const [mode, setMode] = useState<MarkdownEditorMode>("source")

    const styles = useStyleConfig("MarkdownEditor")

    return (
        <MarkdownEditorContext.Provider value={{ mode, setMode }}>
            <CommandContext.Provider value={registry}>
                <Box
                    className="loryth-markdown-editor"
                    __css={styles}
                    {...props}
                >
                    <MarkdownEditorActions/>

                    <Box className="loryth-markdown-editor-body" gridArea="editor-body" display="flex">
                        <BaseMarkdownEditor
                            ref={ref}
                            defaultValue={defaultValue}
                            value={_value}
                            onChange={onChange}
                            isDisabled={isDisabled}
                            allowResize={allowResize}
                        />
                    </Box>
                </Box>
            </CommandContext.Provider>
        </MarkdownEditorContext.Provider>
    )
})

interface EditorState {
    text: string
    selectionStart: number
    selectionEnd: number
}

interface BaseMarkdownEditorProps {
    defaultValue: string
    value: string
    onChange?: (value: string) => void

    isDisabled: boolean
    allowResize: boolean
}

const BaseMarkdownEditor = forwardRef<MarkdownEditorRef, BaseMarkdownEditorProps>(function BaseMarkdownEditor({
    value,
    onChange,
    isDisabled,
    allowResize,
}, ref) {
    const context = useContext(MarkdownEditorContext)
    if (!context) {
        throw new Error("MarkdownEditorContext is not defined")
    }
    const { mode } = context

    const $element = useRef<HTMLTextAreaElement>(null)

    const onChangeRef = useRef(onChange)
    useEffect(() => {
        onChangeRef.current = onChange
        return () => {
            onChangeRef.current = undefined
        }
    }, [onChange]);

    const updateState = useCallback((event: HistoryChangeEvent<EditorState>) => {
        const { text, selectionStart, selectionEnd } = event.state
        const textarea = $element.current
        if (textarea) {
            // Update textarea value.
            if (event.type !== "change") {
                textarea.value = text
            }

            // Update textarea selection. Usually occurs after an operation
            //  has been performed.
            textarea.setSelectionRange(selectionStart, selectionEnd)
        }

        // Dispatch value change.
        if (onChangeRef.current && (event.type === "change" || event.type === null)) {
            onChangeRef.current(text)
        }
    }, [])

    const {
        push: historyPush,
        undo: historyUndo,
        redo: historyRedo,
        reset: historyReset,
        current: { text }
    } = useHistory({ text: value, selectionStart: 0, selectionEnd: 0 }, {
        onChange: updateState,
    });

    useImperativeHandle(ref, () => {
        return {
            get value() {
                if (!$element.current) {
                    return ""
                }
                return $element.current.value
            },
            set value(value: string) {
                if (!$element.current) {
                    return
                }
                $element.current.value = value
                historyPush({
                    text: value,
                    selectionStart: $element.current.selectionStart,
                    selectionEnd: $element.current.selectionEnd,
                }, "change")
            },
            reset(value?: string) {
                if (!$element.current) {
                    return
                }

                if (value !== undefined) {
                    historyReset(
                        { text: value, selectionStart: 0, selectionEnd: 0 }
                    )
                } else {
                    historyReset()
                }

            }
        }
    }, [historyPush, historyReset])


    const applyOperation = useCallback((op: TextareaOperation) => {
        if (!$element.current) {
            return
        }
        const textarea = $element.current
        const result = op(textarea)
        if (result !== undefined) {
            historyPush({
                text: result.text,
                selectionStart: result.selectionStart,
                selectionEnd: result.selectionEnd,
            })
        }
    }, [historyPush])

    const formatBold = useCommand(MarkdownFormatBoldCommand, () => {
        applyOperation(MarkdownOperations.Bold)
    })
    const formatItalic = useCommand(MarkdownFormatItalicCommand, () => {
        applyOperation(MarkdownOperations.Italic)
    })
    useCommand(MarkdownFormatStrikethroughCommand, () => {
        applyOperation(MarkdownOperations.Strikethrough)
    })
    useCommand(MarkdownFormatUnorderedListCommand, () => {
        applyOperation(MarkdownOperations.UnorderedList)
    })
    useCommand(MarkdownFormatOrderedListCommand, () => {
        applyOperation(MarkdownOperations.OrderedList)
    })
    const indent = useCommand(MarkdownEditorIndentCommand, () => {
        applyOperation(MarkdownOperations.Indent)
    })
    const indentReverse = useCommand(MarkdownEditorIndentReverseCommand, () => {
        applyOperation(MarkdownOperations.IndentReverse)
    })


    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.shiftKey && e.code === "Tab") {
            e.preventDefault();
            indentReverse()
        } else if (e.code === "Tab") {
            e.preventDefault();
            indent()
        }

        if (e.ctrlKey && e.shiftKey && e.code === "KeyZ") {
            e.preventDefault()
            historyRedo()
        } else if (e.ctrlKey && e.code === "KeyZ") {
            e.preventDefault();
            historyUndo()
        }

        if (e.ctrlKey && e.code === "KeyB") {
            e.preventDefault();
            formatBold()
        }

        if (e.ctrlKey && e.code === "KeyI") {
            e.preventDefault();
            formatItalic();
        }

        if (e.code === "Enter") {
            e.preventDefault()
            applyOperation(MarkdownOperations.Newline)
        }
    }

    const handleOnChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        historyPush({
            selectionStart: event.target.selectionStart,
            selectionEnd: event.target.selectionEnd,
            text: event.target.value,
        }, "change")
    }, [historyPush])

    if (mode === "preview") {
        return (
            <MarkdownText variant="card" minHeight={20} width="100%">
                {text}
            </MarkdownText>
        )
    }

    return (
        <Textarea
            ref={$element}
            defaultValue={text}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            isDisabled={isDisabled}
            resize={
                allowResize ? "vertical" : "none"
            }
            height="100%"
            minHeight={20}
            sx={{
                fontFamily: "Roboto Mono",
            }}
        />
    )
})