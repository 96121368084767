import { CancelIcon, SaveIcon } from "@loryth/components/icons";
import { useEffect, useRef, useState } from "react";

import { Comment, CommentApi } from "@loryth/api";
import { MarkdownEditor, MarkdownEditorRef } from "@loryth/components";
import { Button, ButtonGroup, Stack } from "@chakra-ui/react";


export interface CommentEditorProps {
    scopeId: string
    comment?: Comment
}

export function CommentEditor({ scopeId, comment }: CommentEditorProps) {
    const [createComment] = CommentApi.useCreateMutation()
    const [updateComment] = CommentApi.useUpdateMutation()

    const $content = useRef<MarkdownEditorRef>(null)
    const [content, setContent] = useState(comment?.content)

    useEffect(() => {
        if ($content.current) {
            $content.current.value = comment?.content ?? ""
        }
    }, [comment]);
    const handleOnSubmit = async () => {
        if (!$content.current) {
            // Impossible to happen, but compiler doesn't know...
            console.warn("Unexpected call to submit: content isn't initialized.")
            return
        }
        try {
            if (!comment) {
                await createComment({
                    data: {
                        scope_id: scopeId,
                        content: $content.current.value,
                        metadata: {},
                    }
                })
                $content.current.reset("")
            } else {
                await updateComment({
                    commentId: comment.id,
                    data: {
                        content: $content.current.value
                    }
                })
                $content.current.reset($content.current.value)
            }
        } catch (error) {
            console.error("Failed to upsert comment:", error)
        }
    }

    const handleOnCancel = () => {
        if (!$content.current) {
            // Impossible to happen, but compiler doesn't know...
            console.warn("Unexpected call to cancel: content isn't initialized.")
            return
        }
        $content.current.value = (comment?.content ?? "")
    }

    const isModified = (
        (!comment && content)
        || (comment && comment.content !== content)
    )

    return (
        <Stack gap={1}>
            <MarkdownEditor
                ref={$content}
                defaultValue={comment?.content ?? ""}
                onChange={value => setContent(value)}
                allowResize
                _focusWithin={{
                    minHeight: 240,
                }}
            />
            {isModified && (
                <ButtonGroup size="xs" alignSelf="flex-end">
                    <Button
                        variant="solid"
                        colorScheme="teal"
                        leftIcon={<SaveIcon/>}
                        onClick={handleOnSubmit}
                    >
                        submit
                    </Button>
                    <Button
                        variant="ghost"
                        colorScheme="gray"
                        leftIcon={<CancelIcon/>}
                        onClick={handleOnCancel}
                    >
                        cancel
                    </Button>
                </ButtonGroup>
            )}
        </Stack>
    )
}