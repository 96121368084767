import dayjs from "dayjs";


export const TimeUnits = ["millisecond", "second", "minute", "hour", "day", "month", "year"]
export type TimeUnit = "millisecond" | "second" | "minute" | "hour" | "day" | "month" | "year"

function timeUnitCompare(a: TimeUnit, b: TimeUnit): number {
    return TimeUnits.indexOf(b) - TimeUnits.indexOf(a)
}

function timeUnitBetween(min: TimeUnit, max: TimeUnit, unit: TimeUnit): boolean {
    return timeUnitCompare(min, unit) >= 0 && timeUnitCompare(unit, max) >= 0
}

export interface FormatDurationOptions {
    format?: "plain" | "short" | "compact" | "long"
    singleUnit?: boolean
    minUnit?: TimeUnit
    maxUnit?: TimeUnit
}

export function formatDuration(seconds: number, {
    format = "plain",
    singleUnit = false,
    minUnit = "second",
    maxUnit = "day",
}: FormatDurationOptions = {}): string {
    const timespan = dayjs.duration(seconds * 1000)

    const durationSeconds = timespan.seconds()
    const durationMinutes = timespan.minutes()
    const durationHours = Math.floor(timespan.asHours())
    const durationDays = Math.floor(timespan.asDays())

    const units = {
        short: { seconds: "s", minutes: "m", hours: "h", days: "d" },
        compact: { seconds: " secs", minutes: " mins", hours: " hours", days: " days" },
        long: { seconds: " seconds", minutes: " minutes", hours: " hours", days: " days" },
    }

    switch (format) {
        case "short":
        case "compact":
        case "long":
            // TODO(souperk): Refactor...
            //  1. determine max unit of timespan
            //  2. if singleUnit or (value of max unit > 2) -> format with 1 unit
            //  3. otherwise, format with 2 units

            if (durationDays > 0 && timeUnitBetween(minUnit, maxUnit, "day")) {
                return `${durationDays}${units[format].days}`
            }
            if (durationHours > 0 && singleUnit) {
                return `${durationHours}${units[format].hours}`
            }
            if (durationHours > 0) {
                return `${durationHours}${units[format].hours} ${durationMinutes}${units[format].minutes}`
            }
            if (timespan.minutes() > 0) {
                return `${durationMinutes}${units[format].minutes}`
            }
            return `${durationSeconds}${units[format].seconds}`

        case "plain":
        default:
            // TODO(souperk): This does not support durations over 24h...
            return timespan.format(`[${durationHours}]:mm:ss`)
    }
}