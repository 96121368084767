import { BreadcrumbItem, BreadcrumbItemProps, BreadcrumbLink, Icon, Text } from "@chakra-ui/react";


import { Task } from "@loryth/api";
import { Link } from "@loryth/components";
import { TaskIcon } from "@loryth/components/icons";


export interface TaskBreadcrumbItemProps extends BreadcrumbItemProps {
    task: Task
}

export function TaskBreadcrumbItem({ task, ...props }: TaskBreadcrumbItemProps) {
    return (
        <BreadcrumbItem {...props}>
            <BreadcrumbLink
                as={Link} to={`/task/${task.id}`}
                display="inline-flex" alignItems="center" gap={2}
            >
                <Icon as={TaskIcon} fontSize="lg"/>
                <Text>{task.title}</Text>
            </BreadcrumbLink>
        </BreadcrumbItem>
    )
}