import { Icon, Menu, MenuButton, MenuItem, MenuList, TagRightIcon } from "@chakra-ui/react";

import { Account, AccountApi } from "@loryth/api";
import { AccountActiveIcon, AccountInactiveIcon, ExpandIcon } from "@loryth/components/icons";

import { AccountStatusTag } from "./AccountStatusTag";


export interface AccountStatusMenuProps {
    account: Account
}

export function AccountStatusMenu({ account }: AccountStatusMenuProps) {
    const [updateAccount] = AccountApi.useAdminUpdateAccountMutation()

    const handleOnStatusChange = async (status: string) => {
        try {
            await updateAccount({
                accountId: account.id,
                data: { status: status },
            }).unwrap()
        } catch (error) {
            console.error("failed to update account", error)
        }
    }
    return (
        <Menu>
            <MenuButton>
                <AccountStatusTag account={account} width={32} cursor="pointer">
                    <TagRightIcon as={ExpandIcon} ml="auto"/>
                </AccountStatusTag>
            </MenuButton>
            <MenuList>
                <MenuItem
                    icon={<Icon as={AccountActiveIcon}/>}
                    onClick={() => handleOnStatusChange("active")}
                    isDisabled={account.status === "active"}
                >
                    Active
                </MenuItem>
                <MenuItem
                    icon={<Icon as={AccountInactiveIcon}/>}
                    onClick={() => handleOnStatusChange("inactive")}
                    isDisabled={account.status === "inactive"}
                >
                    Disabled
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

