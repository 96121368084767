import React from 'react'
import ReactDOM from 'react-dom/client'

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration"
dayjs.extend(duration)

import { App } from './App'


ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
