import { ShellSidebar, SidebarBody, useDeviceMode } from "@loryth/components";

import { AppNavigation } from "../app-navigation/AppNavigation";
import { AppSidebarFooter } from "./AppSidebarFooter";
import { AppSidebarHeader } from "./AppSidebarHeader";

export function AppSidebar() {
    const { isDesktop } = useDeviceMode()

    return (
        <ShellSidebar autoCollapse={!isDesktop}>
            <AppSidebarHeader/>
            <SidebarBody>
                <AppNavigation/>
            </SidebarBody>
            <AppSidebarFooter/>
        </ShellSidebar>
    )
}