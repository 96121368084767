import type { IconType } from "react-icons";

export { IconType }

// Common Icons
import {
    FaHouse as HomeIcon,
    FaBars as MenuIcon,
    FaEllipsisVertical as OptionsIcon,
    FaNotdef as MissingIcon,
    FaMagnifyingGlass as SearchIcon,
    FaPlus as NewIcon,
    FaPencil as EditIcon,
    FaFloppyDisk as SaveIcon,
    FaBan as CancelIcon,
    FaTrash as DeleteIcon,
    FaXmark as ResetIcon,
    FaRotateLeft as UndoIcon,
    FaRotateRight as RedoIcon,
    FaChevronDown as ExpandIcon,
    FaChevronUp as CollapseIcon,
    FaFilter as FilterIcon,
    FaUpload as UploadIcon,
    FaClock as TimeIcon,
    FaStopwatch as TimerIcon,
    FaCheck as SuccessIcon,
    FaTriangleExclamation as WarningIcon,
    FaTriangleExclamation as ErrorIcon,
    FaGear as SettingsIcon,
    FaChevronDown as OrderAscendingIcon,
    FaChevronUp as OrderDescendingIcon,
    FaCheck as CheckIcon,
    FaRegSquare as CheckboxFalseIcon,
    FaRegSquareCheck as CheckboxTrueIcon,
    FaHashtag as TagIcon,
    FaHashtag as SectionIcon,
    FaArrowLeft as BackIcon,
    FaArrowRight as ForwardIcon,
    FaEye as VisibilityOnIcon,
    FaEyeSlash as VisibilityOffIcon,
    FaRightFromBracket as SignOutIcon,
    FaUser as UserIcon,
    FaList as ListViewIcon,
    FaTableCellsLarge as BoxViewIcon,
    FaUserGear as AdministratorIcon,
    FaCircleCheck as AccountActiveIcon,
    FaClock as AccountPendingIcon,
    FaBan as AccountInactiveIcon,
    FaArrowRightArrowLeft as MoveIcon,
    FaSliders as ComponentSettingsIcon,
} from "react-icons/fa6";

export {
    HomeIcon, MenuIcon, OptionsIcon, SettingsIcon,
    SearchIcon, FilterIcon, MissingIcon,
    NewIcon, EditIcon, SaveIcon, CancelIcon, DeleteIcon, ResetIcon,
    UndoIcon, RedoIcon,
    ExpandIcon, CollapseIcon,
    UploadIcon,
    TimeIcon, TimerIcon,
    OrderAscendingIcon, OrderDescendingIcon,
    SuccessIcon, WarningIcon, ErrorIcon,
    CheckIcon, SectionIcon, TagIcon,
    BackIcon, ForwardIcon,
    CheckboxFalseIcon, CheckboxTrueIcon,
    VisibilityOnIcon, VisibilityOffIcon,
    UserIcon, AdministratorIcon,
    SignOutIcon,
    ListViewIcon, BoxViewIcon,
    AccountPendingIcon, AccountActiveIcon, AccountInactiveIcon,
    MoveIcon,
    ComponentSettingsIcon,
}

// Editor

import {
    FaLink as LinkIcon,
    FaBold as FormatBoldIcon,
    FaItalic as FormatItalicIcon,
    FaListOl as FormatOrderedListIcon,
    FaListUl as FormatUnorderedListIcon,
    FaCode as FormatCodeIcon,
    FaQuoteLeft as FormatQuoteIcon,
    FaAlignJustify as EditorSourceIcon,
    FaImage as EditorPreviewIcon,
    FaStrikethrough as FormatStrikethroughIcon
} from "react-icons/fa6"

export {
    LinkIcon,
    FormatBoldIcon,
    FormatItalicIcon,
    FormatOrderedListIcon,
    FormatUnorderedListIcon,
    FormatCodeIcon,
    FormatQuoteIcon,
    FormatStrikethroughIcon,
    EditorSourceIcon,
    EditorPreviewIcon,
}

// Organization
import {
    FaListCheck as ProjectIcon,
    FaTableList as BacklogIcon,
    FaBook as NotebookIcon,
    FaFolder as FolderIcon,
    FaFileLines as NoteIcon,
    FaCalendarCheck as TaskIcon,
    FaCircleInfo as TaskOpenIcon,
    FaBarsProgress as TaskInProgressIcon,
    FaCircleCheck as TaskCompletedIcon,
    FaCircleXmark as TaskClosedIcon,
    FaFeatherPointed as JournalIcon,
    FaBullseye as FocusIcon,
    FaList as CollectionIcon,
} from "react-icons/fa6"

export {
    ProjectIcon,
    BacklogIcon,
    NotebookIcon,
    FolderIcon,
    NoteIcon,
    TaskIcon,
    TaskOpenIcon,
    TaskInProgressIcon,
    TaskCompletedIcon,
    TaskClosedIcon,
    JournalIcon,
    FocusIcon,
    CollectionIcon,
}

// Mental Health
import { FaBrain as MentalHealthIcon } from "react-icons/fa6"

export { MentalHealthIcon }

// Coding


import {
    FaCode as CodeIcon,
    FaLightbulb as IdeaIcon,
    FaBug as BugIcon,
    FaFeather as FeatherIcon,
    FaUser as UserStoryIcon,
    FaBolt as EpicIcon,
    FaChartLine as LineChartIcon,
    FaChartColumn as BarChartIcon,
    FaFilm as MovieIcon,
    FaTv as TvShowIcon,
    FaToriiGate as AnimeIcon,
} from "react-icons/fa6"

export {
    CodeIcon,
    IdeaIcon,
    BugIcon,
    FeatherIcon,
    UserStoryIcon,
    EpicIcon,
    LineChartIcon,
    BarChartIcon,
}

// Emoticons

import {
    FaFaceSmile,
    FaFaceFrown,
    FaFaceAngry,
    FaFaceSurprise,
} from "react-icons/fa6"

// Entertainment

import {
    FaPalette,
    FaPuzzlePiece,
    FaGamepad,
} from "react-icons/fa6"

// Object

import {
    FaBed,
    FaDumbbell,
    FaCloudMoon,
    FaSpa,
    FaCalendar,
    FaFilePen,
    FaNewspaper,
    FaGraduationCap,
    FaShip,
    FaBriefcase,
    FaMap,
    FaBook,
    FaFolder,
    FaLightbulb,
} from "react-icons/fa6"

// Media
export { FaPlay as MediaPlayIcon, FaPause as MediaPauseIcon, FaStop as MediaStopIcon, FaRotateLeft as MediaResetIcon } from "react-icons/fa6"

export function findIconByName(name: string, defaultIcon?: IconType): IconType {
    const index = IconRegistry.findIndex(icon => icon.name == name || icon.alias?.includes?.(name))
    if (index === -1) {
        return defaultIcon || MissingIcon
    }

    const { icon } = IconRegistry[index]
    return icon
}

export function searchIcons(query: string): IconInfo[] {
    if (!query) {
        return IconRegistry
    }
    return IconRegistry.filter(icon =>
        icon.name.includes(query.toLowerCase())
        || icon.category.includes(query.toLowerCase())
        || (icon.alias || []).findIndex(alias => alias.includes(query.toLowerCase())) !== -1
    )
}

export function groupIconsByCategory(icons: IconInfo[]): { category: IconInfo["category"], icons: IconInfo[] }[] {
    const groupMap = new Map<IconInfo["category"], IconInfo[]>()

    for (const icon of icons) {
        const category = icon.category
        const categoryIcons = groupMap.get(category)
        if (categoryIcons) {
            categoryIcons.push(icon)
        } else {
            groupMap.set(category, [icon])
        }
    }

    const categoryOrder: Record<IconInfo["category"], number> = {
        "emoji": 0,
        "entertainment": 1,
        "objects": 2,
        "places": 3,
        "organization": 4,
        "mental-health": 5,
        "coding": 6,
        "common": 7,
        "editor": 8,
        "misc": 9,
    }
    return Array.from(groupMap.entries()).map(
        ([category, icons]) => ({ category, icons })
    ).sort((a, b) => categoryOrder[a.category] - categoryOrder[b.category])
}

interface IconInfo {
    icon: IconType
    name: string
    alias?: string[]
    category:
        "emoji"
        | "entertainment"
        | "objects"
        | "places"
        | "organization"
        | "mental-health"
        | "coding"
        | "common"
        | "editor"
        | "misc"
}

export const IconRegistry: IconInfo[] = [
    // category: Emoji
    { name: "face-smile", icon: FaFaceSmile, category: "emoji" },
    { name: "face-frown", icon: FaFaceFrown, category: "emoji" },
    { name: "face-angry", icon: FaFaceAngry, category: "emoji" },
    { name: "face-surprise", icon: FaFaceSurprise, category: "emoji" },

    // category: Entertainment
    { name: "movie", icon: MovieIcon, category: "entertainment" },
    { name: "tv-show", icon: TvShowIcon, category: "entertainment" },
    { name: "anime", icon: AnimeIcon, category: "entertainment" },
    { name: "palette", icon: FaPalette, category: "entertainment" },
    { name: "puzzle-piece", icon: FaPuzzlePiece, category: "entertainment" },
    { name: "gamepad", icon: FaGamepad, category: "entertainment" },

    // category: Objects
    { name: "bed", icon: FaBed, category: "objects", alias: ["sleep"] },
    { name: "dumbbell", icon: FaDumbbell, category: "objects" },
    { name: "cloud-moon", icon: FaCloudMoon, category: "objects" },
    { name: "calendar", icon: FaCalendar, category: "objects" },
    { name: "file-pen", icon: FaFilePen, category: "objects" },
    { name: "newspaper", icon: FaNewspaper, category: "objects" },
    { name: "map", icon: FaMap, category: "objects" },
    { name: "ship", icon: FaShip, category: "objects" },
    { name: "graduation-cap", icon: FaGraduationCap, category: "objects", alias: ["education"] },
    { name: "briefcase", icon: FaBriefcase, category: "coding", alias: ["company", "work"] },
    { name: "book", icon: FaBook, category: "objects", alias: ["notebook"] },
    { name: "folder", icon: FaFolder, category: "objects" },
    { name: "light-bulb", icon: FaLightbulb, category: "coding", alias: ["idea"] },

    // Category: Places
    { name: "spa", icon: FaSpa, category: "places" },

    // Category: Organization
    { name: "project", icon: ProjectIcon, category: "organization" },
    { name: "task", icon: TaskIcon, category: "organization" },
    { name: "note", icon: NoteIcon, category: "organization", alias: ["sticky", "sticky-note", "file"] },
    { name: "hashtag", icon: TagIcon, category: "organization", alias: ["tag"] },

    // Category: Mental Health
    { name: "mental-health", icon: MentalHealthIcon, category: "mental-health", alias: ["brain"] },
    { name: "focus", icon: FocusIcon, category: "mental-health" },

    // Category: Coding
    { name: "code", icon: CodeIcon, category: "coding" },
    { name: "bug", icon: BugIcon, category: "coding" },
    { name: "feather", icon: FeatherIcon, category: "coding" },
    { name: "user-story", icon: UserStoryIcon, category: "coding" },
    { name: "epic", icon: EpicIcon, category: "coding" },
    { name: "line-chart", icon: LineChartIcon, category: "coding" },
    { name: "bar-chart", icon: BarChartIcon, category: "coding" },

    // Category: Common
    { name: "home", icon: HomeIcon, category: "common" },
    { name: "menu", icon: MenuIcon, category: "common" },
    { name: "options", icon: OptionsIcon, category: "common" },
    { name: "missing", icon: MissingIcon, category: "common", alias: ["x"] },
    { name: "new", icon: NewIcon, category: "common", alias: ["create", "plus", "add"] },
    { name: "search", icon: SearchIcon, category: "common" },
    { name: "edit", icon: EditIcon, category: "common", alias: ["modify", "pencil"] },
    { name: "save", icon: SaveIcon, category: "common", alias: ["disk", "floppy-disk"] },
    { name: "delete", icon: DeleteIcon, category: "common", alias: ["trash"] },
    { name: "time", icon: TimeIcon, category: "common", alias: ["clock"] },

    // Category: Editor
    { name: "format-link", icon: LinkIcon, category: "editor" },
    { name: "format-bold", icon: FormatBoldIcon, category: "editor" },
    { name: "format-italic", icon: FormatItalicIcon, category: "editor" },
    { name: "format-unordered-list", icon: FormatUnorderedListIcon, category: "editor" },
    { name: "format-numbered-list", icon: FormatOrderedListIcon, category: "editor" },
    { name: "format-code", icon: FormatCodeIcon, category: "editor" },
    { name: "format-quote", icon: FormatQuoteIcon, category: "editor" },
    { name: "editor-source", icon: EditorSourceIcon, category: "editor" },
    { name: "editor-preview", icon: EditorPreviewIcon, category: "editor" },

    // Category: Misc
]