import { Icon, chakra, useStyleConfig, forwardRef } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system";

import { findIconByName } from "./icons";


export interface IconBoxProps extends Omit<HTMLChakraProps<"div">, "icon" | "size"> {
    variant?: "solid" | "outline" | "ghost"
    colorScheme?: string | null
    size?: "xs" | "sm" | "md" | "lg" | "xl"
    icon?: string | null
}

export const IconBox = forwardRef<IconBoxProps, "div">(({
    variant,
    size,
    icon = null,
    colorScheme = "gray",
    children,
    ...props
}, ref) => {
    const styles = useStyleConfig("IconBox", {
        variant,
        size,
        colorScheme: colorScheme ?? undefined
    })

    return (
        <chakra.div
            ref={ref}
            className="loryth-icon-box"
            __css={styles}

            {...props}
        >
            {icon && <Icon as={findIconByName(icon)}/>}
            {children}
        </chakra.div>
    )
})

