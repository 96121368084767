import { useRef, useState } from "react";
import { Button, ButtonGroup, Stack } from "@chakra-ui/react";

import { Comment, CommentApi } from "@loryth/api"
import { MarkdownEditor, MarkdownEditorRef, MarkdownText } from "@loryth/components";
import { DeleteIcon, EditIcon, SaveIcon } from "@loryth/components/icons";


export interface CommentPreviewProps {
    comment: Comment
}

export function CommentPreview({ comment }: CommentPreviewProps) {
    const [updateComment] = CommentApi.useUpdateMutation()
    const [deleteComment] = CommentApi.useDeleteMutation()
    const [isEditing, setIsEditing] = useState(false)
    const $content = useRef<MarkdownEditorRef>(null)

    const handleOnSubmit = async () => {
        if (!$content.current) {
            // Impossible to happen, but compiler doesn't know...
            console.warn("Unexpected call to submit: content isn't initialized.")
            return
        }
        try {
            await updateComment({
                commentId: comment.id,
                data: {
                    content: $content.current.value,
                }
            })
            $content.current.reset($content.current.value)
            setIsEditing(false)
        } catch (error) {
            console.error("Failed to update comment.", error)
        }
    }

    const handleOnDelete = async () => {
        try {
            await deleteComment(comment)
        } catch (error) {
            console.error("Failed to delete comment.", error)
        }
    }


    return (
        <Stack gap={1}>
            {!isEditing && (
                <MarkdownText variant="card" flex={1}>
                    {comment.content}
                </MarkdownText>
            )}
            {isEditing && (
                <MarkdownEditor
                    ref={$content}
                    defaultValue={comment.content}
                    allowResize
                    minHeight={240}
                />
            )}
            <ButtonGroup variant="ghost" size="xs" alignSelf="flex-end">
                {isEditing && (
                    <Button leftIcon={<SaveIcon/>} onClick={handleOnSubmit}>
                        submit
                    </Button>
                )}
                {!isEditing && (
                    <Button leftIcon={<EditIcon/>} onClick={() => setIsEditing(true)}>
                        modify
                    </Button>
                )}
                <Button colorScheme="red" leftIcon={<DeleteIcon/>} onClick={handleOnDelete}>
                    delete
                </Button>
            </ButtonGroup>
        </Stack>
    )
}


