import { lorythApi } from "./_api"
import { AccountApi } from "./account.api";
import { AccountGroupApi } from "./account-group.api";
import { CommentApi } from "./comment.api";
import { FilesystemApi } from "./filesystem.api"
import { HabitApi } from "./habit.api"
import { ProjectApi } from "./project.api"
import { SettingsApi } from "./settings.api";
import { TaskApi } from "./task.api"
import { TaskTypeApi } from "./task-type.api"
import { TimerApi } from "./timer.api"

export type * from "./account"
export type * from "./account-group"
export type * from "./comment"
export type * from "./filesystem"
export type * from "./habit"
export type * from "./journal"
export type * from "./project"
export type * from "./settings"
export type * from "./task"
export type * from "./task-type"
export type * from "./timer"

export { AccountApi, AccountGroupApi, CommentApi, FilesystemApi, HabitApi, ProjectApi, SettingsApi, TaskApi, TaskTypeApi, TimerApi }

export const LorythApi = {
    internal: lorythApi,
    account: AccountApi,
    accountGroup: AccountGroupApi,
    comment: CommentApi,
    filesystem: FilesystemApi,
    habit: HabitApi,
    project: ProjectApi,
    settings: SettingsApi,
    task: TaskApi,
    taskType: TaskTypeApi,
    timer: TimerApi,
}
