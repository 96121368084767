import { ChangeEvent, useCallback, useState } from "react";

import { Button, ButtonGroup, Icon, IconButton, Input, InputGroup, InputLeftElement, Spinner } from "@chakra-ui/react";

import { Project, TaskApi } from "@loryth/api";
import { FormDialog, Page, PageLayout, Section, SectionBody, SectionTitle, useDebounceValue } from "@loryth/components";
import { BoxViewIcon, ErrorIcon, FilterIcon, ListViewIcon, NewIcon, SearchIcon } from "@loryth/components/icons";

import { TaskFeature, TaskFilterSection } from "@loryth/features/task";
import { TaskForm } from "@loryth/features/task-form";
import { TaskListingSectionBody } from "@loryth/features/task-listing";

import { useAppDispatch, useAppSelector } from "../../store";


export interface TaskBacklogPageProps {
    project?: Project
}

export function TaskBacklogPage({ project }: TaskBacklogPageProps) {
    const [filterIsVisible, setFilterIsVisible] = useState(false)
    const dispatch = useAppDispatch()
    const filter = useAppSelector(
        state => TaskFeature.selectFilter(state, project?.id ?? "backlog"),
    )

    const queryParams = useDebounceValue({
        ...filter,
        projects: [
            ...(project ? [project.id] : []),
            ...(filter.projects ?? []),
        ],
    }, [project, filter])
    const {
        isFetching,
        isError,
        isSuccess,
        data: tasks
    } = TaskApi.useSearchQuery(queryParams, { pollingInterval: 60 * 1000 })

    const handleOnQueryChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        dispatch(TaskFeature.patchFilter({
            filterId: project?.id ?? "backlog",
            query: event.target.value,
        }))
    }, [dispatch, project])

    const handleOnViewModeChange = useCallback((viewMode: "list" | "cards") => {
        dispatch(TaskFeature.toggleFilterViewMode({ filterId: project?.id || "backlog", viewMode }))
    }, [dispatch, project])

    return (
        <Page title="Backlog - Loryth" height="100%">
            <PageLayout variant={
                filterIsVisible ? "sidebar" : "standard"
            }>
                {filterIsVisible && (
                    <TaskFilterSection
                        area="sidebar"
                        filterId={project?.id ?? "backlog"}
                    />
                )}

                <Section flex="1" overflow="auto">
                    <SectionTitle>
                        Backlog
                    </SectionTitle>

                    <SectionBody variant="toolbar">
                        <FormDialog
                            title="Create a new task..."
                            size="large"
                            form={
                                <TaskForm
                                    initial={{
                                        title: filter.query,
                                        projectId: project?.id ?? filter.projects?.[0],
                                        typeId: filter.types?.[0],
                                    }}
                                />
                            }
                            trigger={
                                <Button
                                    variant="solid"
                                    colorScheme="teal"
                                    leftIcon={<Icon as={NewIcon}/>}
                                >
                                    New
                                </Button>
                            }
                        />

                        <InputGroup>
                            <InputLeftElement p={0}>
                                {isFetching && <Spinner size="sm"/>}
                                {!isFetching && isError && <Icon as={ErrorIcon}/>}
                                {!isFetching && isSuccess && <Icon as={SearchIcon}/>}
                            </InputLeftElement>
                            <Input
                                placeholder="Search..."
                                value={filter.query}
                                onChange={handleOnQueryChange}
                                isInvalid={isError}
                            />
                        </InputGroup>

                        <ButtonGroup isAttached>
                            <IconButton
                                isActive={filter.viewMode === "list"}
                                aria-label="list view"
                                icon={<Icon as={ListViewIcon} />}
                                onClick={() => handleOnViewModeChange("list")}
                            />
                            <IconButton
                                isActive={filter.viewMode === "cards"}
                                aria-label="card view"
                                icon={<Icon as={BoxViewIcon} />}
                                onClick={() => handleOnViewModeChange("cards")}
                            />
                        </ButtonGroup>

                        <IconButton
                            aria-label={
                                filterIsVisible ? "hide filter" : "show filter"
                            }
                            icon={<Icon as={FilterIcon}/>}
                            onClick={() => setFilterIsVisible(!filterIsVisible)}
                        />
                    </SectionBody>

                    {isSuccess && (
                        <TaskListingSectionBody
                            tasks={tasks.results}
                            view={{ mode: filter.viewMode }}
                            flex="1" overflow="auto" p={0}
                        />
                    )}
                </Section>
            </PageLayout>
        </Page>
    )
}

