import { Icon, IconButton } from "@chakra-ui/react";

import { Account, AccountApi } from "@loryth/api";
import { ConfirmationModal } from "@loryth/components";
import { DeleteIcon } from "@loryth/components/icons";


export interface AccountDeleteButtonProps {
    account: Account
}

export function AccountDeleteButton({ account }: AccountDeleteButtonProps) {
    const [deleteAccount] = AccountApi.admin.useDeleteMutation()

    const handleOnDelete = async () => {
        try {
            await deleteAccount(account.id).unwrap()
        } catch (err) {
            console.error("Failed to delete account.", err)
        }
    }

    return (
        <ConfirmationModal
            title="Delete account"
            message="Are your sure you want to delete user?"
            onConfirm={handleOnDelete}
            trigger={
                <IconButton
                    variant="ghost"
                    aria-label="delete account"
                    icon={<Icon as={DeleteIcon}/>}
                />
            }
        />
    )

}