import { Link as WouterLink } from "wouter"
import { Link as ChakraLink, LinkProps as ChakraLinkProps } from "@chakra-ui/react"

export interface LinkProps extends ChakraLinkProps {
    to: string
}

export function Link({ to, ...props }: LinkProps) {
    return (
        <WouterLink to={to}>
            <ChakraLink
                _hover={{
                    color: "teal.400",
                    textDecoration: "underline",
                    "*": { color: "teal.400" }
                }}
                {...props}
            />
        </WouterLink>
    )
}

