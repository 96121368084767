import { useCallback, MouseEvent } from "react";

import { ButtonGroup, Icon, IconButton } from "@chakra-ui/react";
import { FaPause, FaPlay, FaStop, FaTrash } from "react-icons/fa6";

import { Timer, TimerApi } from "@loryth/api";


export interface TimerControlsProps {
    timer: Timer
}

export function TimerControls({ timer }: TimerControlsProps) {
    const [timerPause] = TimerApi.usePauseMutation()
    const [timerResume] = TimerApi.useResumeMutation()
    const [timerComplete] = TimerApi.useCompleteMutation()
    const [timerDelete] = TimerApi.useDeleteMutation()

    const isActive = timer.status === "active"
    const isPaused = timer.status === "paused"
    const isCompleted = timer.status === "completed"

    const handlePause = useCallback(async (event: MouseEvent) => {
        event.preventDefault()
        await timerPause(timer.id)
    }, [timer, timerPause])
    const handleResume = useCallback(async (event: MouseEvent) => {
        event.preventDefault()
        await timerResume(timer.id)
    }, [timer, timerResume])
    const handleComplete = useCallback(async (event: MouseEvent) => {
        event.preventDefault()
        await timerComplete(timer.id)
    }, [timer, timerComplete])
    const handleDelete = useCallback(async (event: MouseEvent) => {
        event.preventDefault()
        await timerDelete(timer.id)
    }, [timer, timerDelete])

    return (
        <ButtonGroup
            variant="ghost"
            isAttached
            backgroundColor="white"
            borderRadius="md"
            borderColor="blackAlpha.200"
            borderWidth={1}
        >
            {isActive && (
                <IconButton
                    aria-label="Pause"
                    title="Pause"
                    icon={<Icon as={FaPause} color="teal.500"/>}
                    onClick={handlePause}
                    _hover={{
                        backgroundColor: "teal.200"
                    }}
                />
            )}
            {isPaused && (
                <IconButton
                    aria-label="Resume"
                    title="Resume"
                    icon={<Icon as={FaPlay} color="teal.500"/>}
                    onClick={handleResume}
                    _hover={{
                        backgroundColor: "teal.200"
                    }}
                />
            )}
            {!isCompleted && (
                <IconButton
                    aria-label="Complete"
                    title="Complete"
                    icon={<Icon as={FaStop} color="teal.500"/>}
                    onClick={handleComplete}
                    _hover={{
                        backgroundColor: "teal.200"
                    }}
                />
            )}
            <IconButton
                aria-label="Delete"
                title="Delete"
                icon={<Icon as={FaTrash} color="teal.500"/>}
                onClick={handleDelete}
                _hover={{
                    backgroundColor: "teal.200"
                }}
            />
        </ButtonGroup>
    )
}