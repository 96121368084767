import { Grid, GridItem, Link, Text } from "@chakra-ui/react";
import { Link as WouterLink } from "wouter"

import { Project } from "@loryth/api";
import { IconBox, MarkdownText } from "@loryth/components";

import { ProjectActions } from "./ProjectActions";



export interface ProjectPreviewProps {
    project: Project
}

export function ProjectPreview({ project }: ProjectPreviewProps) {
    return (
        <Grid
            gridTemplateAreas={`
                "color          name         actions"
                "description    description  description"
            `}
            gridTemplateColumns="auto 1fr auto"
            gridTemplateRows="auto 1fr"
            alignItems="center"
            columnGap={2}
            rowGap={1}
        >
            <GridItem gridArea="color">
                <IconBox
                    colorScheme={project.color ?? undefined}
                    icon={project.icon ?? undefined}
                />
            </GridItem>
            <GridItem gridArea="name">
                <WouterLink href={`/project/${project.id}`}>
                    <Link>
                        <Text fontSize="2xl">
                            {project.name}
                        </Text>
                    </Link>
                </WouterLink>
            </GridItem>
            <GridItem gridArea="description">
                <MarkdownText>
                    {project.description}
                </MarkdownText>
            </GridItem>
            <GridItem gridArea="actions">
                <ProjectActions project={project}/>
            </GridItem>
        </Grid>

    )
}