import { lorythApi } from "./_api";
import { AdminSettings } from "./settings";

export interface AdminSettingsUpdateRequest {
    payload: Partial<AdminSettings>
}


const settingsApi = lorythApi.enhanceEndpoints({
    addTagTypes: ["admin-settings"]
}).injectEndpoints({
    endpoints: builder => ({
        settingsGet: builder.query<AdminSettings, void>({
            query: () => ({
                url: "/api/v1/admin/settings",
                method: "get",
            }),
            providesTags: ["admin-settings"],
        }),
        settingsUpdate: builder.mutation<AdminSettings, AdminSettingsUpdateRequest>({
            query: request => ({
                url: "/api/v1/admin/settings",
                method: "post",
                body: {
                    settings: request.payload,
                }
            }),
            invalidatesTags: ["admin-settings"],
        }),
    })
})


export const SettingsApi = {
    _internal: settingsApi,
    useGetQuery: settingsApi.useSettingsGetQuery,
    useUpdateMutation: settingsApi.useSettingsUpdateMutation,
}