import { Box, Button, Icon, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system";

import { Task } from "@loryth/api";
import {
    ExpandIcon,

    TaskClosedIcon,
    TaskCompletedIcon,
    TaskInProgressIcon,
    TaskOpenIcon
} from "@loryth/components/icons";

import { getStatusColor } from "../task/getStatusColor";
import { getStatusIcon } from "../task/getStatusIcon";


export interface TaskStatusSelectProps extends Omit<HTMLChakraProps<"button">, "value" | "onChange"> {
    value: Task["status"]
    onChange: (value: Task["status"]) => void

    isReadonly?: boolean
}

export function TaskStatusSelect({ value, onChange, isReadonly = false, ...props }: TaskStatusSelectProps) {

    if (isReadonly) {
        return (
            <Box
                borderRadius={4}
                borderWidth={1}
                color={`${getStatusColor(value)}.400`}
                borderColor="currentColor"
                bg={`${getStatusColor(value)}.50`}
                textAlign="left"
                display="flex"
                flexDirection="row"
                alignItems="center"
                px={4}
                gap={2}
                width={48}
                height={10}
                {...props}
            >
                <Icon as={getStatusIcon(value)}/>
                {value}
            </Box>
        )
    }
    return (
        <Menu matchWidth>
            <MenuButton
                as={Button}
                colorScheme={getStatusColor(value)}
                leftIcon={<Icon as={getStatusIcon(value)}/>}
                rightIcon={<ExpandIcon/>}
                textAlign="left"
                width={48}
                {...props}
            >
                {value}
            </MenuButton>
            <MenuList>
                <MenuItem icon={<TaskOpenIcon/>} onClick={() => onChange("open")}>
                    open
                </MenuItem>
                <MenuItem icon={<TaskInProgressIcon/>} onClick={() => onChange("in-progress")}>
                    in-progress
                </MenuItem>
                <MenuItem icon={<TaskCompletedIcon/>} onClick={() => onChange("completed")}>
                    completed
                </MenuItem>
                <MenuItem icon={<TaskClosedIcon/>} onClick={() => onChange("closed")}>
                    closed
                </MenuItem>
            </MenuList>
        </Menu>
    )
}