import { createMetadataKey } from "@loryth/commons/metadata"


export const TimerMetadata = {
    type: createMetadataKey<string>("type", "stopwatch"),
    label: createMetadataKey<string | undefined>("label"),
    stopwatch: {
        lapDurationSeconds: createMetadataKey<number>("stopwatch.lapDurationSeconds", 60),
    }
}
