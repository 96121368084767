import { Avatar, Spinner, Stack, Text } from "@chakra-ui/react";

import { AccountApi } from "@loryth/api";
import { SidebarHeader, useDeviceMode, useShellContext } from "@loryth/components";

export function AppSidebarHeader() {
    const { isLoading, isUninitialized, isSuccess, data: profile } = AccountApi.useMeQuery()

    const { isDesktop } = useDeviceMode()
    const { sidebar: { isOpen } } = useShellContext()

    return (
        <SidebarHeader>
            <Stack>
                {(isUninitialized || isLoading) && (
                    <Spinner/>
                )}
                {isSuccess && (
                    <Stack
                        direction="row" alignItems="center"
                        {...((!isDesktop || isOpen) ? {
                            justifyContent: "flex-start",
                            borderWidth: 1,
                            borderRadius: 4,
                            px: 4,
                            py: 2,
                        } : { justifyContent: "center" })}
                    >
                        <Avatar
                            name={profile.username} size="sm"
                            sx={{
                                ".chakra-avatar__initials": {
                                    fontSize: "2xl",
                                }
                            }}
                        />
                        {(!isDesktop || isOpen) && (
                            <Text fontSize="lg" fontWeight="bold">{profile.username}</Text>
                        )}
                    </Stack>
                )}
            </Stack>
        </SidebarHeader>
    )
}