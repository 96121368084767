import { Tag, TagLabel, TagLeftIcon, TagProps } from "@chakra-ui/react";

import { Account } from "@loryth/api";
import { AdministratorIcon, UserIcon } from "@loryth/components/icons";


export interface AccountRoleTagProps extends TagProps {
    account: Account
}

export function AccountRoleTag({ account, children, ...props }: AccountRoleTagProps) {
    if (account.role === "admin") {
        return (
            <Tag colorScheme="blue" {...props}>
                <TagLeftIcon as={AdministratorIcon}/>
                <TagLabel>Admin</TagLabel>
                {children}
            </Tag>
        )
    }

    return (
        <Tag colorScheme="gray" {...props}>
            <TagLeftIcon as={UserIcon}/>
            <TagLabel>{account.role}</TagLabel>
            {children}
        </Tag>
    )
}



