import { Button, Grid, Icon, Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa6";

export interface ColorInputProps {
    value: string | null
    onChange: (value: string | null) => void
}

export function ColorInput({ value, onChange }: ColorInputProps) {
    // TODO(souperk): Add clear option
    // TODO(souperk): Close popover on select.
    return (
        <Popover>
            <PopoverTrigger>
                <Button colorScheme={value || undefined}/>
            </PopoverTrigger>
            <PopoverContent p={4}>
                <Grid gridTemplateColumns="repeat(5, 1fr)" gap={4}>
                    {ColorOptions.map(colorOption => (
                        <Button
                            key={colorOption}
                            variant="solid"
                            colorScheme={colorOption}
                            onClick={() => onChange(colorOption)}
                        >
                            {value === colorOption && (
                                <Icon as={FaCheck}/>
                            )}
                        </Button>
                    ))}
                </Grid>
            </PopoverContent>
        </Popover>
    )
}

export const ColorOptions = [
    "purple",
    "pink",
    "red",
    "orange",
    "yellow",
    "green",
    "teal",
    "blue",
    "cyan",
]
