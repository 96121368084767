import { skipToken } from "@reduxjs/toolkit/query";

import { Spinner, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";

import { TaskType, TaskTypeApi } from "@loryth/api";
import { findIconByName } from "@loryth/components/icons";


export interface TaskTypeTagProps {
    type: TaskType | string
}

export function TaskTypeTag({ type: typeOrId }: TaskTypeTagProps) {
    const isId = typeof typeOrId === "string"
    const { isUninitialized, isLoading, data, isError } = TaskTypeApi.useGetQuery(
        isId ? typeOrId : skipToken,
        { skip: !isId }
    )
    if (isError) {
        return null
    }
    if (isId && (isUninitialized || isLoading)) {
        return (
            <Tag><Spinner size="xs"/></Tag>
        )
    }
    const type = isId ? (data as TaskType) : (typeOrId as TaskType)
    return (
        <Tag colorScheme={type.color ?? undefined}>
            {type.icon && (<TagLeftIcon as={findIconByName(type.icon)}/>)}
            <TagLabel>
                {type.title}
            </TagLabel>
        </Tag>
    )
}