import { BreadcrumbItem, BreadcrumbItemProps, BreadcrumbLink, Icon, Text } from "@chakra-ui/react";

import { Project } from "@loryth/api";
import { Link } from "@loryth/components";
import { findIconByName } from "@loryth/components/icons";


export interface ProjectBreadcrumbItemProps extends BreadcrumbItemProps {
    project: Project
}

export function ProjectBreadcrumbItem({ project, ...props }: ProjectBreadcrumbItemProps) {
    return (
        <BreadcrumbItem {...props}>
            <BreadcrumbLink
                as={Link} to={`/project/${project.id}`}
                display="inline-flex" alignItems="center" gap={2}
            >
                <Icon
                    as={findIconByName(project.icon ?? "project")}
                    color={
                        project.color ? `${project.color}.400` : undefined
                    }
                    fontSize="lg"
                />
                <Text>{project.name}</Text>
            </BreadcrumbLink>
        </BreadcrumbItem>
    )
}
