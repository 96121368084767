import { lorythApi } from "./_api";
import { Timer, TimerLog } from "./timer";

export interface TimerSearchRequest {

}

export interface TimerListingResponse {
    results: Timer[]
    page_number: number
    page_size: number
    total: number
}

export interface TimerPostRequest {
    data?: {
        metadata?: Record<string, unknown> | null
    }
}


export interface TimerLogSearchRequest {
    timerId: string
}

export interface TimerLogListingResponse {
    results: TimerLog[]
    page_number: number
    page_size: number
    total: number
}

export interface TimerReportBreakdown {
    labels: Record<string, {
        total_seconds: number
        total_count: number
    }>

}

export interface TimerReportRecord {
    timestamp: string
    stats: {
        total_seconds: number
        total_count: number
    }
    breakdown: TimerReportBreakdown
}

export interface TimerReportRequest {
    step_unit: "hour" | "day" | "week" | "month" | "year"
    step_count?: number
    min_dt?: string
    max_dt?: string
}

export interface TimerReportResponse {
    records: TimerReportRecord[]
    breakdown: TimerReportBreakdown
}

const timerApi = lorythApi.enhanceEndpoints({
    addTagTypes: ["timer", "timer-log"]
}).injectEndpoints({
    endpoints: builder => ({
        timerSearch: builder.query<TimerListingResponse, TimerSearchRequest>({
            query: () => `/api/v1/timer`,
            providesTags: response =>
                response ? [
                    ...response.results.map(timer => ({ type: "timer" as const, id: timer.id })),
                    "timer"
                ] : ["timer"],
        }),
        timerGet: builder.query<Timer, string>({
            query: timerId => `/api/v1/timer/${timerId}`,
            providesTags: response =>
                response ? [{ type: "timer", id: response.id }, "timer"]
                    : ["timer"],
        }),
        timerCreate: builder.mutation<Timer, TimerPostRequest>({
            query: (request) => ({
                url: `/api/v1/timer`,
                method: "post",
                body: request.data,
            }),
            invalidatesTags: response =>
                response ? [
                    { type: "timer", id: response.id },
                    { type: "timer-log", id: response.id },
                    "timer"
                ] : ["timer"],
        }),
        timerDelete: builder.mutation<void, string>({
            query: (timerId) => ({
                url: `/api/v1/timer/${timerId}`,
                method: "delete",
            }),
            invalidatesTags: ["timer"],
        }),
        timerHeartbeat: builder.mutation<Timer, string>({
            query: timerId => ({
                url: `/api/v1/timer/${timerId}/heartbeat`,
                method: "post",
            })
        }),
        timerPause: builder.mutation<Timer, string>({
            query: timerId => ({
                url: `/api/v1/timer/${timerId}/pause`,
                method: "post",
            }),
            invalidatesTags: response =>
                response ? [
                    { type: "timer", id: response.id },
                    { type: "timer-log", id: response.id },
                    "timer"
                ] : ["timer"],
        }),
        timerResume: builder.mutation<Timer, string>({
            query: timerId => ({
                url: `/api/v1/timer/${timerId}/resume`,
                method: "post",
            }),
            invalidatesTags: response =>
                response ? [
                    { type: "timer", id: response.id },
                    { type: "timer-log", id: response.id },
                    "timer"
                ] : ["timer"],
        }),
        timerComplete: builder.mutation<Timer, string>({
            query: timerId => ({
                url: `/api/v1/timer/${timerId}/complete`,
                method: "post",
            }),
            invalidatesTags: response =>
                response ? [
                    { type: "timer", id: response.id },
                    { type: "timer-log", id: response.id },
                    "timer"
                ] : ["timer"],
        }),
        timerLogSearch: builder.query<TimerLogListingResponse, TimerLogSearchRequest>({
            query: ({ timerId }) => `/api/v1/timer/${timerId}/logs`,
            providesTags: (response, _, request) =>
                response ? [{ type: "timer-log", id: request.timerId }]
                    : ["timer-log"]
        }),
        timerReport: builder.query<TimerReportResponse, TimerReportRequest>({
            query: request => ({
                url: '/api/v1/timer/report',
                params: request,
            }),
        }),
    }),
})

export const TimerApi = {
    _internal: timerApi,
    useSearchQuery: timerApi.useTimerSearchQuery,
    useSearchQueryState: timerApi.endpoints.timerSearch.useQueryState,
    useGetQuery: timerApi.useTimerGetQuery,
    useCreateMutation: timerApi.useTimerCreateMutation,
    useDeleteMutation: timerApi.useTimerDeleteMutation,
    useHeartbeatMutation: timerApi.useTimerHeartbeatMutation,
    usePauseMutation: timerApi.useTimerPauseMutation,
    useResumeMutation: timerApi.useTimerResumeMutation,
    useCompleteMutation: timerApi.useTimerCompleteMutation,
    useSearchLogsQuery: timerApi.useTimerLogSearchQuery,
    useReportQuery: timerApi.useTimerReportQuery,
}
