import { CommandContext, useCommandRegistry } from "@loryth/commons/command";
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";


export interface ShellContext {
    sidebar: {
        isOpen: boolean
        toggle: () => void
        open: () => void
        close: () => void
    }
}

const Context = createContext<ShellContext | null>(null)

export function useShellContext(): ShellContext {
    const context = useContext(Context)
    if (!context) {
        throw new Error("ShellContext must be initialized")
    }
    return context
}

export interface ShellContextProviderProps {
    children: ReactNode
}

export function ShellContextProvider({ children }: ShellContextProviderProps) {
    const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(true)

    const toggleSidebar = useCallback(() => {
        setSidebarIsOpen(value => !value)
    }, [])

    const openSidebar = useCallback(() => {
        setSidebarIsOpen(true)
    }, [])

    const closeSidebar = useCallback(() => {
        setSidebarIsOpen(false)
    }, [])

    const context = useMemo<ShellContext>(() => {
        return {
            sidebar: {
                isOpen: sidebarIsOpen,
                toggle: toggleSidebar,
                open: openSidebar,
                close: closeSidebar,
            },
        }

    }, [sidebarIsOpen, toggleSidebar, openSidebar, closeSidebar])

    const registry = useCommandRegistry({ create: true })


    return (
        <Context.Provider value={context}>
            <CommandContext.Provider value={registry}>
                {children}
            </CommandContext.Provider>
        </Context.Provider>
    )
}