import { Box, BoxProps, useMultiStyleConfig } from "@chakra-ui/react";
import { SyntheticEvent, useState } from "react";

import { CollapsibleContext, CollapsibleStylesProvider } from "./context";


export interface CollapsibleProps extends BoxProps {
    defaultIsOpen?: boolean
    isOpen?: boolean
    onOpen?: (event: SyntheticEvent<HTMLButtonElement>) => void
    onClose?: (event: SyntheticEvent<HTMLButtonElement>) => void
    onToggle?: (event: SyntheticEvent<HTMLButtonElement>) => void
}

export function Collapsible({
    defaultIsOpen = true,
    isOpen: propIsOpen,
    onOpen,
    onClose,
    onToggle,
    children,
    ...props
}: CollapsibleProps) {
    const [isOpen, setIsOpen] = useState(propIsOpen ?? defaultIsOpen)

    const handleOnToggle = (event: SyntheticEvent<HTMLButtonElement>) => {
        if (onToggle) {
            onToggle(event)
        }

        if (event.isDefaultPrevented()) {
            return
        }

        if (isOpen && onOpen) {
            onOpen(event)
        }

        if (!isOpen && onClose) {
            onClose(event)
        }

        if (event.isDefaultPrevented()) {
            return
        }

        setIsOpen(!isOpen)
    }

    const styles = useMultiStyleConfig("Collapsible")
    return (
        <CollapsibleContext.Provider value={{
            isOpen: propIsOpen ?? isOpen,
            onToggle: handleOnToggle,
        }}>
            <Box className="loryth-collapsible" __css={styles.root} {...props}>
                <CollapsibleStylesProvider value={styles}>
                    {children}
                </CollapsibleStylesProvider>
            </Box>
        </CollapsibleContext.Provider>
    )
}

