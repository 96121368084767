import { useCommand } from "@loryth/commons/command";
import { FormSubmitCommand } from "@loryth/components";
import { useFormContext } from "@loryth/components/forms/FormContext";
import { useState } from "react";

import { Box } from "@chakra-ui/react";

import { Account, AccountGroup, AccountGroupApi } from "@loryth/api";

import { AccountSelect } from "../account/AccountSelect";


export interface AddGroupMemberFormProps {
    accountGroup: AccountGroup
}

export function AddGroupMemberForm({ accountGroup }: AddGroupMemberFormProps) {
    const [upsertMembership] = AccountGroupApi.useUpsertMembershipMutation()
    const [account, setAccount] = useState<Account>()

    const { onSubmitSuccess, onSubmitError } = useFormContext()

    useCommand(FormSubmitCommand, async () => {
        if (!account) {
            return
        }

        try {
            await upsertMembership({
                accountGroupId: accountGroup.id,
                accountId: account.id,
            })
            onSubmitSuccess()
        } catch (error) {
            console.error("failed to upsert membership", error)
            onSubmitError()
        }
    })

    return (
        <Box>
            <AccountSelect value={account} onChange={value => setAccount(value)}/>
        </Box>
    )
}