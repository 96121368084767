import { Tag, TagLabel, TagLeftIcon, TagProps } from "@chakra-ui/react";

import { Account } from "@loryth/api";
import { AccountActiveIcon, AccountPendingIcon } from "@loryth/components/icons";

export interface AccountStatusTagProps extends TagProps {
    account: Account
}

export function AccountStatusTag({ account, children, ...props }: AccountStatusTagProps) {

    if (account.status === "pending") {
        return (
            <Tag colorScheme="orange" {...props}>
                <TagLeftIcon as={AccountPendingIcon}/>
                <TagLabel>pending</TagLabel>
                {children}
            </Tag>
        )
    }

    if (account.status === "active") {
        return (
            <Tag colorScheme="green" {...props}>
                <TagLeftIcon as={AccountActiveIcon}/>
                <TagLabel>active</TagLabel>
                {children}
            </Tag>
        )
    }

    if (account.status === "inactive") {
        return (
            <Tag colorScheme="red" {...props}>
                <TagLeftIcon as={AccountPendingIcon}/>
                <TagLabel>disabled</TagLabel>
                {children}
            </Tag>
        )
    }

    return null

}