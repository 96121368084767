import { Box } from "@chakra-ui/react";
import { IconBox } from "@loryth/components";
import { Collection } from "./Collections";


export interface CollectionPreviewProps {
    collection: Collection
}

export function CollectionPreview({ collection }: CollectionPreviewProps) {
    return (
        <Box
            display="flex" flexDirection="row" alignItems="center"
        >
            <IconBox icon={collection.icon} colorScheme={collection.color}/>
            <Box>
                <Box>{collection.title}</Box>
                <Box>{collection.description}</Box>
            </Box>
        </Box>

    )
}