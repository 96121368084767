import { NavigationGroup, NavigationItem } from "@loryth/components";
import {
    CollectionIcon,
    FolderIcon,
    HomeIcon,
    JournalIcon,
    ProjectIcon,
} from "@loryth/components/icons";
import { useLocation, useRouter } from "wouter";

export function MainNavigation() {
    const [location] = useLocation()
    const router = useRouter()
    return (
        <NavigationGroup title="Menu">
            <NavigationItem
                link="/home"
                title="Home"
                icon={HomeIcon}
                isActive={router.matcher("/home", location)[0]}
            />
            <NavigationItem
                title="Projects"
                icon={ProjectIcon}
                link="/project"
                isActive={router.matcher("/project", location)[0]}
            />
            <NavigationItem
                link="/files"
                title="Files"
                icon={FolderIcon}
                isActive={router.matcher("/files", location)[0]}
            />
            <NavigationItem
                link="/journal"
                title="Journals"
                icon={JournalIcon}
                isActive={router.matcher("/journal", location)[0]}
            />
            <NavigationItem
                link="/collection"
                title="Collections"
                icon={CollectionIcon}
                isActive={router.matcher("/collection", location)[0]}
            />
        </NavigationGroup>
    )
}