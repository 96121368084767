import { useState, KeyboardEvent } from "react";

import { Input, Stack } from "@chakra-ui/react";

import { FilesystemApi, FilesystemNode } from "@loryth/api";
import { useCommand } from "@loryth/commons/command";
import { useFormContext } from "@loryth/components/forms/FormContext";
import { FormSubmitCommand, IconInput } from "@loryth/components";

export interface NewNoteFormProps {
    parent?: FilesystemNode | null
}

export function NewNoteForm({ parent = null }: NewNoteFormProps) {
    const [create] = FilesystemApi.useUpsertNodeMutation()
    const [name, setName] = useState("")
    const [icon, setIcon] = useState<string | null>("note")
    const [color, setColor] = useState<string | null>(null)

    const { onSubmitSuccess, onSubmitError } = useFormContext()

    const submit = useCommand(FormSubmitCommand, async () => {

        try {
            await create({
                node: {
                    parent_id: parent?.id ?? null,

                    type: "note",
                    name,
                    icon,
                    color,

                    metadata: {},
                }
            }).unwrap()
            onSubmitSuccess()
        } catch (error) {
            console.error("failed to create file", error)
            onSubmitError()
        }
    })

    const handleOnKeyDown = (event: KeyboardEvent) => {
        if (event.code === "Enter") {
            event.preventDefault()
            submit()
        }
    }

    return (
        <Stack>
            <Stack direction="row" gap={2}>
                <IconInput
                    icon={icon}
                    onIconChange={icon => setIcon(icon)}
                    useColor
                    color={color}
                    onColorChange={value => setColor(value)}
                    tabIndex={2}
                />
                <Input
                    placeholder="name..."
                    value={name}
                    onChange={e => setName(e.target.value)}
                    tabIndex={1}
                    onKeyDown={handleOnKeyDown}
                />
            </Stack>
        </Stack>
    )
}