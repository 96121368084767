import { forwardRef, ReactNode, useCallback, useRef } from "react";

import {
    Button,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack
} from "@chakra-ui/react";

import { Modal, ModalProps, ModalRef } from "./Modal"


export interface ConfirmationModalProps extends Omit<ModalProps, "children"> {
    title: ReactNode
    message: ReactNode
    onConfirm?: () => void
    onCancel?: () => void
}

export const ConfirmationModal = forwardRef<ModalRef | undefined, ConfirmationModalProps>(({
    title,
    message,
    onConfirm,
    onCancel,
    ...props
}, ref) => {
    const modal = useRef<ModalRef | null>()
    const handleOnConfirm = () => {
        onConfirm?.()
        modal?.current?.close()
    }
    const handleOnCancel = () => {
        onCancel?.()
        modal?.current?.close()
    }
    const handleOnRefChange = useCallback((instance: ModalRef | null) => {
        modal.current = instance

        if (ref instanceof Function) {
            ref(instance)
        } else if (ref) {
            ref.current = instance
        }
    }, [ref])
    return (
        <Modal ref={handleOnRefChange} {...props}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    {message}
                </ModalBody>
                <ModalFooter>
                    <Stack direction="row" spacing={2}>
                        <Button variant="solid" colorScheme="teal" onClick={handleOnConfirm}>
                            Confirm
                        </Button>
                        <Button variant="outline" colorScheme="teal" onClick={handleOnCancel}>
                            Cancel
                        </Button>
                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
})