import { useEffect } from "react";

import { Box, BoxProps } from "@chakra-ui/react";


export interface PageProps extends BoxProps {
    title?: string
}

export function Page({ title = "Loryth Butterfly", ...props }: PageProps) {
    useEffect(() => {
        const originalTitle = document.title
        document.title = title
        return () => {
            document.title = originalTitle
        }
    }, [title]);

    return (
        <Box className="loryth-page" px="4" py="2" {...props}/>
    )
}

