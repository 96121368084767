import Token from "markdown-it/lib/token";


import { isNode, MarkdownItAstNode } from "./MarkdownItAstNode";

type VisitorFn<Result = void> = (node: MarkdownItAstNode | Token) => Result;

interface WalkOptions {
    before?: VisitorFn;
    after?: VisitorFn;
    skip?: VisitorFn<boolean>;
}

export function walkAst(nodes: (MarkdownItAstNode | Token)[], options: WalkOptions) {
    for (const node of nodes) {
        if (options.skip && !options.skip(node)) {
            continue;
        }

        if (options.before) {
            options.before(node);
        }

        if (isNode(node)) {
            walkAst(node.children, options);
        }

        if (options.after) {
            options.after(node);
        }
    }
}