import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers([
    "root", "header", "body"
])

export const CollapsibleTheme = defineMultiStyleConfig({
    baseStyle: definePartsStyle(props => {
        const { colorScheme: c } = props
        return {
            root: {},
            header: {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",

                borderWidth: 1,
                borderRadius: 4,
                borderColor: `${c}.500`,

                px: 4,
                py: 2,

                fontWeight: "bold",

                _hover: {
                    color: `${c}.500`,
                    bg: `${c}.50`,
                },
            },
            body: {},
        }
    }),
    defaultProps: {
        colorScheme: "teal",
    }
})