import { Box, Button, Icon, Menu, MenuButton, MenuItem, MenuList, Spinner, Text } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system";

import { ProjectApi } from "@loryth/api";
import { CheckIcon, ExpandIcon, findIconByName } from "@loryth/components/icons";

export interface TaskProjectSelectProps extends Omit<HTMLChakraProps<"button">, "value" | "onChange"> {
    value?: string
    onChange?: (value: string) => void
    placeholder?: string
    isReadonly?: boolean
}

export function TaskProjectSelect({
    value,
    onChange,
    placeholder = "Select project...",
    isReadonly = false,
    ...props
}: TaskProjectSelectProps) {
    const { isUninitialized, isLoading, data, isError } = ProjectApi.useSearchQuery({})

    if (isError) {
        return null
    }
    if (isUninitialized || isLoading) {
        return <Spinner/>
    }
    const project = data.results.find(x => x.id == value)

    if (isReadonly) {
        return (
            <Box
                borderRadius={4}
                borderWidth={1}
                color={
                    project
                        ? project?.color ? `${project.color}.400` : "black"
                        : "grey.400"
                }
                borderColor="currentColor"
                bg={
                    project?.color ? `${project.color}.50` : "grey.50"
                }
                textAlign="left"
                display="flex"
                flexDirection="row"
                alignItems="center"
                px={2}
                gap={2}
                {...props}
            >
                {project && (
                    <Icon as={findIconByName(project.icon ?? "project")}/>
                )}
                {project?.name ?? placeholder}
            </Box>
        )
    }

    return (
        <Menu matchWidth>
            <MenuButton
                as={Button}
                colorScheme={project?.color ?? undefined}
                color={
                    !project ? "gray.400" : undefined
                }
                leftIcon={
                    project ? <Icon as={findIconByName(project.icon ?? "project")}/> : undefined
                }
                rightIcon={<ExpandIcon/>}
                textAlign="left"
                {...props}
            >
                {project?.name ?? placeholder}
            </MenuButton>
            <MenuList py={0}>
                {data.results.map(option => (
                    <MenuItem
                        key={option.id}
                        color={
                            option.color
                                ? `${option.color}.600`
                                : undefined
                        }
                        bg={
                            option.color
                                ? `${option.color}.100`
                                : undefined
                        }
                        fontWeight={600}
                        icon={
                            option.id == value
                                ? <Icon as={CheckIcon}/>
                                : <Icon color="transparent"/>
                        }

                        onClick={() => onChange?.(option.id)}
                        display="flex"
                        alignItems="center"
                    >
                        <Box as="span" display="flex">
                            <Text as="span">
                                {option.name}
                            </Text>
                            <Icon as={findIconByName(option.icon ?? "project")} ml="auto"/>
                        </Box>
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}