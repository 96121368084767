import { Project } from "@loryth/api";
import {
    MarkdownFormControl,
    Page,
    PageLayout,
    Section,
    SectionBody,
    SectionTitle,
    TextFormControl,
} from "@loryth/components";

import { AccountGroupMembersSection } from "@loryth/features/account-group-membership/AccountGroupMembersSection";

export interface ProjectSettingsPageProps {
    project: Project
}

export function ProjectSettingsPage({ project }: ProjectSettingsPageProps) {
    return (
        <Page>
            <PageLayout>
                <Section>
                    <SectionTitle>General</SectionTitle>
                    <SectionBody>
                        <TextFormControl
                            label="Title"
                            value={project.name}
                        />
                        <MarkdownFormControl
                            label="Description"
                            value={project.description}
                        />
                    </SectionBody>
                </Section>
                <AccountGroupMembersSection
                    accountGroupId={project.account_group_id}
                />
            </PageLayout>
        </Page>
    )

}