import { ReactNode, useContext } from "react";

import { Portal } from "@chakra-ui/react";

import { SidebarProps } from "./Sidebar";
import { SidebarContext } from "./SidebarContext";


export interface SidebarBodyProps {
    children: ReactNode
}

export function SidebarBody({ children }: SidebarProps) {
    const context = useContext(SidebarContext)
    if (!context) {
        throw new Error("SidebarBody cannot be used outside SidebarContext.")
    }
    const { bodyRef } = context
    return (
        <Portal containerRef={bodyRef} children={children}/>
    )
}
