import { modalAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } =
    createMultiStyleConfigHelpers(modalAnatomy.keys)


export const ModalStyle = defineMultiStyleConfig({
    sizes: {
        small: definePartsStyle({
            dialog: { maxW: "md" },
        }),
        medium: definePartsStyle({
            dialog: { maxW: "xl" }
        }),
        large: definePartsStyle({
            dialog: { maxW: "4xl" }
        }),
    }
})