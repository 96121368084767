import {
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal
} from "@chakra-ui/react";
import { Dayjs } from "dayjs";
import { ReactNode } from "react";

export interface HabitHeatmapPopoverProps {
    date: Dayjs
    children: ReactNode
}

export function HabitHeatmapPopover({ date, children }: HabitHeatmapPopoverProps) {
    return (
        <Popover trigger="hover">
            <PopoverTrigger>
                {children}
            </PopoverTrigger>
            <Portal>
                <PopoverContent>
                    <PopoverArrow/>
                    <PopoverCloseButton/>
                    <PopoverHeader>
                        {date.format("DD-MM-YYYY")}
                    </PopoverHeader>
                    <PopoverBody>
                        Did you do it today?
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    )
}