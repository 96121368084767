import { useContext, useMemo } from "react";

import { CommandContext } from "./CommandContext";
import { CommandRegistry } from "./CommandRegistry";

export interface UseRegistryOptions {
    create?: boolean
}
export function useCommandRegistry({ create = false }: UseRegistryOptions = {}): CommandRegistry {
    const activeRegistry = useContext(CommandContext)
    return useMemo(() => {
        if (!create) {
            return activeRegistry
        }
        return new CommandRegistry(activeRegistry)
    }, [activeRegistry, create])
}