import { skipToken } from "@reduxjs/toolkit/query";

import { Spinner } from "@chakra-ui/react";

import { FilesystemApi } from "@loryth/api";
import { formatApiError } from "@loryth/commons/format";
import { Page } from "@loryth/components";

import { FilesystemNodePage } from "./FilesystemNodePage";
import { FilesystemRootPage } from "./FilesystemRootPage";


export interface FilesystemRouteProps {
    params: {
        nodeId?: string
    }
}

export function FilesystemRoute({ params: { nodeId } }: FilesystemRouteProps) {
    const {
        isFetching,
        isLoading,
        isError,
        isUninitialized,
        data: node,
        error
    } = FilesystemApi.useGetNodeQuery(nodeId ?? skipToken, { skip: !nodeId })

    if (!nodeId) {
        return <FilesystemRootPage/>
    }

    if (isFetching || isLoading || isUninitialized) {
        return (
            <Page title="Loading - Notebook">
                <Spinner/>
            </Page>
        )
    }

    if (isError) {
        return (
            <Page title="Error - Notebook">
                {formatApiError(error)}
            </Page>
        )
    }

    return <FilesystemNodePage node={node}/>
}