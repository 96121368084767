import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    "container", "header", "body", "footer"
])

export const FilesystemExplorerStyle = defineMultiStyleConfig({
    baseStyle: {
        container: {
            minHeight: 480,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",

            borderRadius: "md",
            border: 1,
            borderColor: "blackAlpha.200",
            borderStyle: "solid",

            bg: "white",
        },
        header: {
            flex: "0 0 auto",

            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",

            paddingX: 2,
            paddingY: 1,
            paddingBottom: 1,

            borderColor: "blackAlpha.200",
            borderStyle: "solid",
            bg: "gray.100",
        },
        body: {
            flex: "1",
            overflow: "auto",
        }
    },
})