import { useState } from "react";

import { Link as WouterLink } from "wouter"
import { Center, Spinner } from "@chakra-ui/react";

import { FilesystemNode, FilesystemApi } from "@loryth/api";
import {
    AlertApiError,
    Collection,
    CollectionItem,
    FilterButton,
    Page,
    PageLayout,
    SearchInput,
    Section,
    SectionBody,
    useDeviceMode,
    ViewModeInput
} from "@loryth/components";

import { FilesystemNewButton } from "./FilesystemNewButton";
import { FilesystemBreadcrumb } from "./FilesystemBreadcrumb";
import { FilesystemNodeActions } from "./FilesystemNodeActions"
import { FilesystemNodePreview } from "./FilesystemNodePreview";


export interface NotebookFolderPageProps {
    node?: FilesystemNode | null
}

export function FilesystemFolderPage({ node = null }: NotebookFolderPageProps) {
    const [query, setQuery] = useState("")
    const { isLoading, isUninitialized, isError, isSuccess, error, data } = FilesystemApi.useGetTreeQuery({
        parentId: node?.id
    })

    const [viewModeValue, setViewModeValue] = useState<"list" | "box">("box")
    const { isMobile } = useDeviceMode()
    const viewMode = isMobile ? "list" : viewModeValue

    return (
        <Page
            title={node ? `${node.name} - Notebook - Loryth` : "Notebook - Loryth"}
        >
            <PageLayout>
                <FilesystemBreadcrumb
                    nodeId={node?.id ?? null}
                    to={node => node ? `/files/${node.id}` : "/files"}
                />

                <Section>
                    <SectionBody variant="toolbar">
                        <FilesystemNewButton
                            parent={node}
                        />
                        <SearchInput
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                        />
                        {!isMobile && (
                            <ViewModeInput
                                value={viewModeValue}
                                onChange={setViewModeValue}
                            />
                        )}
                        <FilterButton aria-label="filter"/>
                    </SectionBody>

                    <SectionBody variant="elevated" padding={0}>
                        {(isUninitialized || isLoading) && (
                            <Center>
                                <Spinner size="lg"/>
                            </Center>
                        )}
                        {isError && (
                            <AlertApiError error={error}/>
                        )}
                        {isSuccess && (
                            <Collection variant={viewMode}>
                                {data.tree.map(node => (
                                    <CollectionItem
                                        key={node.id}
                                        position="relative"
                                        sx={{
                                            ".loryth-filesystem-actions": {
                                                display: "none",
                                            },
                                            _hover: {
                                                ".loryth-filesystem-actions": {
                                                    display: "block",
                                                },
                                            },
                                        }}
                                    >
                                        <FilesystemNodePreview
                                            node={node}
                                            as={WouterLink}
                                            to={`/files/${node.id}`}
                                            paddingY={2}
                                            paddingX={4}
                                        />
                                        <FilesystemNodeActions
                                            className="loryth-filesystem-actions"
                                            node={node}
                                            colorScheme={node.color ?? "teal"}
                                            sx={{
                                                position: "absolute",
                                                zIndex: 99,
                                                ...(viewMode === "box" ? {
                                                    top: 0,
                                                    right: 0,
                                                } : {}),
                                                ...(viewMode === "list" ? {
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    right: 2,
                                                } : {}),
                                            }}
                                        />
                                    </CollectionItem>
                                ))}
                            </Collection>
                        )}
                    </SectionBody>
                </Section>
            </PageLayout>
        </Page>
    )
}
