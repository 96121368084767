import { Box, Button, Icon, Menu, MenuButton, MenuItem, MenuList, Spinner, Text } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system";

import { TaskTypeApi } from "@loryth/api";
import {
    CheckIcon,
    ExpandIcon,
    findIconByName,
} from "@loryth/components/icons";

export interface TaskTypeSelectProps extends Omit<HTMLChakraProps<"button">, "value" | "onChange"> {
    value: string | null
    onChange?: (value: string) => void

    isReadonly?: boolean
    placeholder?: string
}

export function TaskTypeSelect({
    value,
    onChange,
    isReadonly = false,
    placeholder = "Select task type...",
    ...props
}: TaskTypeSelectProps) {
    const { isUninitialized, isLoading, data, isError } = TaskTypeApi.useSearchQuery({})

    if (isError) {
        return null
    }
    if (isUninitialized || isLoading) {
        return <Spinner/>
    }
    const type = data.results.find(x => x.id == value)

    if (isReadonly) {
        return (
            <Box
                borderRadius={4}
                borderWidth={1}
                color={
                    type
                        ? type?.color ? `${type.color}.400` : "black"
                        : "grey.400"
                }
                borderColor="currentColor"
                bg={
                    type?.color ? `${type.color}.50` : "grey.50"
                }
                textAlign="left"
                display="flex"
                flexDirection="row"
                alignItems="center"
                px={2}
                gap={2}
                width={48}
                {...props}
            >
                {type?.icon && (
                    <Icon as={findIconByName(type.icon)}/>
                )}
                {type?.title ?? placeholder}
            </Box>
        )
    }

    return (
        <Menu matchWidth>
            <MenuButton
                as={Button}
                colorScheme={type?.color ?? undefined}
                color={
                    !type ? "gray.400" : undefined
                }
                leftIcon={
                    type?.icon ? <Icon as={findIconByName(type.icon)}/> : undefined
                }
                rightIcon={<Icon as={ExpandIcon}/>}
                textAlign="left"
                width={48}
                {...props}
            >
                {type?.title ?? placeholder}
            </MenuButton>
            <MenuList py={0}>
                {data.results.map(option => (
                    <MenuItem
                        key={option.id}
                        color={
                            option.color
                                ? `${option.color}.600`
                                : undefined
                        }
                        bg={
                            option.color
                                ? `${option.color}.100`
                                : undefined
                        }
                        fontWeight={600}
                        icon={
                            option.id == value
                                ? <Icon as={CheckIcon}/>
                                : <Icon color="transparent"/>
                        }

                        onClick={() => onChange?.(option.id)}
                        display="flex"
                        alignItems="center"
                    >
                        <Box as="span" display="flex">
                            <Text as="span">
                                {option.title}
                            </Text>
                            {option.icon && (
                                <Icon as={findIconByName(option.icon)} ml="auto"/>
                            )}
                        </Box>
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}