import { Task } from "@loryth/api";
import { TaskClosedIcon, TaskCompletedIcon, TaskInProgressIcon, TaskOpenIcon } from "@loryth/components/icons";

export function getStatusIcon(status: Task["status"]) {
    switch (status) {
        case "open":
            return TaskOpenIcon
        case "in-progress":
            return TaskInProgressIcon
        case "completed":
            return TaskCompletedIcon
        case "closed":
            return TaskClosedIcon
        default:
            return undefined;
    }
}