import { useFormContext } from "@loryth/components/forms/FormContext";
import { ChangeEvent, useState } from "react";

import { Input, Stack } from "@chakra-ui/react";

import { FormSubmitCommand } from "@loryth/components/dialog";
import { useCommand } from "@loryth/commons/command";
import { FilesystemNode, FilesystemApi } from "@loryth/api";


export interface UploadFileFormProps {
    parent?: FilesystemNode | null
}

export function UploadFileForm({ parent = null }: UploadFileFormProps) {
    const [upsertNode] = FilesystemApi.useUpsertNodeMutation()
    const [uploadFile] = FilesystemApi.useUploadMutation()
    const [file, setFile] = useState<File | null>(null)
    const { onSubmitSuccess, onSubmitError } = useFormContext()

    const handleOnFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.length) {
            setFile(null)
        } else {
            setFile(e.target.files[0])
        }
    }
    useCommand(FormSubmitCommand, async () => {
        if (!file) {
            return
        }

        let node: FilesystemNode
        try {
            node = await upsertNode({
                node: {
                    parent_id: parent?.id ?? null,
                    type: "file",
                    name: file.name,
                    icon: null,
                    color: null,
                    metadata: {},
                },
            }).unwrap()
        } catch (error) {
            console.error("file creation failed", error)
            onSubmitError()
            return
        }

        try {
            await uploadFile({
                nodeId: node.id,
                contents: file,
            }).unwrap()
            onSubmitSuccess()
        } catch (error) {
            console.error("file upload failed", error)
            onSubmitError()
            return
        }
    })

    return (
        <Stack>
            <Input type="file" accept="image/*" onChange={handleOnFileChange}/>
        </Stack>
    )
}