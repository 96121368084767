import { useEffect } from "react";
import { Provider as ReduxProvider } from "react-redux"

import { ChakraProvider } from "@chakra-ui/react";

import { AppShell } from "@loryth/features/app-shell/AppShell";
import { GlobalCommandContextProvider } from "@loryth/commons/command/CommandContext";
import { store } from "./store";
import { DefaultTheme } from "./themes";

import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear"

dayjs.extend(weekOfYear)

export function App() {

    useEffect(() => {
        document.title = "Loryth Butterfly"
    }, []);

    return (
        <ReduxProvider store={store}>
            <GlobalCommandContextProvider>
                <ChakraProvider theme={DefaultTheme}>
                    <AppShell/>
                </ChakraProvider>
            </GlobalCommandContextProvider>
        </ReduxProvider>
    )
}
