import { ButtonGroup, IconButton, Stack } from "@chakra-ui/react";
import { useCommandTrigger } from "@loryth/commons/command";
import { MouseEvent, useContext } from "react";
import {
    EditorPreviewIcon,
    EditorSourceIcon,
    FormatBoldIcon,
    FormatCodeIcon,
    FormatItalicIcon,
    FormatOrderedListIcon,
    FormatQuoteIcon,
    FormatStrikethroughIcon,
    FormatUnorderedListIcon
} from "../icons";
import {
    MarkdownFormatBoldCommand,
    MarkdownFormatCodeCommand,
    MarkdownFormatItalicCommand,
    MarkdownFormatOrderedListCommand,
    MarkdownFormatQuoteCommand,
    MarkdownFormatStrikethroughCommand,
    MarkdownFormatUnorderedListCommand
} from "./commands";
import { MarkdownEditorContext } from "./MarkdownEditorContext";


export function MarkdownEditorActions() {
    const editorContext = useContext(MarkdownEditorContext)
    if (!editorContext) {
        throw new Error("MarkdownEditorContext is not defined")
    }
    const { mode, setMode } = editorContext
    const formatBold = useCommandTrigger(MarkdownFormatBoldCommand)
    const formatItalic = useCommandTrigger(MarkdownFormatItalicCommand)
    const formatStrikethrough = useCommandTrigger(MarkdownFormatStrikethroughCommand)
    const formatUnorderedList = useCommandTrigger(MarkdownFormatUnorderedListCommand)
    const formatOrderedList = useCommandTrigger(MarkdownFormatOrderedListCommand)
    const formatCode = useCommandTrigger(MarkdownFormatCodeCommand)
    const formatQuote = useCommandTrigger(MarkdownFormatQuoteCommand)

    return (
        <Stack
            gridArea="editor-actions"
            className="loryth-markdown-editor-actions"
            direction="row"
            gap={2}
        >
            <ButtonGroup variant="outline" isAttached size="sm">
                <IconButton
                    aria-label="Bold"
                    icon={<FormatBoldIcon/>}
                    onMouseDown={withPreventDefault(formatBold)}
                />
                <IconButton
                    aria-label="Italic"
                    icon={<FormatItalicIcon/>}
                    onMouseDown={withPreventDefault(formatItalic)}
                />
                <IconButton
                    aria-label="Strikethrough"
                    icon={<FormatStrikethroughIcon/>}
                    onMouseDown={withPreventDefault(formatStrikethrough)}
                />
            </ButtonGroup>

            <ButtonGroup variant="outline" isAttached size="sm">
                <IconButton
                    aria-label="Unordered List"
                    icon={<FormatUnorderedListIcon/>}
                    onMouseDown={withPreventDefault(formatUnorderedList)}
                />
                <IconButton
                    aria-label="Ordered List"
                    icon={<FormatOrderedListIcon/>}
                    onMouseDown={withPreventDefault(formatOrderedList)}
                />
            </ButtonGroup>

            <ButtonGroup variant="outline" isAttached size="sm">
                <IconButton
                    aria-label="Code"
                    icon={<FormatCodeIcon/>}
                    onMouseDown={withPreventDefault(formatCode)}
                />
                <IconButton
                    aria-label="Quote"
                    icon={<FormatQuoteIcon/>}
                    onMouseDown={withPreventDefault(formatQuote)}
                />
            </ButtonGroup>

            <ButtonGroup variant="outline" isAttached size="sm" marginLeft="auto">
                <IconButton
                    aria-label="Edit Source"
                    icon={<EditorSourceIcon/>}
                    isActive={mode === "source"}
                    onClick={() => setMode("source")}
                />
                <IconButton
                    aria-label="Show Preview"
                    icon={<EditorPreviewIcon/>}
                    isActive={mode === "preview"}
                    onClick={() => setMode("preview")}
                />
            </ButtonGroup>
        </Stack>

    )
}

function withPreventDefault(action: () => void) {
    return (e: MouseEvent) => {
        e.preventDefault()
        action()
    }
}