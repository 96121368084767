import { Center, Spinner } from "@chakra-ui/react";

import { TaskApi } from "@loryth/api";

import { ProjectNavigation } from "./ProjectNavigation";

export interface TaskNavigationProps {
    params: {
        taskId: string
    }
}

export function TaskNavigation({ params: { taskId } }: TaskNavigationProps) {
    const { isUninitialized, isLoading, isError, data: task } = TaskApi.useGetQuery(taskId)
    if (isUninitialized || isLoading) {
        return (
            <Center>
                <Spinner/>
            </Center>
        )
    }
    if (isError) {
        return <>Oops, somethings went wrong.</>
    }
    return (
        <ProjectNavigation params={{ projectId: task.project_id }}/>
    )
}


