import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    "container", "item"
])

export const CollectionStyle = defineMultiStyleConfig({
    baseStyle: {
        container: {},
        item: {},
    },
    variants: {
        list: {
            container: {
                display: "flex",
                flexDirection: "column",
            },
        },
        box: {
            container: {
                display: "grid",
                gap: 2,
                paddingX: 4,
                paddingY: 2,
                gridTemplateColumns: "repeat(auto-fill, minmax(12rem, 1fr))",
                // gridTemplateColumns: "repeat(auto-fit, minmax(200px, minmax(1fr, 250px)))",
                // gridTemplateColumns: {
                //     base: "1fr",
                //     sm: "repeat(3, minmax(0, 1fr))",
                //     md: "repeat(4, minmax(0, 1fr))",
                //     lg: "repeat(6, minmax(0, 1fr))",
                // },
            },
        },
    },
    defaultProps: {
        variant: "list",
    },
})