import { useState } from "react";

import { Input, Stack } from "@chakra-ui/react";

import { FilesystemApi, FilesystemNode } from "@loryth/api";
import { useCommand } from "@loryth/commons/command";
import { FormSubmitCommand, IconInput } from "@loryth/components";
import { useFormContext } from "@loryth/components/forms/FormContext";


export interface FilesystemRenameFormProps {
    node: FilesystemNode
}

export function FilesystemRenameForm({ node }: FilesystemRenameFormProps) {
    const [name, setName] = useState(node.name)
    const [icon, setIcon] = useState<string | null>(node.icon)
    const [color, setColor] = useState<string | null>(node.color)

    const [upsertNode] = FilesystemApi.useUpsertNodeMutation()
    const { onSubmitSuccess, onSubmitError } = useFormContext()

    useCommand(FormSubmitCommand, async () => {
        try {
            await upsertNode({
                id: node.id,
                node: {
                    type: node.type,
                    parent_id: node.parent_id,
                    metadata: node.metadata,
                    name: name,
                    icon: icon,
                    color: color,
                }
            }).unwrap()
            onSubmitSuccess()
        } catch (error) {
            console.error("failed to create/update folder", error)
            onSubmitError()
        }
    })

    return (
        <Stack>
            <Stack direction="row" gap={2}>
                <IconInput
                    icon={icon}
                    onIconChange={icon => setIcon(icon)}
                    useColor
                    color={color}
                    onColorChange={value => setColor(value)}
                />
                <Input
                    placeholder="name..."
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </Stack>
        </Stack>
    )
}