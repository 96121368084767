import Token from "markdown-it/lib/token";
import { MarkdownItAstNode } from "./MarkdownItAstNode";


function genTreeNode(token: Token | null): MarkdownItAstNode {
    return {
        tree: true,
        nodeType: token ? token.type.replace('_open', '') : null,
        openNode: token,
        closeNode: null,
        children: []
    } as unknown as MarkdownItAstNode
}


export function makeAst(tokens: Token[]) {
    // root node
    const rootNode = genTreeNode(null)

    let curr = rootNode
    const stack: MarkdownItAstNode[] = []
    tokens.forEach((token, idx) => {
        if (token.nesting === 1) {
            const tmp = genTreeNode(token)
            curr.children.push(tmp)
            stack.push(curr)
            curr = tmp
        } else if (token.nesting === -1) {
            curr.closeNode = token
            if (!stack.length) {
                throw new Error('AST stack underflow.')
            }
            // TODO: check whether the close node corresponds to the one it opens
            curr = stack.pop() as MarkdownItAstNode
        } else if (token.nesting === 0) {
            curr.children.push(token)
        } else {
            throw new Error('Invalid nesting level found in token index ' + idx + '.')
        }
    });

    if (stack.length !== 0) {
        throw new Error('Unbalanced block open/close tokens.');
    }

    return rootNode.children;
}