import { lorythApi } from "./_api";
import { Habit } from "./habit";

export interface HabitSearchRequest {

}

export interface HabitListResponse {
    results: Habit[]
    page_size: number
    page_number: number
    total: number
}

export interface HabitUpsertRequest {
    id?: string
    data: {
        title: string
    }
}

const habitApi = lorythApi.enhanceEndpoints({
    addTagTypes: ["habit"]
}).injectEndpoints({
    endpoints: builder => ({
        habitSearch: builder.query<HabitListResponse, HabitSearchRequest>({
            query: () => `/api/v1/habit`,
            providesTags: response =>
                response ? [...response.results.map(habit => ({
                        type: "habit" as const,
                        id: habit.id
                    })), "habit"]
                    : ["habit"],
        }),
        habitGet: builder.query<Habit, string>({
            query: habitId => `/api/v1/habit/${habitId}`,
            providesTags: response =>
                response ? [{ type: "habit", id: response.id }, "habit"]
                    : ["habit"],
        }),
        habitUpsert: builder.mutation<Habit, HabitUpsertRequest>({
            query: (request) => ({
                url: !request.id
                    ? `/api/v1/habit`
                    : `/api/v1/habit/${request.id}`,
                method: !request.id ? "post" : "put",
                body: request.data,
            }),
            invalidatesTags: response =>
                response ? [{ type: "habit", id: response.id }, "habit"]
                    : ["habit"],
        }),
        habitDelete: builder.mutation<void, string>({
            query: (habitId) => ({
                url: `/api/v1/habit/${habitId}`,
                method: "delete",
            }),
            invalidatesTags: (response, _, request) =>
                response ? [{ type: "habit", id: request }, "habit"]
                    : ["habit"],
        }),
    })
})

export const HabitApi = {
    _internal: habitApi,
    useSearchQuery: habitApi.useHabitSearchQuery,
    useGetQuery: habitApi.useHabitGetQuery,
    useUpsertMutation: habitApi.useHabitUpsertMutation,
    useDeleteMutation: habitApi.useHabitDeleteMutation,
}
