import { useState } from "react";

export function useControlledInput<T>(value?: T, onChange?: (value: T) => void): [T | undefined, (value: T) => void] {
    const [state, setState] = useState<T>()


    return [value || state, (value: T) => {
        setState(value)
        if (onChange) {
            onChange(value)
        }
    }]
}