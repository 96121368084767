import { NavigationGroup, NavigationItem } from "@loryth/components";
import { SettingsIcon, UserIcon } from "@loryth/components/icons";
import { useLocation, useRouter } from "wouter";

export function AdminNavigation() {
    const [location] = useLocation()
    const router = useRouter()
    return (
        <NavigationGroup title="Admin">
            <NavigationItem
                title="Accounts"
                icon={UserIcon}
                link="/admin/account"
                isActive={router.matcher("/admin/account", location)[0]}
            />
            <NavigationItem
                title="Settings"
                icon={SettingsIcon}
                link="/admin/settings"
                isActive={router.matcher("/admin/settings", location)[0]}
            />
        </NavigationGroup>
    )
}