import { useFormContext } from "@loryth/components/forms/FormContext";
import { useCallback, useState } from "react";

import { FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";

import { Task, TaskApi } from "@loryth/api";
import { useCommand } from "@loryth/commons/command";
import { FormSubmitCommand, MarkdownEditor } from "@loryth/components";
import { TaskProjectSelect } from "./TaskProjectSelect";

import { TaskStatusSelect } from "./TaskStatusSelect";
import { TaskTypeSelect } from "./TaskTypeSelect";


export interface TaskFormProps {
    task?: Task
    initial?: {
        projectId?: string
        typeId?: string
        title?: string
        description?: string
    }
}

export function TaskForm({ task, initial = {} }: TaskFormProps) {
    const [upsertTask] = TaskApi.useUpsertMutation()

    const [projectId, setProjectId] = useState(task?.project_id ?? initial.projectId ?? null)
    const [taskTypeId, setTaskTypeId] = useState(task?.type_id ?? initial.typeId ?? null)
    const [title, setTitle] = useState(task?.title ?? initial.title ?? "")
    const [description, setDescription] = useState(task?.description ?? initial.description ?? "")
    const [status, setStatus] = useState<Task["status"]>(task?.status ?? "open")

    const { onSubmitSuccess, onSubmitError } = useFormContext()

    useCommand(FormSubmitCommand, async () => {
        if (!taskTypeId || !projectId) {
            return
        }

        try {
            await upsertTask({
                taskId: task?.id,
                data: {
                    project_id: projectId,
                    type_id: taskTypeId,
                    title,
                    description,
                    status,
                }
            }).unwrap()
            onSubmitSuccess()
        } catch (error) {
            console.error("failed to create/update task", error)
            onSubmitError()
        }
    })

    const handleDescriptionChange = useCallback((value: string) => setDescription(value), [])

    return (
        <Stack minHeight={480}>
            <Stack direction="row">
                <FormControl>
                    <FormLabel>Project:</FormLabel>
                    <TaskProjectSelect
                        placeholder="Select project..."
                        value={projectId ?? undefined}
                        onChange={value => setProjectId(value)}
                        width="100%"
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Type:</FormLabel>
                    <TaskTypeSelect
                        placeholder="Select task type..."
                        value={taskTypeId}
                        onChange={value => setTaskTypeId(value)}
                        width="100%"
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Status:</FormLabel>
                    <TaskStatusSelect
                        value={status}
                        onChange={value => setStatus(value)}
                        isReadonly={!task}
                        width="100%"
                    />
                </FormControl>
            </Stack>
            <FormControl>
                <FormLabel>Title:</FormLabel>
                <Input value={title} onChange={e => setTitle(e.target.value)} placeholder="Title"/>
            </FormControl>
            <FormControl display="flex" flexDirection="column" flex="1">
                <FormLabel>Description</FormLabel>
                <MarkdownEditor
                    defaultValue={description}
                    onChange={handleDescriptionChange}
                />
            </FormControl>
        </Stack>
    )
}