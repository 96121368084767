import { useMemo } from "react";

import { Stack } from "@chakra-ui/react";

import { TimerApi } from "@loryth/api";
import {
    Widget,
    WidgetCollapseButton,
    WidgetContent,
    WidgetHeader,
    WidgetTitle
} from "@loryth/components";

import { TimerController } from "../timer/TimerController"

import {
    TimerWidgetChart,
    TimerWidgetChartLegend,
    TimerWidgetChartRoot,
    TimerWidgetPeriodButton,
    TimerWidgetChartControls,
} from "./TimerWidgetChart";
import { TimerWidgetList } from "./TimerWidgetList"

export function TimerWidget() {
    const { data } = TimerApi.useSearchQuery({}, {
        // update once a minute
        pollingInterval: 60 * 1000,
    })

    const activeTimer = useMemo(() => {
        if (!data) {
            return
        }
        return data.results.find(timer => timer.status === "active")
    }, [data])

    return (
        <Widget>
            <WidgetHeader>
                <WidgetCollapseButton/>
                <WidgetTitle>Timer</WidgetTitle>
            </WidgetHeader>

            <WidgetContent sx={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
            }}>
                <Stack
                    direction={{ base: "column", lg: "row" }}
                    gap={{ base: 2, lg: 8 }}
                >
                    <TimerController timer={activeTimer} sx={{
                        backgroundColor: "white",

                        borderRadius: "md",
                        borderColor: "blackAlpha.200",
                        borderWidth: 1,

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                        padding: 2,
                    }}/>

                    <TimerWidgetList flex="1"/>
                </Stack>

                <TimerWidgetChartRoot>
                    <TimerWidgetChartControls>
                        <TimerWidgetPeriodButton/>
                    </TimerWidgetChartControls>
                    <TimerWidgetChart/>
                    <TimerWidgetChartLegend/>
                </TimerWidgetChartRoot>
            </WidgetContent>
        </Widget>
    )
}
