import { createContext, useCallback, useContext, useState } from "react"

export interface WidgetContextType {
    isOpen: boolean

    toggle(value: boolean): void
}

export const WidgetContext = createContext<WidgetContextType | null>(null)

export function useWidgetContext() {
    const context = useContext(WidgetContext)
    if (!context) {
        throw new Error("WidgetContext is not initialized.")
    }

    return context
}

export const WidgetProvider = WidgetContext.Provider

export interface UseWidgetOptions {
    initialIsOpen?: boolean
}

export function useWidget({
    initialIsOpen = true,
}: UseWidgetOptions = {}): WidgetContextType {
    const [isOpen, setIsOpen] = useState(initialIsOpen)

    const toggle = useCallback((value: boolean) => setIsOpen(value), [])

    return {
        isOpen,
        toggle,
    }
}