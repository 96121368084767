import { lorythApi } from "./_api"
import { Comment } from "./comment"

interface CommentSearchRequest {
    scope_id: string
    parent_id: string | null
    query?: string
}

interface CommentSearchResponse {
    results: Comment[]
}

interface CommentCreateRequest {
    data: {
        scope_id: string
        parent_id?: string | null
        content: string
        metadata: Record<string, unknown>
    }
}

interface CommentUpdateRequest {
    commentId: string
    data: { content: string } | { metadata: Record<string, unknown> }
}


const commentApi = lorythApi.enhanceEndpoints({
    addTagTypes: ["comment"]
}).injectEndpoints({
    endpoints: builder => ({
        commentSearch: builder.query<CommentSearchResponse, CommentSearchRequest>({
            query: request => ({
                url: "/api/v1/comment",
                method: "get",
                params: request,
            }),
            providesTags: (_response, _error, request) => ["comment", {
                type: "scope" as const,
                id: `comment/${request.scope_id}`
            }],
        }),
        commentGet: builder.query<Comment, string>({
            query: commentId => ({
                url: `/api/v1/comment/${commentId}`,
                method: "get",
            }),
            providesTags: (response) => response ? ["comment", {
                type: "scope" as const,
                id: `comment/${response.scope_id}`
            }] : [],
        }),
        commentCreate: builder.mutation<Comment, CommentCreateRequest>({
            query: ({ data }) => ({
                url: "/api/v1/comment",
                method: "post",
                body: data,
            }),
            invalidatesTags: (response) => response ? ["comment", {
                type: "scope" as const,
                id: `comment/${response.scope_id}`,
            }] : [],
        }),
        commentUpdate: builder.mutation<Comment, CommentUpdateRequest>({
            query: ({ commentId, data }) => ({
                url: `/api/v1/comment/${commentId}`,
                method: "put",
                body: data,
            }),
            invalidatesTags: (response) => response ? ["comment", {
                type: "scope" as const,
                id: `comment/${response.scope_id}`,
            }] : [],
        }),
        commentDelete: builder.mutation<void, Comment>({
            query: (comment) => ({
                url: `/api/v1/comment/${comment.id}`,
                method: "delete",
            }),
            invalidatesTags: (_response, _error, request) => ["comment", {
                type: "scope" as const,
                id: `comment/${request.scope_id}`,
            }],
        }),
    })
})

export const CommentApi = {
    _internal: commentApi,
    useSearchQuery: commentApi.useCommentSearchQuery,
    useGetQuery: commentApi.useCommentGetQuery,
    useCreateMutation: commentApi.useCommentCreateMutation,
    useUpdateMutation: commentApi.useCommentUpdateMutation,
    useDeleteMutation: commentApi.useCommentDeleteMutation,
}