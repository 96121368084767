import { Box, BoxProps } from "@chakra-ui/react";

import { useCollapsibleContext, useCollapsibleStyles } from "./context";


export interface CollapsibleBodyProps extends BoxProps {

}

export function CollapsibleBody({ display, ...props }: CollapsibleBodyProps) {
    const { isOpen } = useCollapsibleContext()
    const styles = useCollapsibleStyles()
    return (
        <Box
            className="loryth-collapsible__body"
            __css={styles.body}
            {...props}
            display={!isOpen ? "none" : display}
        />
    )
}