import {
    Center,
    Icon,
    Spinner,
    Text, chakra, ButtonProps, Button, Stack
} from "@chakra-ui/react"

import { ProjectApi, TaskOrder, TaskStatus, TaskTypeApi } from "@loryth/api"
import {
    Collapsible,
    CollapsibleBody,
    CollapsibleHeader,
    Section,
    SectionProps,
    SectionBody,
    SectionTitle
} from "@loryth/components";
import {
    IconType,
    CheckboxFalseIcon,
    CheckboxTrueIcon,
    findIconByName,
    OrderAscendingIcon,
    OrderDescendingIcon,
    TaskClosedIcon,
    TaskCompletedIcon,
    TaskInProgressIcon,
    TaskOpenIcon
} from "@loryth/components/icons"

import { useTaskFilter } from "./useTaskFilter";

export interface TaskFilterSectionProps extends SectionProps {
    filterId: string
}

/**
 * Function component of TaskFilterMenu
 *
 * TaskFilterMenu is a UI component that provides functionalities for filtering and ordering tasks related to a
 * specific project. It handles changes in filter state and dispatches actions to update filter status in the redux
 * store accordingly.
 *
 * @param filterId - The id of the task filter to use. Common values include "backlog" and project ids.
 * @param children -
 * @param props - Box props.
 */
export function TaskFilterSection({ filterId, children, ...props }: TaskFilterSectionProps) {
    const {
        order,
        orderIsSelected,
        toggleOrder,
        statusIsActive,
        toggleStatus,
    } = useTaskFilter(filterId)

    return (
        <Section width={300} {...props}>
            <SectionTitle>Filter:</SectionTitle>
            <SectionBody>
                <Collapsible>
                    <CollapsibleHeader>Sort by:</CollapsibleHeader>
                    <CollapsibleBody as={Stack} gap={0}>
                        {TaskOrderOptions.map(option => (
                            <TaskFilterOption
                                key={option.value}
                                onClick={() => toggleOrder(option.value)}
                                title={option.title}
                                isActive={orderIsSelected(option.value)}
                                checkIcon={
                                    !orderIsSelected(option.value) ? null
                                        : order.direction === "ascending"
                                            ? OrderAscendingIcon : OrderDescendingIcon
                                }
                            />
                        ))}
                    </CollapsibleBody>
                </Collapsible>

                <Collapsible>
                    <CollapsibleHeader>Filter by status:</CollapsibleHeader>
                    <CollapsibleBody as={Stack} gap={0}>
                        {TaskStatusOptions.map(option => (
                            <TaskFilterOption
                                key={option.value}
                                title={option.title}
                                onClick={() => toggleStatus(option.value)}
                                icon={option.icon}
                                isActive={statusIsActive(option.value)}
                            />
                        ))}
                    </CollapsibleBody>
                </Collapsible>

                <Collapsible defaultIsOpen={false}>
                    <CollapsibleHeader>Filter by type:</CollapsibleHeader>
                    <CollapsibleBody as={Stack} gap={0}>
                        <TaskFilterByType filterId={filterId}/>
                    </CollapsibleBody>
                </Collapsible>

                <Collapsible defaultIsOpen={false}>
                    <CollapsibleHeader>Filter by project:</CollapsibleHeader>
                    <CollapsibleBody as={Stack} gap={0}>
                        <TaskFilterByProject filterId={filterId}/>
                    </CollapsibleBody>
                </Collapsible>

                {children}
            </SectionBody>
        </Section>
    )
}

interface TaskFilterOptionProps extends Omit<ButtonProps, "children"> {
    title: string
    icon?: IconType
    checkIcon?: IconType | null
    isActive?: boolean
}

function TaskFilterOption({ title, icon, checkIcon, isActive = false, ...props }: TaskFilterOptionProps) {
    if (checkIcon === undefined) {
        checkIcon = isActive ? CheckboxTrueIcon : CheckboxFalseIcon
    }
    return (
        <Button
            variant="ghost"
            leftIcon={icon ? <Icon as={icon}/> : <Icon color="transparent"/>}
            rightIcon={
                checkIcon === null ? <Icon color="transparent" fontSize="xl"/>
                    : <Icon as={checkIcon} fontSize="xl"/>
            }
            textAlign="left"
            isActive={isActive}
            _active={{
                color: "teal.400"
            }}
            _hover={{
                color: "teal.400"
            }}
            {...props}
        >
            <chakra.span flex="1">
                {title}
            </chakra.span>
        </Button>
    )
}


interface TaskFilterByTypeProps {
    filterId: string
}

function TaskFilterByType({ filterId }: TaskFilterByTypeProps) {
    const { typeIsSelected, toggleType } = useTaskFilter(filterId)
    const {
        isUninitialized,
        isLoading,
        isError,
        data: types
    } = TaskTypeApi.useSearchQuery({})

    if (isUninitialized || isLoading) {
        return (
            <Center>
                <Spinner/>
            </Center>
        )
    }

    if (isError) {
        return <Text>Oops, something went wrong...</Text>
    }

    return types.results.map(type => (
        <TaskFilterOption
            key={type.id}
            onClick={() => toggleType(type.id)}
            icon={
                type.icon ? findIconByName(type.icon) : undefined
            }
            isActive={typeIsSelected(type.id)}
            title={type.title}
        />
    ))
}


interface TaskFilterByProjectProps {
    filterId: string
}

function TaskFilterByProject({ filterId }: TaskFilterByProjectProps) {
    const { projectIsSelected, toggleProject } = useTaskFilter(filterId)
    const {
        isUninitialized,
        isLoading,
        isError,
        data: projects,
    } = ProjectApi.useSearchQuery({})

    if (isUninitialized || isLoading) {
        return (
            <Center>
                <Spinner/>
            </Center>
        )
    }

    if (isError) {
        return <Text>Oops, something went wrong...</Text>
    }

    return projects.results.map(project => (
        <TaskFilterOption
            key={project.id}
            onClick={() => toggleProject(project.id)}
            icon={project.icon ? findIconByName(project.icon) : undefined}
            isActive={projectIsSelected(project.id)}
            title={project.name}
        />
    ))
}

interface TaskStatusOption {
    value: TaskStatus
    title: string
    icon: IconType
}

const TaskStatusOptions: TaskStatusOption[] = [
    { value: "open", title: "Open", icon: TaskOpenIcon },
    { value: "in-progress", title: "In Progress", icon: TaskInProgressIcon },
    { value: "completed", title: "Completed", icon: TaskCompletedIcon },
    { value: "closed", title: "Closed", icon: TaskClosedIcon },
]

interface TaskOrderOption {
    value: TaskOrder["property"]
    title: string
}

const TaskOrderOptions: TaskOrderOption[] = [
    { value: "title", title: "Title" },
    { value: "status", title: "Status" },
    { value: "created_at", title: "Created" },
    { value: "updated_at", title: "Updated" },
]