import { Popover, PopoverProps } from "@chakra-ui/react";

import { SelectContextProvider } from "./SelectContext";

export interface SelectProps extends PopoverProps {
}

export function Select({ children, ...props }: SelectProps) {

    return (
        <SelectContextProvider value={{}}>
            <Popover matchWidth={true} autoFocus {...props}>
                {children}
            </Popover>
        </SelectContextProvider>
    )
}