import { useFormContext } from "@loryth/components/forms/FormContext";
import { useState } from "react";

import { Input, Stack } from "@chakra-ui/react";

import { FilesystemApi, FilesystemNode } from "@loryth/api";
import { useCommand } from "@loryth/commons/command";
import { FormSubmitCommand, IconInput } from "@loryth/components";

export interface NewFolderFormProps {
    parent?: FilesystemNode | null
}

export function NewFolderForm({ parent = null }: NewFolderFormProps) {
    const [name, setName] = useState("")
    const [icon, setIcon] = useState<string | null>("folder")
    const [color, setColor] = useState<string | null>(null)

    const [upsertNode] = FilesystemApi.useUpsertNodeMutation()

    const { onSubmitSuccess, onSubmitError } = useFormContext()

    useCommand(FormSubmitCommand, async () => {
        try {
            await upsertNode({
                node: {
                    parent_id: parent?.id ?? null,

                    type: "folder",
                    name,
                    icon,
                    color,

                    metadata: {},
                }
            }).unwrap()
            onSubmitSuccess()
        } catch (error) {
            console.error("failed to create/update folder", error)
            onSubmitError()
        }
    })

    return (
        <Stack>
            <Stack direction="row" gap={2}>
                <IconInput
                    icon={icon}
                    onIconChange={icon => setIcon(icon)}
                    useColor
                    color={color}
                    onColorChange={value => setColor(value)}
                />
                <Input
                    placeholder="name..."
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </Stack>
        </Stack>
    )
}