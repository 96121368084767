import { BoxProps, Grid, GridItem } from "@chakra-ui/react";

import { Account } from "@loryth/api";
import {
    AccountAvatar,
    AccountSignature,
    AccountDeleteButton,
    AccountRoleMenu,
    AccountStatusMenu
} from "../account";


export interface AccountAdminPreviewProps extends BoxProps {
    account: Account
}


export function AccountAdminPreview({ account, ...props }: AccountAdminPreviewProps) {
    return (
        <Grid
            className="loryth-account-preview"
            display="grid"
            gridTemplateAreas={{
                base: `
                    "avatar signature signature signature"
                    "role role status delete"
                `,
                lg: `"avatar signature role status delete"`,
            }}
            gridTemplateColumns={{
                base: "auto auto 1fr auto",
                lg: "auto 1fr auto auto auto"
            }}
            columnGap={4}
            rowGap={1}
            alignItems="center"
            {...props}
        >
            <GridItem gridArea="avatar">
                <AccountAvatar account={account}/>
            </GridItem>
            <GridItem gridArea="signature">
                <AccountSignature account={account} flex="1"/>
            </GridItem>
            <GridItem gridArea="role">
                <AccountRoleMenu account={account}/>
            </GridItem>
            <GridItem gridArea="status">
                <AccountStatusMenu account={account}/>
            </GridItem>
            <GridItem gridArea="delete">
                <AccountDeleteButton account={account}/>
            </GridItem>
        </Grid>
    )
}
