import { Icon, IconButton, IconButtonProps } from "@chakra-ui/react"
import { CollapseIcon, ExpandIcon } from "../../icons"
import { useWidgetContext } from "./context"


export function WidgetCollapseButton(props: Omit<IconButtonProps, "aria-label">) {
    const { isOpen, toggle } = useWidgetContext()

    return (
        <IconButton
            variant="ghost"
            size="sm"
            aria-label={
                isOpen ? "Collapse widget" : "Expand Widget"
            }
            icon={
                <Icon as={isOpen ? CollapseIcon : ExpandIcon}/>
            }
            onClick={() => toggle(!isOpen)}
            {...props}
        />
    )
}