import { DependencyList, useEffect, useState } from "react";


export interface UseDebounceValueOptions {
    delay?: number
}

export function useDebounceValue<T>(value: T, deps: DependencyList = [], { delay = 200 }: UseDebounceValueOptions = {}): T {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const handle = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(handle)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, ...(deps.length > 0 ? deps : [value])])

    return debouncedValue
}