import { HTMLChakraProps } from "@chakra-ui/system"
import { Box, Icon } from "@chakra-ui/react";

import { Timer } from "@loryth/api";
import { Timespan } from "@loryth/components";
import { TimerIcon } from "@loryth/components/icons";

import { TimerControls } from "./TimerControls";
import { TimerLabelTag } from "./TimerLabelTag"
import { TimerMetadata } from "./TimerMetadata"
import { useTimerHeartbeat } from "./useTimerHeartbeat";

export interface TimerWidgetRowProps extends HTMLChakraProps<"div"> {
    timer: Timer
}

export function TimerPreview({ timer, ...props }: TimerWidgetRowProps) {
    const duration = useTimerHeartbeat(timer)
    const label = TimerMetadata.label.get(timer.metadata ?? {})

    return (
        <Box
            __css={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "center",
                paddingX: 2,
                paddingY: 1,
            }}
            {...props}
        >
            <Icon as={TimerIcon} fontSize="4xl"/>

            <Box flex="1">
                {label && (<TimerLabelTag label={label}/>)}
            </Box>


            <Timespan
                value={duration}
                fontSize="2xl"
            />


            <TimerControls timer={timer}/>
        </Box>
    )
}


