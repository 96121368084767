import { useCallback, useState } from "react"


export function useToggleList<T>(initial: T[] = []) {
    const [items, setItems] = useState<T[]>(initial)

    const isIncluded = (item: T) => items.includes(item)
    const addItem = useCallback((item: T) => {
        setItems(prevItems => {
            if (prevItems.includes(item)) {
                return prevItems
            }
            return [...prevItems, item]
        })
    }, [])
    const removeItem = useCallback((item: T) => {
        setItems(prevItems => prevItems.filter(x => x !== item))
    }, [])
    const switchItem = useCallback((item: T) => {
        setItems(prevItems => {
            if (prevItems.includes(item)) {
                return prevItems.filter(x => x !== item)
            } else {
                return [...prevItems, item]
            }
        })
    }, [])

    return {
        items,
        isIncluded,
        addItem,
        removeItem,
        switchItem,
    }

}