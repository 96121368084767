import { Stack } from "@chakra-ui/react";

import { AccountGroupMembership } from "@loryth/api";
import { AccountGroupMemberPreview } from "./AccountGroupMemberPreview";


export interface AccountGroupMemberListProps {
    memberships: AccountGroupMembership[]
}

export function AccountGroupMemberList({ memberships }: AccountGroupMemberListProps) {
    return (
        <Stack>
            {memberships.map((membership) => (
                <AccountGroupMemberPreview
                    key={membership.account_id}
                    membership={membership}
                />
            ))}
        </Stack>
    )
}