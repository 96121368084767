import { defineStyleConfig } from "@chakra-ui/react";


export const MarkdownEditorStyle = defineStyleConfig({
    baseStyle: {
        display: "grid",
        gridTemplateAreas: `
            "editor-actions"
            "editor-body"
        `,
        gridTemplateRows: "auto 1fr",
        rowGap: 1,
        flex: 1,
    },
    variants: {}
})