import { Button, Icon, IconButton, Stack } from "@chakra-ui/react";

import { AccountApi } from "@loryth/api";
import { SidebarFooter, useDeviceMode, useShellContext } from "@loryth/components";
import { SignOutIcon } from "@loryth/components/icons";

export function AppSidebarFooter() {
    const [signOut] = AccountApi.useSignOutMutation()
    const { isDesktop } = useDeviceMode()
    const { sidebar: { isOpen } } = useShellContext()

    return (
        <SidebarFooter>
            <Stack>
                {(!isDesktop || isOpen) && (
                    <Button
                        variant="outline" colorScheme="teal"
                        leftIcon={<Icon as={SignOutIcon}/>}
                        justifyContent="flex-start"
                        onClick={() => signOut()}
                    >
                        Logout
                    </Button>
                )}
                {(isDesktop && !isOpen) && (
                    <IconButton
                        aria-label="logout"
                        variant="ghost" colorScheme="teal"
                        icon={<Icon as={SignOutIcon}/>}
                        onClick={() => signOut()}
                    />
                )}
            </Stack>
        </SidebarFooter>
    )
}