import { useEffect, useState } from "react";

import { skipToken } from "@reduxjs/toolkit/query";

import { FilesystemApi, FilesystemNode } from "@loryth/api";
import { useCommand } from "@loryth/commons/command";
import { FormSubmitCommand } from "@loryth/components";
import { useFormContext } from "@loryth/components/forms/FormContext";

import { FilesystemPicker } from "../filesystem-explorer";


export interface FilesystemMoveFormProps {
    node: FilesystemNode
}

export function FilesystemMoveForm({ node }: FilesystemMoveFormProps) {
    const [parent, setParent] = useState<FilesystemNode | null>(null)
    const [upsertNode] = FilesystemApi.useUpsertNodeMutation()
    const { data: nodeParent } = FilesystemApi.useGetNodeQuery(node.parent_id ?? skipToken)

    useEffect(() => {
        if (nodeParent) {
            setParent(nodeParent)
        }
    }, [nodeParent]);

    const { onSubmitSuccess, onSubmitError } = useFormContext()

    useCommand(FormSubmitCommand, async () => {
        try {
            node = await upsertNode({
                id: node.id,
                node: {
                    parent_id: parent?.id ?? null,
                    type: node.type,
                    name: node.name,
                    icon: node.icon,
                    color: node.color,
                    metadata: node.metadata,
                },
            }).unwrap()
            onSubmitSuccess()
        } catch (error) {
            console.error("file creation failed", error)
            onSubmitError()
        }
    })

    return (
        <FilesystemPicker
            value={parent}
            onChange={value => setParent(value)}
            allowedTypes={["folder"]}
        />
    )
}