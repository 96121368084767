export interface Collection {
    id: string
    title: string
    description: string
    color: string | null
    icon: string | null
}

export interface TvShowDetails {
    is_watched: boolean
    rating: number | null
    review: string | null
    episodes_watched: number | null
}

export interface TvShowMetadata {
    summary: string | null
    episodes_total: number | null
    release_date: string | null
}

export interface TvShowSeriesDetails {
    is_watched: boolean
    rating: number | null
    review: string | null
    episodes_watched: number | null
}

export interface TvShowSeriesMetadata {
    summary: string | null
    episodes_total: number | null
    release_date: string | null
}

export interface TvShowEpisodeDetails {
    is_watched: boolean
    rating: number | null
    review: string | null
}

export interface TvShowEpisodeMetadata {
    summary: string | null
    release_date: string | null
    duration: number | null
}

export type CollectionItem = {
    id: string
    type: string
    parent_id: string | null
    collection_id: string
    title: string
    description: string
    color: string | null
    icon: string | null
    details: Record<string, unknown>
    metadata: Record<string, unknown>
}


export const Collections: Collection[] = [
    { id: "movies", title: "Movies", description: "", icon: "movie", color: null },
    { id: "tv-shows", title: "Tv Shows", description: "", icon: "tv-show", color: null },
    { id: "anime", title: "Anime", description: "", icon: "anime", color: null },
    { id: "books", title: "Books", description: "", icon: "book", color: null },
]

export const CollectionItems: CollectionItem[] = [
    {
        id: "item-1",
        parent_id: null,
        type: "tv-show",
        collection_id: "tv-shows",
        title: "Community",
        description: "A suspended lawyer is forced to enroll in a community college with an eccentric staff and student body.",
        icon: "image:488c2b6d-115f-4120-af28-653f81650333",
        color: null,
        details: {},
        metadata: {},
    },
    {
        id: "item-2",
        parent_id: null,
        type: "tv-show",
        collection_id: "tv-shows",
        title: "Rookie",
        description: "Starting over isn't easy, especially for John Nolan who, after a life-altering incident, is pursuing his dream of joining the LAPD. As their oldest rookie, he's met with skepticism from those who see him as just a walking midlife crisis.",
        icon: "image:2aa1a4d8-cf1f-4867-9094-25849154bc60",
        color: null,
        details: {},
        metadata: {},
    },
    {
        id: "item-3",
        parent_id: null,
        type: "tv-show",
        collection_id: "tv-shows",
        title: "Community",
        description: "",
        icon: null,
        color: null,
        details: {},
        metadata: {},
    },
    {
        id: "item-4",
        parent_id: null,
        type: "tv-show",
        collection_id: "tv-shows",
        title: "Community",
        description: "",
        icon: null,
        color: null,
        details: {},
        metadata: {},
    },
]