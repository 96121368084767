import { ReactNode } from "react";

import { BoxProps, Stack } from "@chakra-ui/react";


export interface SectionProps extends BoxProps {
    area?: "main" | "sidebar" | "breadcrumb"
    children?: ReactNode
}

export function Section({ area = "main", children, ...props }: SectionProps) {
    return (
        <Stack as="section" className="loryth-section" gap={4} gridArea={area} {...props}>
            {children}
        </Stack>
    )
}
