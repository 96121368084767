import { Box } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"

export interface WidgetHeaderProps extends HTMLChakraProps<"div"> {

}

export function WidgetHeader(props: WidgetHeaderProps) {
    return (
        <Box
            className="loryth-widget__header"
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "baseline",
                borderBottomWidth: 1,

            }}
            {...props}
        />
    )
}