import { Journal } from "@loryth/api";
import { IconBox, MarkdownText, Page, PageLayout, Section, SectionBody, SectionTitle } from "@loryth/components";


export interface JournalPageProps {
    journal: Journal
}

export function JournalPage({ journal }: JournalPageProps) {
    return (
        <Page>
            <PageLayout>
                <Section>
                    <SectionTitle
                        icon={<IconBox icon={journal.icon} colorScheme={journal.color} size="sm"/>}
                    >
                        {journal.title}
                    </SectionTitle>
                    <SectionBody>
                        <MarkdownText>{journal.description}</MarkdownText>
                    </SectionBody>
                </Section>

                <Section>
                    <SectionTitle size="md">Today</SectionTitle>
                    <SectionBody>

                    </SectionBody>
                </Section>

                <Section>
                    <SectionTitle size="md">Analytics</SectionTitle>
                    <SectionBody>

                    </SectionBody>
                </Section>

                <Section>
                    <SectionTitle size="md">Activities</SectionTitle>
                    <SectionBody>

                    </SectionBody>
                </Section>
            </PageLayout>
        </Page>
    )
}