import { AccountApi } from "@loryth/api";
import { Navigation, NavigationProps, useDeviceMode } from "@loryth/components";
import { useShellContext } from "@loryth/components/layout/Shell/ShellContext";
import { Route, Switch } from "wouter";
import { AdminNavigation } from "./AdminNavigation";
import { MainNavigation } from "./MainNavigation";

import { ProjectNavigation } from "./ProjectNavigation";
import { TaskNavigation } from "./TaskNavigation";

export function AppNavigation() {
    const { isSuccess, data: profile } = AccountApi.useMeQuery()
    const { isDesktop } = useDeviceMode()
    const { sidebar: { isOpen } } = useShellContext()

    let variant: NavigationProps["variant"] = "complete"
    let minWidth: number | "auto" = 220
    if (isDesktop && !isOpen) {
        variant = "minimal"
        minWidth = "auto"
    }

    return (
        <Navigation variant={variant} minWidth={minWidth}>
            <MainNavigation/>
            <Switch>
                <Route path="/project/:projectId/:tab?" component={ProjectNavigation}/>
                <Route path="/task/:taskId?" component={TaskNavigation}/>
            </Switch>
            {isSuccess && profile.role === "admin" && (
                <AdminNavigation/>
            )}
        </Navigation>
    )
}

