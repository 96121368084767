import { Icon, IconButton, IconButtonProps } from "@chakra-ui/react";
import { DeleteIcon } from "../icons";


export function DeleteButton(props: IconButtonProps) {
    return (
        <IconButton
            variant="ghost"
            icon={<Icon as={DeleteIcon}/>}
            {...props}
        />
    )
}