import { useNow } from "@loryth/components"
import { Dayjs } from "dayjs"
import { useState } from "react"

import { useToggleList } from "@loryth/commons/utils"

export type TimerWidgetPeriodType =
    "today"
    | "last-week"
    | "last-month"
    | "last-year"
    | "all"

export interface TimerWidgetPeriod {
    stepUnit: "hour" | "day" | "week" | "month" | "year"
    minDt?: Dayjs
    maxDt?: Dayjs
}

export interface UseTimerWidgetChart {
    periodType: TimerWidgetPeriodType
    period: TimerWidgetPeriod

    setPeriodUnit(unit: TimerWidgetPeriodType): void

    labels: string[]

    isLabelIncluded(label: string): boolean

    addLabel(label: string): void

    removeLabel(label: string): void

    switchLabel(label: string): void
}

export interface UseTimerWidgetChartOptions {
    initialPeriod?: TimerWidgetPeriodType
    initialLabels?: string[]
}

export function useTimerWidgetChart({
    initialPeriod = "today",
    initialLabels = [],
}: UseTimerWidgetChartOptions = {}): UseTimerWidgetChart {
    const now = useNow(5 * 60)
    const [periodType, setPeriodType] = useState<TimerWidgetPeriodType>(initialPeriod)
    const [period, setPeriod] = useState<TimerWidgetPeriod>(getPeriod(initialPeriod, now))
    const {
        items: labels,
        isIncluded: isLabelIncluded,
        addItem: addLabel,
        removeItem: removeLabel,
        switchItem: switchLabel
    } = useToggleList<string>(initialLabels)

    return {
        periodType,
        period,
        setPeriodUnit(unit: TimerWidgetPeriodType) {
            setPeriod(getPeriod(unit, now))
            setPeriodType(unit)
        },
        labels,
        isLabelIncluded,
        addLabel,
        removeLabel,
        switchLabel,
    }
}

function getPeriod(unit: TimerWidgetPeriodType, currentDt: Dayjs): TimerWidgetPeriod {
    switch (unit) {
        case "today":
            return {
                stepUnit: "hour",
                minDt: currentDt.startOf("day"),
                maxDt: currentDt.startOf("day").add(1, "day")
            }
        case "last-week":
            return {
                stepUnit: "day",
                minDt: currentDt.startOf("week"),
                maxDt: currentDt.startOf("week").add(1, "week"),
            }
        case "last-month":
            return {
                stepUnit: "day",
                minDt: currentDt.startOf("month"),
                maxDt: currentDt.startOf("month").add(1, "month"),
            }
        case "last-year":
            return {
                stepUnit: "month",
                minDt: currentDt.startOf("year"),
                maxDt: currentDt.startOf("year").add(1, "year"),
            }
        case "all":
            return {
                stepUnit: "month",
            }
    }
}