import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";


const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers([
    "root", "group", "groupTitle", "item"
])


export const NavigationStyle = defineMultiStyleConfig({
    baseStyle: definePartsStyle(props => {
            const { colorScheme: c } = props
            return {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                },
                group: {
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                },
                groupTitle: {
                    height: 10,
                    py: 1,
                    px: 2,

                    display: "inline-flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 4,


                    borderWidth: 1,
                    borderColor: "transparent",
                    borderBottomColor: mode("gray.200", "gray.400")(props),
                    borderRadius: 0,

                    _hover: {
                        bg: `${c}.50`,
                        color: `${c}.500`,
                        borderColor: `${c}.500`,
                        borderRadius: 4,
                    }
                },
                item: {
                    height: 10,
                    py: 1,
                    px: 2,

                    display: "flex",
                    alignItems: "center",
                    gap: 4,

                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "transparent",

                    cursor: "pointer",

                    _hover: {
                        color: "teal.400",
                        borderColor: "teal.400",
                    },
                    _active: {
                        borderColor: "teal.400",
                        color: "teal.400",
                        bg: "teal.50",
                        textDecoration: "underline",
                    },
                }
            }
        }
    ),
    variants: {
        complete: {},
        minimal: definePartsStyle({
            groupTitle: {
                justifyContent: "center",
                width: 10,
                height: 10,
                "&>*:not(svg:first-of-type)": {
                    display: "none",
                },
            },
            item: {
                justifyContent: "center",
                width: 10,
                height: 10,
                "&>:not(svg)": {
                    display: "none",
                }
            }
        })
    }
    ,
    defaultProps: {
        colorScheme: "teal",
        variant: "complete",
    }
})