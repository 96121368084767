import { Stack } from "@chakra-ui/react";

import { Timer } from "@loryth/api";
import { Page } from "@loryth/components";

import { TimerPageActivity } from "./TimerPageActivity";
import { TimerPageTimer } from "./TimerPageTimer";


export interface TimerPageProps {
    timer: Timer
}

export function TimerPage({ timer }: TimerPageProps) {
    return (
        <Page>
            <Stack gap={2}>
                <TimerPageTimer timer={timer}/>
                <TimerPageActivity timer={timer}/>
            </Stack>
        </Page>
    )
}