import { ReactNode, useContext } from "react";

import { Portal } from "@chakra-ui/react";

import { SidebarContext } from "./SidebarContext";


export interface SidebarHeaderProps {
    children: ReactNode
}

export function SidebarHeader({ children }: SidebarHeaderProps) {
    const context = useContext(SidebarContext)
    if (!context) {
        throw new Error("SidebarHeader cannot be used outside SidebarContext.")
    }
    const { headerRef } = context

    return (
        <Portal containerRef={headerRef} children={children}/>
    )
}
