import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TaskOrder, TaskStatus } from "@loryth/api";
import { deepCopy, deepFreeze } from "@loryth/commons/object";

import { RootState } from "../../store";

const TaskOrderDefaults: Record<TaskOrder["property"], TaskOrder["direction"]> = {
    status: "descending",
    title: "ascending",
    created_at: "descending",
    updated_at: "descending",
}

interface TaskFormState {
    title?: string
    description?: string
}

interface TaskState {
    form: TaskFormState
}

interface TaskFilterState {
    query?: string
    status?: TaskStatus[]
    order: TaskOrder
    types?: string[]
    projects?: string[]
    viewMode: "list" | "cards"
}

interface ProjectFormState {
    name?: string
    description?: string
}

interface ProjectState {
    form: ProjectFormState
}

interface TaskFeatureState {
    tasks: Record<string, TaskState>
    projects: Record<string, ProjectState>

    filters: Record<string, TaskFilterState>
}

const initialState: TaskFeatureState = deepFreeze({
    tasks: {},
    projects: {},
    filters: {},
})
const initialTaskState: TaskState = deepFreeze({
    form: {},
})
const initialProjectState: ProjectState = deepFreeze({
    form: {},
})
const initialFilterState: TaskFilterState = deepFreeze({
    order: {
        property: "updated_at" as const,
        direction: "descending" as const,
    },
    viewMode: "list",
})

const slice = createSlice({
    name: "task-feature",
    initialState,
    reducers: {
        updateTaskForm(state, action: PayloadAction<{ taskId: string } & TaskFormState>) {
            const { taskId, ...update } = action.payload
            const task = state.tasks[taskId] = getTaskOrDefault(state.tasks, taskId, true)
            task.form = update
        },
        patchTaskForm(state, action: PayloadAction<{ taskId: string } & Partial<TaskFormState>>) {
            const { taskId, ...patch } = action.payload
            const task = state.tasks[taskId] = getTaskOrDefault(state.tasks, taskId, true)
            task.form = {
                ...task.form,
                ...patch
            }
        },
        updateFilter(state, action: PayloadAction<{ filterId: string } & TaskFilterState>) {
            const { filterId, ...update } = action.payload
            state.filters[filterId] = update
        },
        patchFilter(state, action: PayloadAction<{ filterId: string } & Partial<TaskFilterState>>) {
            const { filterId, ...patch } = action.payload
            state.filters[filterId] = {
                ...getFilterOrDefault(state.filters, filterId),
                ...patch,
            }
        },
        toggleFilterStatus(state, action: PayloadAction<{
            filterId: string
            status: TaskStatus[]
        }>) {
            const { filterId, status: statusUpdate } = action.payload

            const filter = state.filters[filterId] = getFilterOrDefault(state.filters, filterId, true)
            const { status: statusCurrent = [] } = filter

            const newStatus = statusUpdate.filter(x => !statusCurrent.includes(x))
            if (newStatus.length > 0) {
                filter.status = [...statusCurrent, ...statusUpdate]
            } else {
                filter.status = statusCurrent.filter(x => !statusUpdate.includes(x))
            }
        },
        toggleFilterOrder(state, action: PayloadAction<{
            filterId: string
            property: TaskOrder["property"]
            direction?: TaskOrder["direction"]
        }>) {
            const { filterId, property: propertyUpdate, direction: directionUpdate } = action.payload
            const filter = state.filters[filterId] = getFilterOrDefault(state.filters, filterId, true)

            if (filter.order.property !== propertyUpdate) {
                // If property does not match current selection, update
                //  to default selection.
                filter.order = {
                    property: propertyUpdate,
                    direction: directionUpdate ?? TaskOrderDefaults[propertyUpdate],
                }
            } else {
                // Toggle direction.
                filter.order = {
                    property: propertyUpdate,
                    direction: filter.order.direction === "ascending" ? "descending" : "ascending",
                }
            }
        },
        toggleFilterType(state, action: PayloadAction<{
            filterId: string
            types: string[]
        }>) {
            const { filterId, types: typesUpdate } = action.payload
            const filter = state.filters[filterId] = getFilterOrDefault(state.filters, filterId, true)
            const { types: typesCurrent = [] } = filter
            const newTypes = typesUpdate.filter(x => !typesCurrent.includes(x))
            if (newTypes.length > 0) {
                filter.types = [...typesCurrent, ...newTypes]
            } else {
                filter.types = typesCurrent.filter(x => !typesUpdate.includes(x))
            }
        },
        toggleFilterProject(state, action: PayloadAction<{
            filterId: string
            projects: string[]
        }>) {
            const { filterId, projects: projectsUpdate } = action.payload
            const filter = state.filters[filterId] = getFilterOrDefault(state.filters, filterId, true)
            const { projects: projectsCurrent = [] } = filter
            const newProjects = projectsUpdate.filter(x => !projectsCurrent.includes(x))
            if (newProjects.length > 0) {
                filter.projects = [...projectsCurrent, ...newProjects]
            } else {
                filter.projects = projectsCurrent.filter(x => !projectsUpdate.includes(x))
            }
        },
        toggleFilterViewMode(state, action: PayloadAction<{
            filterId: string
            viewMode?: "list" | "cards"
        }>) {
            const { filterId, viewMode: newViewMode } = action.payload
            const filter = state.filters[filterId] = getFilterOrDefault(state.filters, filterId, true)
            const { viewMode: currentViewMode } = filter

            if (newViewMode) {
                filter.viewMode = newViewMode
            } else {
                filter.viewMode = currentViewMode === "list" ? "cards" : "list"
            }
        },
        updateProjectForm(state, action: PayloadAction<{ projectId: string } & ProjectFormState>) {
            const { projectId, ...update } = action.payload
            const project = state.projects[projectId] = getProjectOrDefault(state.projects, projectId, true)
            project.form = update
        },
        patchProjectForm(state, action: PayloadAction<{ projectId: string } & Partial<ProjectFormState>>) {
            const { projectId, ...patch } = action.payload
            const project = state.projects[projectId] = getProjectOrDefault(state.projects, projectId, true)
            project.form = {
                ...project.form,
                ...patch
            }
        },
    }
})


function getTaskOrDefault(state: Record<string, TaskState>, taskId: string, newInstance = false) {
    if (taskId in state) {
        return state[taskId]
    }
    if (newInstance) {
        return deepCopy(initialTaskState)
    }
    return initialTaskState
}

function getProjectOrDefault(state: Record<string, ProjectState>, projectId: string, newInstance = false) {
    if (projectId in state) {
        return state[projectId]
    }
    if (newInstance) {
        return deepCopy(initialProjectState)
    }
    return initialProjectState
}

function getFilterOrDefault(state: Record<string, TaskFilterState>, filterId: string, newInstance = false) {
    if (filterId in state) {
        return state[filterId]
    }
    if (newInstance) {
        return deepCopy(initialFilterState)
    }
    return initialFilterState
}

const selectTaskFeature = (state: RootState) => state.features.task
const selectTask = createSelector(
    [
        selectTaskFeature,
        (_, taskId: string) => taskId
    ],
    (state, taskId) => getTaskOrDefault(state.tasks, taskId)
)

const selectFilter = createSelector(
    [
        selectTaskFeature,
        (_, filterId: string) => filterId
    ],
    (state, filterId) => getFilterOrDefault(state.filters, filterId),
)
const selectProject = createSelector(
    [
        selectTaskFeature,
        (_, projectId: string) => projectId
    ],
    (state, projectId) => getProjectOrDefault(state.projects, projectId),
)


export const TaskFeatureReducer = slice.reducer
export const TaskFeature = {
    selectTask,
    updateTaskForm: slice.actions.updateTaskForm,
    patchTaskForm: slice.actions.patchTaskForm,
    selectFilter,
    updateFilter: slice.actions.updateFilter,
    patchFilter: slice.actions.patchFilter,
    toggleFilterStatus: slice.actions.toggleFilterStatus,
    toggleFilterOrder: slice.actions.toggleFilterOrder,
    toggleFilterType: slice.actions.toggleFilterType,
    toggleFilterProject: slice.actions.toggleFilterProject,
    toggleFilterViewMode: slice.actions.toggleFilterViewMode,
    selectProject,
    updateProjectForm: slice.actions.updateProjectForm,
    patchProjectForm: slice.actions.patchProjectForm,
}

