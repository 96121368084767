import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers([
    "root", "image", "icon", "name"
])


const baseStyle = definePartsStyle((props) => {
    const { colorScheme: c, isActive } = props

    return {
        root: {
            _hover: {
                bg: `${c}.50`,
            },

            ...(!isActive ? {} : {
                bg: `${c}.100`,
                color: `${c}.400`,
            }),
        },
        image: {
            objectFit: "contain",

            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "blackAlpha.200",
            borderRadius: "md",
        },
        name: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        }
    }
})

const boxStyle = definePartsStyle({
    root: {
        height: 48,
        overflow: "hidden",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,

        borderRadius: "md",
    },
    icon: {
        flex: 1,

        width: "100%",
    },
    image: {
        flex: 1,

        overflow: "hidden",
        maxWidth: "100%",
        margin: "auto",
    },
    name: {
        flex: "0 0 auto",
        maxWidth: "100%",
    }
})

const listStyle = definePartsStyle({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
    },
    icon: {
        flex: "0 0 auto",
        width: 16,
        height: 16,
    },
    image: {
        width: 16,
        height: 16,
    },
    name: {
        flex: 1,
    },
})


export const FilesystemNodePreviewStyle = defineMultiStyleConfig({
    baseStyle,
    variants: {
        box: boxStyle,
        list: listStyle,
    },
    defaultProps: {
        variant: "list",
        colorScheme: "teal",
    }
})