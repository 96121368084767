import { ReactNode, useContext } from "react";

import { Portal } from "@chakra-ui/react";

import { SidebarContext } from "./SidebarContext";


export interface SidebarFooterProps {
    children: ReactNode
}

export function SidebarFooter({ children }: SidebarFooterProps) {
    const context = useContext(SidebarContext)
    if (!context) {
        throw new Error("SidebarFooter cannot be used outside SidebarContext.")
    }
    const { footerRef } = context

    return (
        <Portal containerRef={footerRef} children={children}/>
    )
}