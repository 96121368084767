import { chakra } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"
import { Dayjs } from "dayjs"

export interface TimestampProps extends HTMLChakraProps<"div"> {
    timestamp: Dayjs
    format?: string
}

export function Timestamp({ timestamp, format = "datetime", ...props }: TimestampProps) {
    return (
        <chakra.span {...props}>{timestamp.format(format)}</chakra.span>
    )
}