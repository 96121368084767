import { useContext, useEffect } from "react";

import { CommandContext } from "./CommandContext";
import { CommandHandler, CommandKey, CommandRegistry, CommandTrigger } from "./CommandRegistry";
import { useCommandTrigger } from "./useCommandTrigger";

export function useCommand<Payload>(key: CommandKey<Payload>, handler: CommandHandler<Payload>, registry?: CommandRegistry): CommandTrigger<Payload> {
    const contextRegistry = useContext(CommandContext)
    const activeRegistry = registry || contextRegistry

    useEffect(() => {
        return activeRegistry.subscribe(key, handler)
    }, [activeRegistry, key, handler])

    return useCommandTrigger(key, activeRegistry)
}