import { Account } from "./account";
import { lorythApi } from "./_api";


interface SignInRequest {
    username: string
    password: string
}

interface SignUpRequest {
    username: string
    email: string
    password: string
}

interface PasswordRecoveryRequest {
    email: string
}

interface AdminAccountSearchRequest {
    query?: string
}

interface AdminAccountListingResponse {
    results: Account[]
    page_number: number
    page_size: number
    total: number
}

interface AdminUpdateAccountRequest {
    accountId: string
    data: {
        status?: string
        role?: string
    }
}

interface AccountSearchRequest {
    query?: string
}

interface AccountSearchResponse {
    results: Account[]
}


const accountApi = lorythApi.enhanceEndpoints({
    addTagTypes: ["account"],
}).injectEndpoints({
        endpoints: builder => ({
            me: builder.query<Account, void>({
                query: () => ({
                    url: "/api/v1/me",
                }),
                providesTags: ["me"],
            }),
            signIn: builder.mutation<void, SignInRequest>({
                query: request => ({
                    url: "/api/v1/account/sign-in",
                    method: "post",
                    body: request,
                }),
                invalidatesTags: ["me"],
            }),
            signUp: builder.mutation<void, SignUpRequest>({
                query: request => ({
                    url: "/api/v1/account/sign-up",
                    method: "post",
                    body: request,
                }),
                invalidatesTags: ["me"],
            }),
            signOut: builder.mutation<void, void>({
                query: () => ({
                    url: "/api/v1/account/sign-out",
                    method: "post"
                }),
                invalidatesTags: ["me"],
            }),
            passwordRecovery: builder.mutation<void, PasswordRecoveryRequest>({
                query: request => ({
                    url: "/api/v1/account/password-recovery",
                    method: "post",
                    body: request,
                }),
                invalidatesTags: ["me"],
            }),
            adminGetAccounts: builder.query<AdminAccountListingResponse, AdminAccountSearchRequest>({
                query: request => ({
                    url: "/api/v1/admin/account",
                    method: "get",
                    params: request,
                }),
                providesTags: ["account"],
            }),
            adminUpdateAccount: builder.mutation<void, AdminUpdateAccountRequest>({
                query: request => ({
                    url: `/api/v1/admin/account/${request.accountId}`,
                    method: "put",
                    body: request.data,
                }),
                invalidatesTags: ["account"],
            }),
            adminDeleteAccount: builder.mutation<void, string>({
                query: accountId => ({
                    url: `/api/v1/admin/account/${accountId}`,
                    method: "delete",
                }),
                invalidatesTags: ["account"],
            }),
            accountGet: builder.query<Account, string>({
                query: accountId => ({
                    url: `/api/v1/account/${accountId}`,
                    method: "get",
                }),
                providesTags: ["account"],
            }),
            accountSearch: builder.query<AccountSearchResponse, AccountSearchRequest>({
                query: request => ({
                    url: "/api/v1/account",
                    method: "get",
                    params: request,
                }),
                providesTags: ["account"],
            }),
        }),
    }
)


export const AccountApi = {
    _internal: accountApi,
    useMeQuery: accountApi.useMeQuery,
    useSignInMutation: accountApi.useSignInMutation,
    useSignUpMutation: accountApi.useSignUpMutation,
    useSignOutMutation: accountApi.useSignOutMutation,
    usePasswordRecoveryMutation: accountApi.usePasswordRecoveryMutation,
    useGetQuery: accountApi.useAccountGetQuery,
    useSearchQuery: accountApi.useAccountSearchQuery,
    admin: {
        useSearchQuery: accountApi.useAdminGetAccountsQuery,
        useUpdateMutation: accountApi.useAdminUpdateAccountMutation,
        useDeleteMutation: accountApi.useAdminDeleteAccountMutation,
    },
    useAdminGetAccountsQuery: accountApi.useAdminGetAccountsQuery,
    useAdminUpdateAccountMutation: accountApi.useAdminUpdateAccountMutation,
    useAdminDeleteAccountMutation: accountApi.useAdminDeleteAccountMutation,
}