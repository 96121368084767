import { Button, Icon, IconButton, Input, InputGroup, InputLeftAddon, Stack, StackDivider } from "@chakra-ui/react";
import { AccountApi } from "@loryth/api";
import { Page, PageLayout, Section, SectionBody, SectionTitle } from "@loryth/components";
import { FilterIcon, NewIcon, SearchIcon } from "@loryth/components/icons";
import { useState } from "react";
import { AccountAdminPreview } from "./AccountAdminPreview";


export function AccountAdminPage() {
    const [query, setQuery] = useState("")
    const { isSuccess, data: accounts } = AccountApi.useAdminGetAccountsQuery({ query })
    const [filterIsVisible, setFilterIsVisible] = useState<boolean>(false);

    return (
        <Page title="Accounts - Admin - Loryth" height="full">
            <PageLayout variant={
                filterIsVisible ? "sidebar" : "standard"
            }>
                <Section flex="1">
                    <SectionTitle>Accounts</SectionTitle>
                    <SectionBody variant="toolbar">
                        <Button variant="solid" colorScheme="teal"
                                leftIcon={<Icon as={NewIcon}/>}>
                            New
                        </Button>

                        <InputGroup>
                            <InputLeftAddon>
                                <Icon as={SearchIcon}/>
                            </InputLeftAddon>
                            <Input
                                placeholder="Search..."
                                value={query}
                                onChange={event => setQuery(event.target.value)}
                            />
                        </InputGroup>

                        <IconButton
                            variant="ghost"
                            aria-label="account filter"
                            icon={<Icon as={FilterIcon}/>}
                            onClick={() => setFilterIsVisible(!filterIsVisible)}
                        />

                    </SectionBody>
                    <SectionBody variant="elevated">
                        {isSuccess && (
                            <Stack divider={<StackDivider/>}>
                                {accounts.results.map(account => (
                                    <AccountAdminPreview key={account.id} account={account}/>
                                ))}
                            </Stack>
                        )}
                    </SectionBody>
                </Section>

                {filterIsVisible && (
                    <Section area="sidebar" minWidth={300}>
                        <SectionTitle>Filter:</SectionTitle>
                    </Section>
                )}
            </PageLayout>
        </Page>
    )
}