import { chakra, useMultiStyleConfig } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system";
import { CollectionContextProvider } from "./CollectionContextType"

import { CollectionStylesProvider } from "./CollectionStylesContext";

export interface CollectionProps extends HTMLChakraProps<"div"> {
    variant: "list" | "box"
}


export function Collection({ variant, children, ...props }: CollectionProps) {
    const styles = useMultiStyleConfig("Collection", { variant })
    return (
        <CollectionContextProvider variant={variant}>
            <chakra.div className="loryth-collection" __css={styles.container} {...props}>
                <CollectionStylesProvider value={styles}>
                    {children}
                </CollectionStylesProvider>
            </chakra.div>
        </CollectionContextProvider>
    )
}