import { Center, Flex, Stack } from "@chakra-ui/react";

import { Timer } from "@loryth/api";
import { Timespan } from "@loryth/components";

import { TimerControls } from "../timer/TimerControls";
import { useTimerHeartbeat } from "../timer/useTimerHeartbeat";


export interface TimerPageTimerProps {
    timer: Timer
}

export function TimerPageTimer({ timer }: TimerPageTimerProps) {
    const duration = useTimerHeartbeat(timer)
    return (
        <Center>
            <Stack>
                <Timespan value={duration} TextProps={{
                    fontSize: { base: 64, sm: 96, md: 128 },
                }}/>
                <Flex justifyContent="center">
                    <TimerControls timer={timer}/>
                </Flex>
            </Stack>
        </Center>

    )
}