import { useCallback } from "react";

import { FormControl, FormLabel } from "@chakra-ui/react";

import { Task, TaskApi, TaskStatus } from "@loryth/api";
import {
    MarkdownFormControl,
    TextFormControl,
    Page,
    PageLayout,
    Section,
    SectionBody,
    SectionTitle
} from "@loryth/components";
import { TaskFeature } from "@loryth/features/task";
import { TaskStatusSelect } from "@loryth/features/task-form";

import { useAppDispatch, useAppSelector } from "../../store";
import { TaskTypeSelect } from "../task-form/TaskTypeSelect";

import { TaskActivities } from "./TaskActivities";
import { TaskBreadcrumb } from "./TaskBreadcrumb";

export interface TaskPageProps {
    task: Task
}

export function TaskPage({ task }: TaskPageProps) {
    const { form, isSubmitting, change, submit } = useTaskForm(task)

    return (
        <Page title={`${task.title} - Loryth`}>
            <PageLayout variant="sidebar">
                <Section area="breadcrumb">
                    <TaskBreadcrumb task={task}/>
                </Section>

                <Section>
                    <SectionBody variant="standard">
                        <TextFormControl
                            label="Title:"
                            value={task.title}
                            editValue={form.title}
                            onChange={value => change({ title: value })}
                            onSubmit={value => submit({ title: value })}
                            isSubmitting={isSubmitting}
                        />

                        <MarkdownFormControl
                            label="Description:"
                            value={task.description}
                            editValue={form.description}
                            onChange={value => change({ description: value })}
                            onSubmit={value => submit({ description: value })}
                            isSubmitting={isSubmitting}
                        />
                    </SectionBody>
                </Section>

                <Section>
                    <SectionTitle>Activities</SectionTitle>
                    <SectionBody variant="standard">
                        <TaskActivities task={task}/>
                    </SectionBody>
                </Section>

                <Section area="sidebar" minWidth={300}>
                    <SectionBody variant="standard">
                        <FormControl>
                            <FormLabel>Type:</FormLabel>
                            <TaskTypeSelect
                                value={task.type_id}
                                onChange={value => submit({ type_id: value })}
                                width="100%"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Status:</FormLabel>
                            <TaskStatusSelect
                                value={task.status}
                                onChange={value => submit({ status: value })}
                                width="100%"
                            />
                        </FormControl>
                    </SectionBody>
                </Section>
            </PageLayout>
        </Page>
    )
}

type TaskChange = {
    project_id?: string
    type_id?: string
    title?: string
    description?: string
    status?: TaskStatus
}

function useTaskForm(task: Task) {
    const [upsertTask, { isLoading }] = TaskApi.useUpsertMutation()
    const dispatch = useAppDispatch()
    const { form } = useAppSelector(state => TaskFeature.selectTask(state, task.id))

    const change = useCallback((changes: TaskChange) => {
        changes = Object.fromEntries(
            Object.entries(changes).map(([key, value]) => task[key as keyof TaskChange] !== value ? [key, value] : [key, undefined])
        )
        dispatch(TaskFeature.patchTaskForm({
            taskId: task.id,
            ...changes,
        }))
    }, [dispatch, task])

    const submit = useCallback(async (changes: TaskChange) => {
        try {
            await upsertTask({
                taskId: task.id,
                data: {
                    project_id: task.project_id,
                    type_id: task.type_id,
                    title: task.title,
                    description: task.description,
                    status: task.status,
                    ...changes,
                }
            })
        } catch (error) {
            console.error("Failed to update task.", error)
            return
        }

        dispatch(TaskFeature.patchTaskForm({
            taskId: task.id,
            ...Object.fromEntries(
                Object.keys(changes).map((key) => [key, undefined])
            ),
        }))
    }, [dispatch, upsertTask, task])

    return {
        form,
        change,
        isSubmitting: isLoading,
        submit,
    }
}