import { defineStyle, defineStyleConfig } from "@chakra-ui/react";


const sizes = {
    lg: defineStyle({
        h: "12",
        minW: "12",
        fontSize: "lg"
    }),
    md: defineStyle({
        h: "10",
        minW: "10",
        fontSize: "md"
    }),
    sm: defineStyle({
        h: "8",
        minW: "8",
        fontSize: "sm"
    }),
    xs: defineStyle({
        h: "6",
        minW: "6",
        fontSize: "xs"
    }),
}

const variants = {
    solid: defineStyle(props => {
        const { colorScheme: c } = props

        return {
            bg: `${c}.100`,
            color: `${c}.500`,
            borderColor: `blackAlpha.200`,
            borderWidth: 1,
            borderStyle: "solid",
        }
    }),
    outline: defineStyle(props => {
        const { colorScheme: c } = props

        return {
            color: `${c}.500`,
            borderWidth: 1,
            borderColor: `${c}.500`,
            bg: `${c}.50`,
            _hover: {
                backgroundColor: `${c}.100`,
            }
        }
    }),
    ghost: defineStyle(props => {
        const { colorScheme: c } = props

        return {
            color: `${c}.500`,
        }
    }),
}

export const IconBoxStyle = defineStyleConfig({
    baseStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "md",
    },
    sizes,
    variants,
    defaultProps: {
        variant: "solid",
        size: "md",
        colorScheme: "gray",
    }
})