import { chakra } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system";
import { useCollectionStyles } from "./CollectionStylesContext";


export interface CollectionItemProps extends HTMLChakraProps<"div"> {

}

export function CollectionItem(props: CollectionItemProps) {
    const styles = useCollectionStyles()
    return (
        <chakra.div className="loryth-collection-item" __css={styles.item} {...props}/>
    )
}