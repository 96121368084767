import { Box, createStylesContext, useMultiStyleConfig } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system";

export const [StylesProvider, useStyles] = createStylesContext("Navigation")


export interface NavigationProps extends HTMLChakraProps<typeof Box> {
    variant?: "complete" | "minimal"
}

export function Navigation({ variant, children, ...props }: NavigationProps) {
    const styles = useMultiStyleConfig('Navigation', { variant })
    return (
        <Box className="loryth-navigation" __css={styles.root} {...props}>
            <StylesProvider value={styles}>
                {children}
            </StylesProvider>
        </Box>
    )
}

