import { useCallback, useEffect, useRef } from "react";

type Update<State> = State | ((state: State) => State)

interface HistoryItem<State> {
    state: State
    type: string | null
    previous: HistoryItem<State> | null
    next: HistoryItem<State> | null
    size: number
}

interface History<State> {
    current: State

    push(state: Update<State>, type?: string | null): void;

    undo(): void;

    redo(): void;

    reset(state?: State): void
}

export interface HistoryChangeEvent<State> {
    state: State
    type: string | null
}

export interface UseHistoryOptions<State> {
    onChange?: (event: HistoryChangeEvent<State>) => void
}


export function useHistory<State>(initialState: State, { onChange }: UseHistoryOptions<State> = {}): History<State> {
    const $item = useRef<HistoryItem<State>>({
        state: initialState,
        type: "initial",
        previous: null,
        next: null,
        size: 0
    })

    const onChangeRef = useRef<UseHistoryOptions<State>["onChange"]>(onChange)
    useEffect(() => {
        onChangeRef.current = onChange
        return () => {
            onChangeRef.current = undefined
        }
    }, [onChange]);

    const push = useCallback((state: Update<State>, type: string | null = null) => {
        const currentItem = $item.current
        if (state instanceof Function) {
            state = state(currentItem.state)
        }

        // Update type is defined and matches the previous item,
        //  we can merge the state to preserve space.
        if (type != null && currentItem.type === type) {
            const newItem = {
                ...currentItem,
                state,
            }
            if (currentItem.next) {
                currentItem.next.previous = newItem
            }
            if (currentItem.previous) {
                currentItem.previous.next = newItem
            }

            $item.current = newItem
            if (onChangeRef.current) {
                onChangeRef.current({ state, type: newItem.type })
            }
        }

        // Push new record to the stack.
        const newItem = {
            state,
            type: type ?? null,
            previous: currentItem,
            next: null,
            size: currentItem.size + 1,
        }
        currentItem.next = newItem
        $item.current = newItem

        if (onChangeRef.current) {
            onChangeRef.current({ state, type: newItem.type })
        }

    }, [])

    const undo = useCallback(() => {
        const currentItem = $item.current
        if (!currentItem.previous) {
            return
        }

        $item.current = currentItem.previous
        if (onChangeRef.current) {
            onChangeRef.current({
                state: $item.current.state,
                type: "undo",
            })
        }
    }, [])

    const redo = useCallback(() => {
        const currentItem = $item.current
        if (!currentItem.next) {
            return
        }
        $item.current = currentItem.next
        if (onChangeRef.current) {
            onChangeRef.current({
                state: $item.current.state,
                type: "redo",
            })
        }
    }, [])

    const reset = useCallback((state?: State) => {
        if (!state) {
            let currentItem = $item.current
            while (currentItem.type !== "initial" && currentItem.previous) {
                currentItem = currentItem.previous
            }
            state = currentItem.state
        }

        $item.current = {
            state: state,
            type: "initial",
            previous: null,
            next: null,
            size: 0
        }

        if (onChangeRef.current) {
            onChangeRef.current({
                state: $item.current.state,
                type: "reset",
            })
        }

    }, [])

    return {
        current: $item.current.state,
        push,
        undo,
        redo,
        reset,
    }

}