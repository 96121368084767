
import {
    Button,
    ButtonProps,
    Icon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverProps,
    PopoverTrigger,
    Radio,
    RadioGroup,
    Stack, StackDivider,
    useDisclosure
} from "@chakra-ui/react"

import { ComponentSettingsIcon } from "@loryth/components/icons"
import { useTimerWidgetChartContext } from "./TimerWidgetChartContext"
import { TimerWidgetPeriodType } from "./useTimerWidgetChart"

export interface TimerWidgetPeriodButtonProps extends ButtonProps {
    placement?: PopoverProps["placement"]
}

export function TimerWidgetPeriodButton({
    placement = "bottom-start",
    ...props
}: TimerWidgetPeriodButtonProps) {
    const { periodType, setPeriodUnit } = useTimerWidgetChartContext()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleOnChange = (nextValue: string) => {
        setPeriodUnit(nextValue as TimerWidgetPeriodType)
        onClose()
    }

    return (
        <Popover isOpen={isOpen} onClose={onClose} placement={placement}>
            <PopoverTrigger>
                <Button
                    rightIcon={<Icon as={ComponentSettingsIcon}/>}
                    onClick={onOpen}
                    size="xs"
                    {...props}
                >
                    {getTitle(periodType)}
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow/>
                <PopoverCloseButton/>
                <PopoverBody>
                    <RadioGroup value={periodType} onChange={handleOnChange}>
                        <Stack divider={<StackDivider/>}>
                            <Radio value="today">Today</Radio>
                            <Radio value="last-week">Last Week</Radio>
                            <Radio value="last-month">Last Month</Radio>
                            <Radio value="last-year">Last Year</Radio>
                            <Radio value="all">All</Radio>
                        </Stack>
                    </RadioGroup>
                </PopoverBody>
            </PopoverContent>
        </Popover>

    )
}

function getTitle(period: TimerWidgetPeriodType): string {
    return {
        "today": "Today",
        "last-week": "Last Week",
        "last-month": "Last Month",
        "last-year": "Last Year",
        "all": "All"
    }[period]
}
