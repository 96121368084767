import { useEffect } from "react";

export function useTick(tick: () => void, delay = 1000) {
    useEffect(() => {
        const interval = setInterval(tick, delay)

        return () => {
            clearInterval(interval)
        }
    }, [delay, tick])
}
