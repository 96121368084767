import { createContext, createElement, ReactNode, useContext } from "react"


export interface CollectionContextType {
    variant: "box" | "list"
}

export const CollectionContext = createContext<CollectionContextType | null>(null)

export function useCollectionContext(): CollectionContextType {
    const context = useContext(CollectionContext)

    if (context === null) {
        throw new Error("CollectionContext is not initialized.")
    }

    return context
}

export interface CollectionContextProviderProps {
    variant: "box" | "list"
    children: ReactNode
}

export function CollectionContextProvider({ variant, children }: CollectionContextProviderProps) {
    return createElement(CollectionContext.Provider, { value: { variant }, children })
}