import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";


export function useNow(seconds = 10): Dayjs {
    const [now, setNow] = useState(dayjs())

    useEffect(() => {
        const handle = setInterval(() => {
            console.log("useNow interval")
            setNow(dayjs())
        }, seconds * 1000)
        return () => clearInterval(handle)
    }, [seconds]);

    return now;
}
