import dayjs from "dayjs";
import { useMemo } from "react";

import { Calendar } from "@loryth/components";

import { HabitHeatmapItem } from "./HabitHeatmapItem";

export function HabitHeatmap() {
    const { start, end } = useMemo(() => {
        const today = dayjs()
        return { start: today.startOf("month").add(-2, "month"), end: today }
    }, [])

    return (
        <Calendar
            start={start}
            end={end}
            renderItem={date => <HabitHeatmapItem date={date}/>}
        />
    )
}