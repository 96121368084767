import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

/**
 * Guidelines:
 * 1. Keep in mind that margins collapse (see:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_box_model/Mastering_margin_collapsing)
 *
 */

const commonStyle = defineStyle({
    lineHeight: 1,

    strong: {
        color: "teal.400",
    },

    a: {
        color: "teal.400",
        display: "flex-inline",
        alignItems: "center",
        "::before": {
            // up-right-from-square icon
            content: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='0.7rem' height='0.7rem' stroke='%2338B2AC' fill='%2338B2AC' stroke-width='0'%3E%3Cpath d='M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z'/%3E%3C/svg%3E\")",
            marginRight: "0.2rem",
        },
        _hover: {
            borderBottom: 1,
            borderBottomColor: "teal.400",
        },
    },

    p: {
        marginY: 2,
    },
    "p:first-child": {
        marginTop: 0,
    },
    "p:last-child": {
        marginBottom: 0,
    },
})

const headerStyle = defineStyle({
    "h1, h2, h3, h4, h5, h6": {
        fontWeight: 700,
        lineHeight: 1,
        marginY: 4,
        marginX: 0,
    },
    "h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child": {
        marginTop: 0,
    },
    "h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child": {
        marginBottom: 0,
    },
    h1: {
        fontSize: "2.5rem",
    },
    h2: {
        fontSize: "2.0rem",
        borderBottomWidth: 1,
    },
    h3: {
        fontSize: "1.5rem",
    },
    h4: {
        fontSize: "1.2rem",
    },
    h5: {
        fontSize: "1.0rem",
    },
    h6: {
        fontSize: "0.9rem",
    },
})

const listStyle = defineStyle({
    "ol, ul": {
        listStyle: "none",

        marginY: 2,
        marginX: 0,
        paddingLeft: 8,
    },

    "ol:first-child, ul:first-child": {
        marginTop: 0,
    },
    "ol:last-child, ul:last-child": {
        marginBottom: 0,
    },

    "ol > li": {
        listStyleType: "decimal",
    },

    "ul > li": {
        listStyleType: "disc",
    },

    "li": {
        marginY: 2,
    },

    "li:first-child": {
        marginTop: 0,
    },

    "li:last-child": {
        marginBottom: 0,
    },
    "li > *:first-child": {
        marginTop: 2,
    },
    "li > *:last-child": {
        marginBottom: 2,
    },
})

const tableStyle = defineStyle({
    table: {
        borderColor: "blackAlpha.400",
        borderCollapse: "separate",
        borderSpacing: 0,
        borderWidth: 1,
        borderRadius: 4,
        width: "100%",
    },

    "table *": {
        borderColor: "inherit",
        borderWidth: 0,
    },
    "table > thead > tr:first-of-type > th:first-of-type": {
        borderTopLeftRadius: 4,
    },
    "table > thead > tr:first-of-type > th:last-of-type": {
        borderTopRightRadius: 4,
    },
    "table > thead > tr > th": {
        borderBottomWidth: 1,
    },
    "table > thead > tr > th:not(:last-of-type)": {
        borderRightWidth: 1,
    },
    "table > tbody > tr:last-of-type > td:first-of-type": {
        borderBottomLeftRadius: 4,
    },
    "table > tbody > tr:last-of-type > td:last-of-type": {
        borderBottomRightRadius: 4,
    },
    "table > tbody > tr:not(:last-of-type) > td": {
        borderBottomWidth: 1,
    },
    "table > tbody > tr > td:not(:last-of-type)": {
        borderRightWidth: 1,
    },
    "table th": {
        fontWeight: 700,
        backgroundColor: "teal.100",
        color: "teal.500",
    },

    "table th, table td": {
        padding: "0.3rem 0.7rem",
    },

    "table tr": {
        borderTopWidth: 1,
    },

    "table tr:nth-of-type(even)": {
        backgroundColor: "gray.200",
    },
})

const codeStyle = defineStyle({
    "pre, code, tt": {
        fontSize: "sm",
        lineHeight: "1",
        fontFamily: "Roboto Mono",
    },

    "code, tt": {
        margin: 0,
        padding: "2px",
        whiteSpace: "nowrap",
        border: 1,
        borderRadius: 2,
        backgroundColor: "gray.100",
    },

    "pre": {
        overflow: "auto",
        maxHeight: 64,

        paddingY: 2,
        paddingX: 4,
        marginY: 2,

        backgroundColor: "gray.100",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "blackAlpha.100",
        borderLeftWidth: 3,
        borderLeftColor: "gray.500",
    },

    "pre:first-child": {
        marginTop: 0,
    },
    "pre:last-child": {
        marginBottom: 0,
    },

    "pre code, pre tt": {
        backgroundColor: "transparent",
        border: 0,
        margin: 0,
        padding: 0,
        whiteSpace: "pre",
    },
})


const quoteStyle = defineStyle({
    blockquote: {
        overflow: "auto",
        maxHeight: 64,

        paddingX: 4,
        paddingY: 2,
        marginY: 2,

        fontSize: "sm",

        backgroundColor: "gray.100",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "blackAlpha.100",
        borderLeftWidth: 3,
        borderLeftColor: "gray.500",
    },

    "blockquote:first-child": {
        marginTop: 0,
    },
    "blockquote:last-child": {
        marginBottom: 0,
    },
})


export const MarkdownTextStyle = defineStyleConfig({
    baseStyle: {
        ...commonStyle,
        ...headerStyle,
        ...tableStyle,
        ...codeStyle,
        ...quoteStyle,
        ...listStyle,

        // TODO(souperk): Document styles for the dd element
        "dd": {
            margin: "0 0 0 2rem",
        },
    },
    variants: {
        card: {
            borderWidth: 1,
            borderRadius: 4,
            px: 4,
            py: 2,
            backgroundColor: "white",
            borderColor: "blackAlpha.200",
        }
    }
})
