import { ReactNode } from "react";

import { Box, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";

import { Task, TaskApi } from "@loryth/api";
import { ConfirmationModal, FormDialog } from "@loryth/components";
import {
    DeleteIcon,
    EditIcon,
    TaskClosedIcon,
    TaskCompletedIcon,
    TaskInProgressIcon,
    TaskOpenIcon
} from "@loryth/components/icons";

import { TaskForm } from "@loryth/features/task-form";


export interface TaskActionsProps {
    task: Task

    children: ReactNode
}

export function TaskActions({ task, children }: TaskActionsProps) {
    const [upsertTask] = TaskApi.useUpsertMutation()
    const [deleteTask] = TaskApi.useDeleteMutation()

    const updateStatus = async (status: Task["status"]) => {
        await upsertTask({
            taskId: task.id,
            data: {
                project_id: task.project_id,
                type_id: task.type_id,
                title: task.title,
                description: task.description,
                status: status,
            }
        })
    }
    const handleTaskDelete = async () => {
        await deleteTask(task.id)
    }

    const canStart = task.status === "open"
    const canOpen = task.status === "completed" || task.status === "closed"
    const canStop = task.status === "in-progress"
    const canComplete = task.status === "open" || task.status === "in-progress"
    const canClose = task.status === "open" || task.status === "in-progress"

    return (
        <Menu>
            <MenuButton>
                {children}
            </MenuButton>
            <MenuList>
                <FormDialog
                    title="Edit Task"
                    size="large"
                    form={<TaskForm task={task}/>}
                    trigger={
                        <MenuItem icon={<EditIcon/>} color="teal">
                            Edit
                        </MenuItem>
                    }
                />

                {canStart && (
                    <MenuItem icon={<TaskInProgressIcon/>} onClick={() => updateStatus("in-progress")}>
                        Start
                    </MenuItem>
                )}
                {canStop && (
                    <MenuItem icon={<TaskOpenIcon/>} onClick={() => updateStatus("open")}>
                        Open
                    </MenuItem>
                )}
                {canOpen && (
                    <MenuItem icon={<TaskOpenIcon/>} onClick={() => updateStatus("open")}>
                        Open
                    </MenuItem>
                )}
                {canComplete && (
                    <MenuItem icon={<TaskCompletedIcon/>} onClick={() => updateStatus("completed")}>
                        Complete
                    </MenuItem>
                )}
                {canClose && (
                    <MenuItem icon={<TaskClosedIcon/>} onClick={() => updateStatus("closed")}>
                        Close
                    </MenuItem>
                )}

                <ConfirmationModal
                    title="Delete Task"
                    message={
                        <Box>
                            <Text>
                                Are you sure you want to delete <Text as="strong">{task.title}</Text>?
                            </Text>
                        </Box>
                    }
                    onConfirm={handleTaskDelete}
                    trigger={
                        <MenuItem icon={<DeleteIcon/>} color="red">
                            Delete
                        </MenuItem>
                    }
                />
            </MenuList>

        </Menu>
    )
}