import { DependencyList, useEffect, useRef, useState } from "react";


export function useWatch(callback: () => void, deps: DependencyList) {
    const callbackRef = useRef<() => void>(callback)
    useEffect(() => {
        callbackRef.current = callback
    }, [callback])

    const [state, setState] = useState<DependencyList>(deps)
    useEffect(() => {
        if (hasChanged(deps, state)) {
            callbackRef.current()
            setState(deps)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, ...deps]);
}

function hasChanged(value: DependencyList, other: DependencyList) {
    if (value.length !== other.length) {
        return true
    }
    for (let i = 0; i < value.length; i++) {
        if (value[i] !== other[i]) {
            return true
        }
    }
    return false
}