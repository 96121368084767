import { Button, Icon, Input, InputGroup, InputLeftElement, Spinner } from "@chakra-ui/react";

import { ProjectApi } from "@loryth/api";
import { FormDialog, Page, Section, SectionBody, SectionTitle } from "@loryth/components";
import { ErrorIcon, NewIcon, SearchIcon } from "@loryth/components/icons";

import { ProjectForm } from "@loryth/features/project-form";

import { ProjectPreview } from "../project/ProjectPreview";


export function ProjectListPage() {
    const { isFetching, isError, isSuccess, data: projects } = ProjectApi.useSearchQuery({})

    return (
        <Page>
            <Section>
                <SectionTitle>Projects</SectionTitle>

                <SectionBody variant="toolbar">
                    <FormDialog
                        title="Create Project"
                        form={<ProjectForm/>}
                        trigger={
                            <Button
                                variant="solid"
                                colorScheme="teal"
                                leftIcon={
                                    isFetching ? <Spinner size="sm"/> : <Icon as={NewIcon}/>
                                }
                                isDisabled={isFetching || isError}
                                flex="1 0 auto"
                            >
                                New
                            </Button>
                        }
                    />
                    <InputGroup>
                        <InputLeftElement>
                            {isFetching && <Spinner size="sm"/>}
                            {!isFetching && isError && <Icon as={ErrorIcon}/>}
                            {!isFetching && isSuccess && <Icon as={SearchIcon}/>}
                        </InputLeftElement>
                        <Input placeholder="Search..."/>
                    </InputGroup>
                </SectionBody>

                <SectionBody variant="elevated">
                    {isSuccess && projects.results.map(project => (
                        <ProjectPreview key={project.id} project={project}/>
                    ))}
                </SectionBody>
            </Section>
        </Page>
    )
}
