import { TaskApi } from "@loryth/api";
import { formatApiError } from "@loryth/commons/format";
import { LoadingPage, Page } from "@loryth/components";

import { TaskPage } from "./TaskPage";

export interface TaskRouteProps {
    params: {
        taskId: string
    }
}

export function TaskRoute({ params: { taskId } }: TaskRouteProps) {
    const { isUninitialized, isLoading, isError, error, data: task } = TaskApi.useGetQuery(taskId)

    if (isUninitialized || isLoading) {
        return (
            <LoadingPage/>
        )
    }

    if (isError) {
        return (
            <Page title="Not Found - Loryth">
                {formatApiError(error)}
            </Page>
        )
    }

    return <TaskPage task={task}/>
}

