import { Center, Spinner } from "@chakra-ui/react";

import { ProjectApi } from "@loryth/api";
import { NavigationGroup, NavigationItem } from "@loryth/components";
import { BacklogIcon, findIconByName, SettingsIcon } from "@loryth/components/icons";

export interface ProjectNavigationProps {
    params: {
        projectId: string
        tab?: "backlog" | "settings"
    }
}

export function ProjectNavigation({ params: { projectId, tab } }: ProjectNavigationProps) {
    const { isUninitialized, isLoading, isError, data: project } = ProjectApi.useGetQuery(projectId)
    if (isUninitialized || isLoading) {
        return (
            <Center>
                <Spinner/>
            </Center>
        )
    }
    if (isError) {
        return <>Oops, somethings went wrong.</>
    }

    return (
        <NavigationGroup title={project.name} icon={findIconByName(project.icon ?? "project")}>
            <NavigationItem
                title="Backlog"
                icon={BacklogIcon}
                link={`/project/${projectId}/backlog`}
                isActive={tab === "backlog"}
            />
            <NavigationItem
                title="Settings"
                icon={SettingsIcon}
                link={`/project/${projectId}/settings`}
                isActive={tab === "settings"}
            />
        </NavigationGroup>
    )
}