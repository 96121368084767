import { Box, BoxProps } from "@chakra-ui/react";


export interface ShellBodyProps extends Omit<BoxProps, "gridArea"> {

}

export function ShellBody(props: ShellBodyProps) {
    return (
        <Box
            className="loryth-shell__body"
            gridArea="body"
            width="100%"
            overflow="auto"
            {...props}
        />
    )
}