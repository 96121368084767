import { useCallback, useContext } from "react";

import { CommandContext } from "./CommandContext";
import { CommandKey, CommandRegistry, CommandTrigger } from "./CommandRegistry";

export function useCommandTrigger<Payload>(key: CommandKey<Payload>, registry?: CommandRegistry): CommandTrigger<Payload> {
    const contextRegistry = useContext(CommandContext)
    const activeRegistry = registry || contextRegistry

    return useCallback((payload: Payload) => activeRegistry.trigger(key, payload), [activeRegistry, key]) as CommandTrigger<Payload>
}