import { lorythApi } from "./_api";
import { Project } from "./project";

export interface SearchRequest {

}

export interface ListingResponse {
    results: Project[]
    page_size: number
    page_number: number
    total: number
}

export interface UpsertRequest {
    projectId?: string
    data: {
        name: string
        description: string
        icon?: string | null
        color?: string | null
    }
}


const projectApi = lorythApi.enhanceEndpoints({
    addTagTypes: ["project"],
}).injectEndpoints({
    endpoints: builder => ({
        projectSearch: builder.query<ListingResponse, SearchRequest>({
            query: () => `/api/v1/project`,
            providesTags: response =>
                response ? [...response.results.map(project => ({
                        type: "project" as const,
                        id: project.id
                    })), "project"]
                    : [],
        }),
        projectGet: builder.query<Project, string>({
            query: projectId => `/api/v1/project/${projectId}`,
            providesTags: response =>
                response ? [{ type: "project", id: response.id }, "project"]
                    : [],
        }),
        projectUpsert: builder.mutation<Project, UpsertRequest>({
            query: (request) => ({
                url: !request.projectId
                    ? `/api/v1/project`
                    : `/api/v1/project/${request.projectId}`,
                method: !request.projectId ? "post" : "put",
                body: request.data,
            }),
            invalidatesTags: response =>
                response ? [{ type: "project", id: response.id }, "project"]
                    : [],
        }),
        projectDelete: builder.mutation<void, string>({
            query: (projectId) => ({
                url: `/api/v1/project/${projectId}`,
                method: "delete",
            }),
            invalidatesTags: (response, _, request) =>
                response ? [{ type: "project", id: request }, "project"]
                    : [],
        }),
    })
})

export const ProjectApi = {
    _internal: projectApi,
    useSearchQuery: projectApi.useProjectSearchQuery,
    useGetQuery: projectApi.useProjectGetQuery,
    useUpsertMutation: projectApi.useProjectUpsertMutation,
    useDeleteMutation: projectApi.useProjectDeleteMutation,
}