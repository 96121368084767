import { findIconByName } from "@loryth/components/icons"
import { useContext } from "react"

import { HTMLChakraProps } from "@chakra-ui/system";
import { Box, Image, forwardRef, useMultiStyleConfig, Icon } from "@chakra-ui/react";

import { FilesystemApi, FilesystemNode } from "@loryth/api";
import { CollectionContext } from "@loryth/components";

import { nodeIsImage } from "./utils";


export interface FilesystemNodePreviewProps extends HTMLChakraProps<"div"> {
    variant?: "box" | "list"
    node: FilesystemNode

    isActive?: boolean
}

/**
 *
 */
export const FilesystemNodePreview = forwardRef<FilesystemNodePreviewProps, 'div'>(({
    variant,
    node,
    isActive = false,
    children,
    ...props
}: FilesystemNodePreviewProps, ref) => {

    const context = useContext(CollectionContext)
    if (context) {
        if (variant) {
            console.warn(`FilesystemNodePreview: Overriding variant with collection context.`)
        }
        variant = context.variant
    }

    const styles = useMultiStyleConfig("FilesystemNodePreview", {
        variant,
        isActive,
        colorScheme: node.color ?? undefined
    })


    return (
        <Box
            ref={ref}
            className="loryth-filesystem-preview"
            __css={styles.root}
            title={node.name}
            {...props}
        >
            {!nodeIsImage(node) && (
                <Icon
                    as={findIconByName(node.icon ?? node.type)}
                    __css={styles.icon}
                    color={
                        `${node.color ?? "teal"}.400`
                    }
                />
            )}
            {nodeIsImage(node) && (
                <Image
                    src={FilesystemApi.url(node.id)}
                    objectFit="contain"
                    maxHeight="100%" maxWidth="100%"
                    borderWidth={1}
                    borderStyle="solid"
                    borderColor="blackAlpha.200"
                    borderRadius={4}
                    __css={styles.image}
                />
            )}
            <Box as="span" __css={styles.name}>
                {node.name}
            </Box>
            {children}
        </Box>
    )
})

