import { Box, Icon, IconButton, Text } from "@chakra-ui/react";

import { FilesystemNode } from "@loryth/api";
import { FormDialog, IconBox } from "@loryth/components";
import { FolderIcon } from "@loryth/components/icons";
import { FilesystemExplorer } from "./FilesystemExplorer";


export interface FilesystemPickerProps {
    value: FilesystemNode | null
    onChange: (value: FilesystemNode | null) => void
    allowedTypes?: string[]
}

export function FilesystemPicker({ value, onChange, allowedTypes }: FilesystemPickerProps) {

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                border: 1,
                borderStyle: "solid",
                borderRadius: 4,
                borderColor: "rgba(0, 0, 0, 0.12)",
            }}
        >
            <IconBox
                variant="ghost"
                icon={value?.icon ?? value?.type ?? "folder"}
                colorScheme={value?.color ?? null}
            />

            <Text as="span" fontSize="lg">
                {value?.name || "Files"}
            </Text>

            <FormDialog
                title="Select location"
                trigger={
                    <IconButton
                        variant="ghost"
                        aria-label="open explorer"
                        icon={<Icon as={FolderIcon}/>}
                        ml="auto"
                    />
                }
                form={
                    <FilesystemExplorer
                        allowedTypes={allowedTypes}
                        initialLocation={value}
                        selection={value}
                        onSelectionChange={onChange}
                    />
                }
            />
        </Box>
    )
}