import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";

import { TaskStatus } from "@loryth/api/loryth/task";

import { getStatusColor } from "../task/getStatusColor";
import { getStatusIcon } from "../task/getStatusIcon";


export interface TaskStatusTagProps {
    status: TaskStatus
}

export function TaskStatusTag({ status }: TaskStatusTagProps) {
    const color = getStatusColor(status)
    const icon = getStatusIcon(status)
    return (
        <Tag colorScheme={color}>
            {icon && (<TagLeftIcon as={icon}/>)}
            <TagLabel>
                {status}
            </TagLabel>
        </Tag>
    )
}

