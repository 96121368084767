import { ReactNode } from "react";

import { Box, Heading, HeadingProps } from "@chakra-ui/react";

export interface SectionTitleProps extends HeadingProps {
    icon?: ReactNode
}

export function SectionTitle(props: SectionTitleProps) {
    const { icon, size = "lg", children, ...rest } = props

    return (
        <Heading
            className="loryth-section__title"
            as="h2"
            size={size}
            borderBottomWidth={1} borderBottomColor="blackAlpha.100"
            display="flex" flexDirection="row" gap={2} alignItems="flex-start"
            {...rest}
        >
            {icon && (
                <Box
                    className="loryth-section__title-icon"
                >
                    {icon}
                </Box>
            )}
            <Box>
                {children}
            </Box>
        </Heading>
    )
}

