import { useFormContext } from "@loryth/components/forms/FormContext";
import { useState } from "react";

import { Input, Stack } from "@chakra-ui/react";

import { Project, ProjectApi } from "@loryth/api";
import { useCommand } from "@loryth/commons/command";
import { AlertApiError, FormSubmitCommand, IconInput, MarkdownEditor } from "@loryth/components";

export interface ProjectFormProps {
    project?: Project
}

export function ProjectForm({ project }: ProjectFormProps) {
    const [name, setName] = useState(project?.name)
    const [color, setColor] = useState(project?.color || "teal")
    const [icon, setIcon] = useState(project?.icon || null)
    const [description, setDescription] = useState(project?.description)

    const [projectUpsert, { isError, error }] = ProjectApi.useUpsertMutation()

    const { onSubmitSuccess, onSubmitError } = useFormContext()

    useCommand(FormSubmitCommand, async () => {
        try {
            await projectUpsert({
                projectId: project?.id,
                data: {
                    name: name ?? "",
                    description: description ?? "",
                    color,
                    icon,
                },
            }).unwrap()
            onSubmitSuccess()
        } catch (error) {
            onSubmitError()
        }
    })

    return (
        <Stack minHeight={480}>
            <Stack direction="row" gap={2}>
                <IconInput
                    icon={icon}
                    onIconChange={value => setIcon(value)}
                    useColor
                    color={color}
                    onColorChange={value => setColor(value ?? "teal")}
                />
                <Input placeholder="name" value={name} onChange={e => setName(e.target.value)}/>
            </Stack>
            <MarkdownEditor
                defaultValue={description ?? ""}
                onChange={value => setDescription(value)}
            />
            {isError && (
                <AlertApiError error={error}/>
            )}
        </Stack>
    )
}
