import { Box, BoxProps, Heading, Icon, IconButton, Text } from "@chakra-ui/react";
import { Journal } from "@loryth/api";
import { IconBox, Link } from "@loryth/components";
import { OptionsIcon } from "@loryth/components/icons";


export interface JournalPreviewProps extends BoxProps {
    journal: Journal
}

export function JournalPreview({ journal, ...props }: JournalPreviewProps) {
    return (
        <Box
            display="flex" flexDirection="row"
            alignItems="center"
            gap={4}
            {...props}
        >
            <IconBox
                icon={journal.icon} colorScheme={journal.color}
                width={10} height={10}
                sx={{ "svg": { fontSize: "2xl" } }}
            />
            <Box>
                <Link to={`/journal/${journal.id}`}>
                    <Heading size="md">
                        {journal.title}
                    </Heading>
                </Link>
                <Text>
                    {journal.description}
                </Text>
            </Box>
            <Box ml="auto">
                <IconButton
                    variant="ghost"
                    aria-label="options"
                    icon={<Icon as={OptionsIcon}/>}
                />
            </Box>
        </Box>
    )
}