import { chakra } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"

export function WidgetTitle(props: HTMLChakraProps<"span">) {
    return (
        <chakra.span
            className="loryth-widget__title"
            flex="1"
            fontSize="lg"
            fontWeight="bold"
            {...props}
        />
    )
}