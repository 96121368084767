export type MetadataKey<Payload> = symbol & {
    key: symbol
    get(metadata: Record<string, unknown>): Payload
    set(metadata: Record<string, unknown>, value: Payload extends undefined ? never : Payload): void
    remove(metadata: Record<string, unknown>): void
}


export function createMetadataKey<Payload>(key: string): MetadataKey<Payload | undefined>
export function createMetadataKey<Payload>(key: string, defaultValue: Payload): MetadataKey<Payload>
export function createMetadataKey<Payload>(key: string, defaultValue?: Payload) {
    return {
        key: Symbol(key),
        get(metadata: Record<string, unknown>) {
            if (!(key in metadata)) {
                return defaultValue
            }
            return metadata[key] as Payload
        },
        set(metadata: Record<string, unknown>, value: Payload extends undefined ? never : Payload) {
            metadata[key] = value
        },
        remove(metadata: Record<string, unknown>) {
            delete metadata[key]
        },
    }
}