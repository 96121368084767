import { Habit } from "@loryth/api";


export interface HabitPickerProps {
    value?: Habit | null
    onChange?: (habit: Habit) => void

    placeholder?: string
}

export function HabitPicker() {
    // const { isLoading, isSuccess, data } = useHabitSearchQuery({})
    // const options = [
    //     { id: "1", title: "Running" },
    //     { id: "2", title: "Walking" },
    //     { id: "3", title: "Cooking" },
    // ]
    return null
    // return (
    //     <Select>
    //         <Button
    //             variant="ghost" colorScheme="teal"
    //             leftIcon={<Icon as={FaPlus}/>}
    //         >
    //             Add habit
    //         </Button>
    //         {options.map(option => (
    //             <SelectOption key={option.id} title={option.title} value={option}/>
    //         ))}
    //     </Select>
    // )
}