import { AccountGroup, AccountGroupMembership } from "./account-group";
import { lorythApi } from "./_api";


interface AccountGroupUpsertRequest {
    accountGroupId: string
    payload: {
        name: string
    }
}

interface AccountGroupMembershipResponse {
    results: AccountGroupMembership[]
}

interface AccountGroupMembershipUpsertRequest {
    accountGroupId: string
    accountId: string
}

const accountGroupApi = lorythApi.enhanceEndpoints({
    addTagTypes: ["account-group"],
}).injectEndpoints({
        endpoints: builder => ({
            accountGroupGet: builder.query<AccountGroup, string>({
                query: accountGroupId => ({
                    url: `/api/v1/account-group/${accountGroupId}`,
                }),
                providesTags: ["account-group"],
            }),
            accountGroupUpsert: builder.mutation<AccountGroup, AccountGroupUpsertRequest>({
                query: request => ({
                    url: `/api/v1/account-group/${request.accountGroupId}`,
                    method: "post",
                    body: request.payload,
                }),
                invalidatesTags: ["account-group"],
            }),
            accountGroupDelete: builder.mutation<void, string>({
                query: accountGroupId => ({
                    url: `/api/v1/account-group/${accountGroupId}`,
                    method: "delete",
                }),
                invalidatesTags: ["account-group"],
            }),
            accountGroupGetMemberships: builder.query<AccountGroupMembershipResponse, string>({
                query: accountGroupId => ({
                    url: `/api/v1/account-group/${accountGroupId}/memberships`,
                    method: "get",
                }),
                providesTags: ["account-group"],
            }),
            accountGroupGetMembership: builder.query<AccountGroupMembership, AccountGroupMembershipUpsertRequest>({
                query: request => ({
                    url: `/api/v1/account-group/${request.accountGroupId}/memberships/${request.accountId}`,
                    method: "get",
                }),
                providesTags: ["account-group"],
            }),
            accountGroupUpsertMembership: builder.mutation<AccountGroupMembership, AccountGroupMembershipUpsertRequest>({
                query: request => ({
                    url: `/api/v1/account-group/${request.accountGroupId}/memberships/${request.accountId}`,
                    method: "post",
                }),
                invalidatesTags: ["account-group"],
            }),
            accountGroupDeleteMembership: builder.mutation<void, AccountGroupMembershipUpsertRequest>({
                query: request => ({
                    url: `/api/v1/account-group/${request.accountGroupId}/memberships/${request.accountId}`,
                    method: "delete",
                }),
                invalidatesTags: ["account-group"],
            }),
        })
    }
)


export const AccountGroupApi = {
    _internal: accountGroupApi,
    useGetQuery: accountGroupApi.useAccountGroupGetQuery,
    useUpsertMutation: accountGroupApi.useAccountGroupUpsertMutation,
    useDeleteMutation: accountGroupApi.useAccountGroupDeleteMutation,
    useGetMembershipsQuery: accountGroupApi.useAccountGroupGetMembershipsQuery,
    useGetMembershipQuery: accountGroupApi.useAccountGroupGetMembershipQuery,
    useUpsertMembershipMutation: accountGroupApi.useAccountGroupUpsertMembershipMutation,
    useDeleteMembershipMutation: accountGroupApi.useAccountGroupDeleteMembershipMutation,
}