import MarkdownIt from "markdown-it";
import Token from "markdown-it/lib/token";

import { makeAst } from "./makeAst";
import { isNode, isToken } from "./MarkdownItAstNode";
import { walkAst } from "./walkAst";


interface RenderMarkdownOptions {
    crop?: boolean
}

export function renderMarkdown(raw: string, { crop = false }: RenderMarkdownOptions = {}) {
    // TODO(souperk): Add support for plugins.
    // TODO(souperk): understand if it's ok to re-render
    const md = new MarkdownIt();

    // TODO(souperk): Extract crop to some kind of transformation.
    if (crop) {
        const env = {};
        const tokens = md.parse(raw, env);

        const ast = makeAst(tokens);

        const selected: Token[] = [];
        walkAst(ast, {
                before: node => {
                    if (isToken(node)) {
                        selected.push(node);
                    }

                    if (isNode(node)) {
                        selected.push(node.openNode);
                    }
                },
                after: node => {
                    if (isNode(node)) {
                        selected.push(node.closeNode);
                    }
                },
                skip: (node) => {
                    if (isToken(node)) {
                        return true;
                    }

                    if (isNode(ast[0]) && ast[0].nodeType === "heading") {
                        if (isNode(ast[1]) && ast[1].nodeType === "paragraph" && node === ast[1]) {
                            return true;
                        }
                        if (node.nodeType === "heading") {
                            return node.openNode.tag === ast[0].openNode.tag
                                && ast.filter(x => isNode(x) && x.nodeType === "heading").indexOf(node) <= 6;
                        }
                    }
                    if (isNode(ast[0]) && (ast[0].nodeType === "ordered_list" || ast[0].nodeType === "bullet_list")) {
                        if (node.nodeType === "ordered_list" || node.nodeType === "bullet_list") {
                            return node.openNode.level === 0 && ast.indexOf(node) <= 10;
                        }
                    }

                    const index = ast.indexOf(node);
                    return index === -1 || index === 0;
                },
            }
        )

        return md.renderer.render(selected, {}, env);
    }

    return md.render(raw);

}