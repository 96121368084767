import { createContext, ReactNode } from "react";

import { CommandRegistry } from "./CommandRegistry";


export const GlobalCommandRegistry = new CommandRegistry(null)
export const CommandContext = createContext<CommandRegistry>(GlobalCommandRegistry)

export function GlobalCommandContextProvider({ children }: { children: ReactNode }) {
    return (
        <CommandContext.Provider value={GlobalCommandRegistry}>
            {children}
        </CommandContext.Provider>
    )
}
