import { Text, TextProps, Tooltip } from "@chakra-ui/react"
import dayjs, { Dayjs } from "dayjs";

import { formatDateAndTime, formatDuration, FormatDurationOptions } from "@loryth/commons/format";
import { parseApiTimestamp } from "../../commons/time";

import { useNow } from "./useNow";


export interface TimeElapsedProps extends TextProps {
    timestamp: Date | Dayjs | string
    reference?: Dayjs
    options?: FormatDurationOptions
}

export function TimeElapsed({ timestamp, reference, options = {}, ...props }: TimeElapsedProps) {

    const now = useNow(1)
    if (!reference) {
        reference = now
    }

    if (typeof timestamp === "string") {
        timestamp = dayjs(parseApiTimestamp(timestamp))
    } else if (timestamp instanceof Date) {
        timestamp = dayjs(timestamp)
    }

    return (
        <Tooltip hasArrow label={formatDateAndTime(timestamp)}>
            <Text as="span" {...props}>
                {formatDuration(reference.diff(timestamp).valueOf() / 1000, { format: "short", ...options })} ago
            </Text>
        </Tooltip>
    )
}
