import { createContext, useContext } from "react";


export interface FormContext {
    onSubmit: () => void
    onSubmitError: () => void
    onSubmitSuccess: () => void
}

const context = createContext<FormContext | null>(null)


export function useFormContext(): FormContext {
    const context_ = useContext(context)
    if (!context_) {
        throw new Error("FormContext is not initialized.")
    }
    return context_
}

export const FormContextProvider = context.Provider

