import { Box, createStylesContext, useMultiStyleConfig } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"
import { TimerWidgetChartProvider } from "./TimerWidgetChartContext"
import { useTimerWidgetChart } from "./useTimerWidgetChart"

export const [TimerWidgetChartStylesProvider, useTimerWidgetChartStyles] = createStylesContext("TimerWidgetChart")


export interface TimerWidgetChartRootProps extends HTMLChakraProps<"div"> {

}

export function TimerWidgetChartRoot(props: TimerWidgetChartRootProps) {
    const context = useTimerWidgetChart()
    const styles = useMultiStyleConfig("TimerWidgetChart", {})
    return (
        <TimerWidgetChartProvider value={context}>
            <TimerWidgetChartStylesProvider value={styles}>
                <Box __css={styles.root} {...props}/>
            </TimerWidgetChartStylesProvider>
        </TimerWidgetChartProvider>
    )
}