import { chakra, Icon } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system";
import { CollapseIcon, ExpandIcon } from "../../icons";
import { useCollapsibleContext, useCollapsibleStyles } from "./context";


export interface CollapsibleHeaderProps extends HTMLChakraProps<"button"> {

}


export function CollapsibleHeader({ children, ...props }: CollapsibleHeaderProps) {
    const { isOpen, onToggle } = useCollapsibleContext()
    const styles = useCollapsibleStyles()
    return (
        <chakra.button
            className="loryth-collapsible__header"
            __css={styles.header}
            onClick={onToggle}
            {...props}
        >
            {children}
            <Icon as={isOpen ? CollapseIcon : ExpandIcon}/>
        </chakra.button>
    )
}