import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    "root", "controls", "legend", "chart"
])

export const TimerWidgetChartStyle = defineMultiStyleConfig({
    baseStyle: {
        root: {

            overflow: "hidden",
            maxWidth: "100%",

            display: "grid",
            gridTemplateAreas: {
                base: `
                    "controls"
                    "legend"
                    "chart"
                `,
                lg: `
                    "controls controls"
                    "chart legend"
                `,
            },
            gridTemplateColumns: {
                base: "100%",
                lg: "4fr 1fr",
            },
            rowGap: 2,
            columnGap: 4,
        },
        controls: {
            gridArea: "controls",
            display: "flex",
            flexDirection: "row",
            gap: 1,
        },
        legend: {
            gridArea: "legend",
        },
        chart: {
            gridArea: "chart",
            height: 320,
            backgroundColor: "white",
            padding: 4,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: "blackAlpha.200",
        },
    },
})