import { ReactNode } from "react";

import { PopoverTrigger } from "@chakra-ui/react";


export interface SelectTriggerProps {
    children: ReactNode;
}

export function SelectTrigger({ children }: SelectTriggerProps) {
    return (
        <PopoverTrigger>
            {children}
        </PopoverTrigger>
    )
}