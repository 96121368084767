import { chakra } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"
import { useWidgetContext } from "./context"


export interface WidgetContentProps extends HTMLChakraProps<"div"> {

}

export function WidgetContent(props: WidgetContentProps) {
    const { isOpen } = useWidgetContext()

    if (!isOpen) {
        return null
    }

    return (
        <chakra.div
            className="loryth-widget__content"
            {...props}
        />
    )
}

