import { Icon, IconButton, IconButtonProps, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { FilesystemApi, FilesystemNode } from "@loryth/api";
import { ConfirmationModal, FormDialog } from "@loryth/components";
import { DeleteIcon, EditIcon, MoveIcon, OptionsIcon } from "@loryth/components/icons";
import { FilesystemMoveForm } from "./FilesystemMoveForm";
import { FilesystemRenameForm } from "./FilesystemRenameForm";

export interface FilesystemNodeActionsProps extends Omit<IconButtonProps, "aria-label"> {
    ["aria-label"]?: string
    node: FilesystemNode
}

export function FilesystemNodeActions({ node, ...props }: FilesystemNodeActionsProps) {

    const [deleteNode] = FilesystemApi.useDeleteNodeMutation()

    const handleOnDelete = async () => {
        try {
            await deleteNode(node.id).unwrap()
        } catch (error) {
            console.error("failed to delete filesystem node", error)
        }
    }

    return (
        <Menu direction="rtl">
            <MenuButton
                as={IconButton}
                aria-label="options"
                variant="ghost"
                icon={<Icon as={OptionsIcon}/>}
                {...props}
            />
            <MenuList color="black">
                <FormDialog
                    title="Rename"
                    trigger={
                        <MenuItem icon={<Icon as={EditIcon}/>}>
                            Rename
                        </MenuItem>
                    }
                    form={
                        <FilesystemRenameForm node={node}/>
                    }
                />

                <FormDialog
                    title="Move"
                    trigger={
                        <MenuItem icon={<Icon as={MoveIcon}/>}>
                            Move
                        </MenuItem>
                    }
                    form={
                        <FilesystemMoveForm node={node}/>
                    }
                />

                <ConfirmationModal
                    title="Delete file"
                    message="Are you sure you want to delete file?"
                    trigger={
                        <MenuItem icon={<Icon as={DeleteIcon}/>}>
                            Delete
                        </MenuItem>
                    }
                    onConfirm={handleOnDelete}
                />
            </MenuList>
        </Menu>
    )
}