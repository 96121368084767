export function parseApiTimestamp(value: string): Date;
export function parseApiTimestamp(value: null): null;
export function parseApiTimestamp(value: string | null): Date | null;
export function parseApiTimestamp(value: string | null): Date | null {
    if (value === null) {
        return null
    }

    // loryth timestamps are in utc but the timezone information is not serialized,
    //  which makes js Date parse the timestamp like it's in the client timezone.
    //  Adding a Z at the end of the timestamp defines the utc timezone.
    return new Date(value + "Z")
}