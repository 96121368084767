import { BaseQueryApi, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { createApi, FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

function matchEndpoints(args: string | FetchArgs, endpoints: string[]): boolean {
    if (typeof args === "string") {
        return endpoints.includes(args)
    }
    return endpoints.includes(args.url)
}

export type MaybePromise<T> = T | PromiseLike<T>

export type LorythQueryReturnValue =
    | {
    error: FetchBaseQueryError
    data?: undefined
    meta?: Record<string, unknown>
}
    | {
    error?: undefined
    data: unknown
    meta?: Record<string, unknown>
}


export type LorythQueryFn = (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: Record<string, unknown>
) => MaybePromise<LorythQueryReturnValue>


export type LorythQueryMiddlewareFn = (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: Record<string, unknown>,
    next: LorythQueryFn
) => MaybePromise<LorythQueryReturnValue>

export type LorythQueryMiddlewareHandle = number

class LorythQuery {

    private _middleware: LorythQueryMiddlewareFn[] = []
    private readonly _query: LorythQueryFn
    private _pipeline: LorythQueryFn

    constructor(
        query?: LorythQueryFn) {
        if (!query) {
            query = fetchBaseQuery({ baseUrl: '/' })
        }
        this._query = query
        this._pipeline = query
    }

    addMiddleware(middleware: LorythQueryMiddlewareFn): LorythQueryMiddlewareHandle {
        this._middleware.push(middleware)

        const pipeline = this._pipeline
        this._pipeline = (args: string | FetchArgs, api: BaseQueryApi, extraOptions: Record<string, unknown>): MaybePromise<LorythQueryReturnValue> => {
            return middleware(args, api, extraOptions, pipeline)
        }

        return this._middleware.length - 1
    }

    removeMiddleware(handle: LorythQueryMiddlewareHandle): void {
        this._middleware.splice(handle, 1)

        this._pipeline = this._query

        for (const middleware of this._middleware) {
            const pipeline = this._pipeline
            this._pipeline = (args: string | FetchArgs, api: BaseQueryApi, extraOptions: Record<string, unknown>): MaybePromise<LorythQueryReturnValue> => {
                return middleware(args, api, extraOptions, pipeline)
            }
        }
    }

    get fetch(): LorythQueryFn {
        return (args, api, extraOptions) => this._pipeline(args, api, extraOptions)
    }

}


const AnonymousEndpoints = [
    "/api/v1/account/sign-in",
    "/api/v1/account/sign-up",
    "/api/v1/account/password-recovery",
]

function authenticationMiddleware(): LorythQueryMiddlewareFn {
    let isAuthenticated = false
    return async (args, api, extraOptions, next) => {
        const result = await next(args, api, extraOptions)
        if (result.data && !matchEndpoints(args, AnonymousEndpoints)) {
            isAuthenticated = true
        }
        if (result.error && result.error.status === 401 && isAuthenticated) {
            isAuthenticated = false
            // Invalidate me so auth guard can redirect to login...
            // api.dispatch(lorythApi.util.invalidateTags([{ type: "me" }]))
            api.dispatch(lorythApi.util.resetApiState())
        }
        return result
    }
}

const baseQuery = new LorythQuery()
baseQuery.addMiddleware(authenticationMiddleware())

export const lorythApi = createApi({
    reducerPath: "api",
    baseQuery: baseQuery.fetch,
    tagTypes: ["scope", "me"],
    endpoints: () => ({}),
})


