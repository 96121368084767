import { memo } from "react";

import {
    Card,
    CardBody,
    CardHeader, Divider,
    Grid,
    GridItem,
    Heading,
    Stack,
    Tag,
    TagLabel,
    TagLeftIcon
} from "@chakra-ui/react";

import { Task } from "@loryth/api";
import { MarkdownText, TimeElapsed, Link } from "@loryth/components";
import { OptionsIcon, TimeIcon } from "@loryth/components/icons";

import { TaskActions } from "./TaskActions";
import { TaskStatusTag } from "./TaskStatusTag";
import { TaskTypeTag } from "./TaskTypeTag";


export interface TaskPreviewProps {
    task: Task
    viewMode?: "list" | "cards"
}

export const TaskPreview = memo(({ task, viewMode = "list" }: TaskPreviewProps) => {
    if (viewMode === "list") {
        return <TaskListPreview task={task}/>
    }
    if (viewMode === "cards") {
        return <TaskCardPreview task={task}/>
    }
})

export function TaskListPreview({ task }: Omit<TaskPreviewProps, "viewMode">) {
    return (
        <Grid
            gridTemplateAreas={`
                "title  actions"
                "details details"
            `}
            gridTemplateColumns="1fr auto"
            rowGap={2}
        >
            <GridItem gridArea="title">
                <Heading size="md">
                    <Link to={`/task/${task.id}`}>
                        {task.title}
                    </Link>
                </Heading>
            </GridItem>

            <GridItem gridArea="details">
                <Stack direction="row" flexWrap="wrap">
                    <TaskTypeTag type={task.type_id}/>
                    <TaskStatusTag status={task.status}/>
                    <Tag>
                        <TagLeftIcon as={TimeIcon}/>
                        <TagLabel>
                            updated&nbsp;
                            <TimeElapsed
                                timestamp={task.updated_at}
                                options={{ format: "compact", singleUnit: true }}
                            />
                        </TagLabel>
                    </Tag>
                    <Tag>
                        <TagLeftIcon as={TimeIcon}/>
                        <TagLabel>
                            created&nbsp;
                            <TimeElapsed
                                timestamp={task.created_at}
                                options={{ format: "compact", singleUnit: true }}
                            />
                        </TagLabel>
                    </Tag>
                </Stack>
            </GridItem>

            <GridItem gridArea="actions">
                <TaskActions task={task}>
                    <OptionsIcon/>
                </TaskActions>
            </GridItem>
        </Grid>
    )
}

export function TaskCardPreview({ task }: Omit<TaskPreviewProps, "viewMode">) {
    return (
        <Card>
            <CardHeader>
                <Grid
                    gridTemplateAreas={`
                        "title  actions"
                        "details details"
                    `}
                    gridTemplateColumns="1fr auto"
                    rowGap={2}
                >
                    <GridItem gridArea="title">
                        <Heading size="md">
                            <Link to={`/task/${task.id}`}>
                                {task.title}
                            </Link>
                        </Heading>
                    </GridItem>
                    <GridItem gridArea="details">
                        <Stack direction="row" flexWrap="wrap">
                            <TaskTypeTag type={task.type_id}/>
                            <TaskStatusTag status={task.status}/>
                            <Tag>
                                <TagLeftIcon as={TimeIcon}/>
                                <TagLabel>
                                    updated&nbsp;
                                    <TimeElapsed
                                        timestamp={task.updated_at}
                                        options={{ format: "compact", singleUnit: true }}
                                    />
                                </TagLabel>
                            </Tag>
                            <Tag>
                                <TagLeftIcon as={TimeIcon}/>
                                <TagLabel>
                                    created&nbsp;
                                    <TimeElapsed
                                        timestamp={task.created_at}
                                        options={{ format: "compact", singleUnit: true }}
                                    />
                                </TagLabel>
                            </Tag>
                        </Stack>
                    </GridItem>
                    <GridItem gridArea="actions">
                        <TaskActions task={task}>
                            <OptionsIcon/>
                        </TaskActions>
                    </GridItem>
                </Grid>
            </CardHeader>
            <Divider/>
            <CardBody>
                <MarkdownText>
                    {task.description}
                </MarkdownText>
            </CardBody>
        </Card>
    )
}