import { useMemo } from "react";
import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";

import { renderMarkdown } from "./renderMarkdown";


export interface MarkdownTextProps extends Omit<BoxProps, "dangerouslySetInnerHTML"> {
    variant?: "unstyled" | "card"
    children: string
    crop?: boolean;
}

export function MarkdownText({ variant = "unstyled", crop = false, children, ...rest }: MarkdownTextProps) {
    const renderedHtml = useMemo(
        () => renderMarkdown(children, { crop }),
        [children, crop]
    )
    const styles = useStyleConfig("MarkdownText", { variant })
    return (
        <Box className="loryth-markdown" __css={styles} {...rest} dangerouslySetInnerHTML={{ __html: renderedHtml }}/>
    )
}
