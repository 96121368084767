import { ReactNode } from "react";

import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";

export interface SectionBodyProps extends BoxProps {
    variant?: "standard" | "elevated" | "toolbar"
    children?: ReactNode
}

export function SectionBody({ variant, ...props }: SectionBodyProps) {
    const styles = useStyleConfig("SectionBody", { variant })
    return (
        <Box className="loryth-section__body" __css={styles} {...props} />
    )
}