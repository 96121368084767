import { useState } from "react";

import { useLocation } from "wouter";
import {
    Button,
    Center,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    Stack,
    Text,
} from "@chakra-ui/react";

import { AccountApi } from "@loryth/api";
import { AlertApiError, Link, Page, PasswordInput } from "@loryth/components";


export function LoginPage() {
    const [, navigate] = useLocation()
    const [signIn, { isLoading, isError, error }] = AccountApi.useSignInMutation()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const handleOnSubmit = async (): Promise<void> => {
        try {
            await signIn({ username, password }).unwrap()
            navigate("/home")
        } catch (error) {
            console.error("Failed to sign in.", error)
        }
    }

    return (
        <Page height="full">
            <Center paddingTop={{ lg: 160 }} height={{ base: "full", lg: "auto" }}>
                <Stack w={420} maxW="100%">
                    <Heading>
                        Welcome!
                    </Heading>
                    <Stack>
                        <FormControl>
                            <FormLabel> Username: </FormLabel>
                            <Input
                                value={username}
                                onChange={event => setUsername(event.target.value)}
                            />
                            <FormHelperText>
                                You may use your <Text as="span" textDecoration="underline">username or email</Text> to
                                login.
                            </FormHelperText>
                        </FormControl>
                        <FormControl>
                            <FormLabel> Password: </FormLabel>
                            <PasswordInput
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                            />
                        </FormControl>
                        <Button
                            variant="link" colorScheme="teal"
                            alignSelf="flex-end"
                            as={Link} to="/password-recovery"
                        >
                            Forgot password?
                        </Button>
                        <Button
                            variant="solid" colorScheme="teal"
                            onClick={handleOnSubmit}
                            isLoading={isLoading}
                            isDisabled={!username || !password || isLoading}
                        >
                            Submit
                        </Button>
                        <Text>Don't have an account yet? <Button
                            variant="link" colorScheme="teal"
                            as={Link} to="/register"
                        >
                            Sign up
                        </Button>
                        </Text>

                        {isError && (
                            <AlertApiError error={error}/>
                        )}
                    </Stack>
                </Stack>
            </Center>
        </Page>
    )
}