import { BreadcrumbItem, BreadcrumbItemProps, BreadcrumbLink, Icon, Text } from "@chakra-ui/react"

import { FilesystemNode } from "@loryth/api"
import { Link } from "@loryth/components"
import { findIconByName } from "@loryth/components/icons"


export interface FilesystemBreadcrumbItemProps extends BreadcrumbItemProps {
    node?: FilesystemNode | null
    to?: string
}

export function FilesystemBreadcrumbItem({ node = null, to, ...props }: FilesystemBreadcrumbItemProps) {
    if (to) {
        return (
            <BreadcrumbItem {...props}>
                <BreadcrumbLink
                    as={Link} to={to}
                    display="inline-flex" alignItems="center" gap={1}
                >
                    <Icon
                        as={findIconByName(node?.icon ?? node?.type ?? "folder")}
                        color={`${node?.color ?? "teal"}.400`}
                        fontSize="xl"
                    />
                    <Text as="span">{node?.name ?? "Files"}</Text>
                </BreadcrumbLink>
            </BreadcrumbItem>
        )
    }

    return (
        <BreadcrumbItem {...props}>
            <BreadcrumbLink
                sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: 1,
                }}
            >
                <Icon
                    as={findIconByName(node?.icon ?? node?.type ?? "folder")}
                    color={`${node?.color ?? "teal"}.400`}
                    fontSize="xl"
                />
                <Text as="span">{node?.name ?? "Files"}</Text>
            </BreadcrumbLink>
        </BreadcrumbItem>
    )
}