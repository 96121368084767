import Token from "markdown-it/lib/token";

export interface MarkdownItAstNode {
    tree: true
    nodeType: string
    openNode: Token
    closeNode: Token
    children: (MarkdownItAstNode | Token)[]
}

export const isNode = (node: MarkdownItAstNode | Token): node is MarkdownItAstNode => "tree" in node && node.tree;
export const isToken = (node: MarkdownItAstNode | Token): node is Token => !("tree" in node) || !node.tree;
