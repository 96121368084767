import { Shell, ShellBody, ShellHeader } from "@loryth/components";

import { AppHeader } from "./AppHeader";
import { AppRouter } from "./AppRouter";
import { AppSidebar } from "./AppSidebar";


export function AppShell() {
    return (
        <Shell>
            <ShellHeader>
                <AppHeader/>
            </ShellHeader>

            <AppSidebar/>

            <ShellBody>
                <AppRouter/>
            </ShellBody>
        </Shell>
    )
}

