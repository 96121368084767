import { Box, Button, ButtonGroup, Icon, Stack } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"

import { Timespan, Timestamp, useNow } from "@loryth/components"
import { MediaPauseIcon, MediaPlayIcon, MediaResetIcon, MediaStopIcon } from "@loryth/components/icons"
import { ProgressCircle } from "@loryth/components/ProgressCircle"
import { useEffect, useState } from "react"

type TimerStatus = "init" | "paused" | "active" | "completed"

export interface StopwatchTimerProps extends HTMLChakraProps<"div"> {
    status?: TimerStatus
    duration?: number
    lapDuration?: number

    onStatusChange?: (status: TimerStatus) => void
    onTimerReset?: () => void
}

export function StopwatchTimer({
    status: propStatus,
    duration: propDuration,
    lapDuration: propLapDuration,
    onStatusChange,
    onTimerReset,
    ...props
}: StopwatchTimerProps) {
    const now = useNow(1)

    const [stateStatus, setStatus] = useState<TimerStatus>("init")
    const [stateDuration, setDuration] = useState<number>(0)
    const [stateLapDuration] = useState<number>(60)

    useEffect(() => {
        if (propStatus || stateStatus !== "active") {
            // Nothing to do here...
            return
        }

        let lastUpdate = new Date().getTime()
        const handle = setInterval(() => {
            const currentTime = new Date().getTime()
            const delta = (currentTime - lastUpdate) / 1000
            lastUpdate = currentTime
            setDuration(prevValue => prevValue + delta)
        }, 1000)

        return () => clearInterval(handle)
    })

    const status = propStatus ?? stateStatus
    const duration = propDuration ?? stateDuration
    const lapDuration = propLapDuration ?? stateLapDuration

    const handleOnStatusChange = (status: TimerStatus) => {
        if (onStatusChange) {
            onStatusChange(status)
        } else {
            setStatus(status)
        }
    }

    const handleOnReset = () => {
        if (onTimerReset) {
            onTimerReset()
        } else {
            setStatus("active")
            setDuration(0)
        }
    }

    if (status === "init") {
        return (
            <Box
                __css={{
                    width: 250,
                    height: 250,
                    position: "relative",

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
                {...props}
            >
                <ProgressCircle
                    progress={0}
                    circleGap={0}
                    sx={{
                        position: "absolute",
                    }}
                />
                <Button
                    variant="ghost"
                    colorScheme="teal"
                    leftIcon={<Icon as={MediaPlayIcon}/>}
                    title="Start stopwatch"
                >
                    <Timestamp timestamp={now} format="HH:mm:ss" sx={{
                        fontFamily: "Roboto Mono",
                        fontSize: 24,
                        lineHeight: 1,
                    }}/>
                </Button>
            </Box>
        )
    }

    const pickOnStatus = <T, >(options: Record<Exclude<TimerStatus, "init">, T>): T => {
        return options[status]
    }

    return (
        <Box
            __css={{
                width: 250,
                height: 250,
                position: "relative",

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 4,
            }}
            {...props}
        >
            <ProgressCircle
                progress={(duration % lapDuration) / lapDuration}
                circleGap={0}
                sx={{
                    position: "absolute",
                }}
            />
            <Stack gap={1} alignItems="center">
                <Button
                    colorScheme="teal"
                    variant="ghost"
                    fontSize={24}
                    leftIcon={pickOnStatus({
                        "active": <Icon as={MediaPauseIcon}/>,
                        "paused": <Icon as={MediaPlayIcon}/>,
                        "completed": undefined,
                    })}
                    onClick={() => {
                        if (status === "active") {
                            handleOnStatusChange("paused")
                        } else if (status === "completed") {
                            handleOnStatusChange("active")
                        }
                    }}
                >
                    <Timespan value={duration * 1000}/>
                </Button>
                <Timestamp timestamp={now} format="HH:mm:ss" sx={{
                    fontFamily: "Roboto Mono",
                    fontSize: "xs",
                    lineHeight: 1,
                }}/>
            </Stack>
            <ButtonGroup size="xs">
                {(status === "active") && (
                    <Button
                        leftIcon={<Icon as={MediaPauseIcon}/>}
                        onClick={() => handleOnStatusChange("paused")}
                    >
                        PAUSE
                    </Button>
                )}
                {(status === "paused") && (
                    <Button
                        leftIcon={<Icon as={MediaPlayIcon}/>}
                        onClick={() => handleOnStatusChange("active")}
                    >
                        RESUME
                    </Button>
                )}
                {(status !== "completed") && (
                    <Button
                        leftIcon={<Icon as={MediaStopIcon}/>}
                        onClick={() => handleOnStatusChange("completed")}
                    >
                        STOP
                    </Button>
                )}
                {(status === "completed") && (
                    <Button
                        leftIcon={<Icon as={MediaResetIcon}/>}
                        onClick={handleOnReset}
                    >
                        RESET
                    </Button>
                )}
            </ButtonGroup>
        </Box>
    )
}
