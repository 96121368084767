import { isObject } from "./isObject";

export function deepFreeze<T extends object>(obj: T): Readonly<T> {
    const props = Reflect.ownKeys(obj)

    for (const prop of props) {
        const value = obj[prop as keyof T]
        if (isObject(value)) {
            deepFreeze(value)
        }
    }

    return Object.freeze(obj)
}