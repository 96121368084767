import { Children, ReactElement, ReactNode } from "react";

import { chakra, useMultiStyleConfig } from "@chakra-ui/react";

export type PageLayoutVariant = "standard" | "sidebar"
export type PageArea = "main" | "sidebar" | "breadcrumb"

export interface PageLayoutProps {
    variant?: PageLayoutVariant

    children?: ReactElement | (ReactElement | false)[] | false
}

export function PageLayout({ variant = "standard", children }: PageLayoutProps) {
    const areaMap: Record<string, ReactNode[]> = {}
    Children.forEach(children, (child) => {
        if (!child) {
            return
        }
        const area = getArea(child)
        if (!(area in areaMap)) {
            areaMap[area] = [child]
        } else {
            areaMap[area].push(child)
        }
    })

    const styles = useMultiStyleConfig('PageLayout', { variant })
    return (
        <chakra.div className="loryth-page__layout" __css={styles.layout}>
            {Object.entries(areaMap).map(([area, areaChildren]) => (
                <chakra.div key={area} className="loryth-page__layout_area" gridArea={area} __css={styles.area}>
                    {areaChildren}
                </chakra.div>
            ))}
        </chakra.div>
    )
}

function getArea(node: Exclude<ReactElement, boolean | null | undefined>) {
    return node?.props?.area ?? "main"
}

