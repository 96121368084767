import { Box, BoxProps, Icon, chakra } from "@chakra-ui/react";

import { IconType } from "../../icons";
import { Link } from "../../typography";
import { useStyles } from "./Navigation";

interface NavigationItemProps extends BoxProps {
    title: string
    icon: IconType
    link: string
    isActive?: boolean
    children?: never
}

export function NavigationItem({
    title,
    icon,
    link,
    isActive = false,
    ...rest
}: NavigationItemProps) {

    const styles = useStyles()
    return (
        <Box
            className="loryth-navigation__item"
            as={Link}
            to={link}
            data-active={isActive ? "" : undefined}
            __css={styles.item}
            {...rest}
        >
            {icon && (
                <Icon
                    fontSize="md"
                    _groupHover={{
                        color: 'inherit',
                    }}
                    as={icon}
                />
            )}
            <chakra.span flex={1}>
                {title}
            </chakra.span>
        </Box>
    )
}