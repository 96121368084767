import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers([
    "layout", "area"
])

export const PageLayoutStyle = defineMultiStyleConfig({
    baseStyle: {
        layout: {
            display: "grid",
            gap: 4,
            overflowY: "auto",
            overflowX: "hidden",
            height: "100%",
        },
        area: {
            display: "flex",
            flexDirection: "column",
            gap: 4,
            overflowY: "auto",
            overflowX: "hidden",
        },
    },
    variants: {
        standard: {
            layout: {
                gridTemplateAreas: `
                    "breadcrumb breadcrumb"
                    "main main"
                `,
                gridTemplateRows: "auto minmax(0, 1fr)",
            },
        },
        sidebar: {
            layout: {
                gridTemplateAreas: `
                    "breadcrumb breadcrumb"
                    "main sidebar"
                `,
                gridTemplateRows: "auto 1fr",
                gridTemplateColumns: "1fr auto",
            },
        },
    },
    defaultProps: {
        variant: "standard",
    },
})