import { parseApiTimestamp } from "@loryth/commons/time"
import { useState, KeyboardEvent, useMemo } from "react"

import { Center, Input, Spinner, Stack } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"

import { Timer, TimerApi } from "@loryth/api"

import { TimerPreview } from "../timer/TimerPreview"

export interface TimerWidgetListProps extends HTMLChakraProps<"div"> {
}

export function TimerWidgetList({ ...props }: TimerWidgetListProps) {
    const [label, setLabel] = useState<string>()
    const [createTimer] = TimerApi.useCreateMutation()

    const handleOnTimerCreate = async () => {
        const response = await createTimer({
            data: { metadata: { label } }
        })
        if ("data" in response) {
            setLabel("")
        }
    }

    const handleOnKeyDow = async (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault()
            await handleOnTimerCreate()
        }
    }

    const { isLoading, data } = TimerApi.useSearchQuery({})

    const incompleteTimers = useMemo(() => {
        if (!data) {
            return []
        }
        const results = data.results

        return sortTimersByCreatedAt(
            results.filter(timer => timer.status !== "completed")
        )
    }, [data])

    return (
        <Stack gap={2} {...props}>
            <Input
                placeholder="Pick a label..."
                value={label}
                onChange={event => setLabel(event.target.value)}
                onKeyDown={handleOnKeyDow}
            />
            {isLoading && (
                <Center flex="1">
                    <Spinner size="xl"/>
                </Center>
            )}
            {incompleteTimers.map(timer => (
                <TimerPreview
                    key={timer.id}
                    timer={timer}
                    padding={0}
                />
            ))}
        </Stack>
    )
}

function sortTimersByCreatedAt(results: Timer[]) {
    return [...results].sort((a, b) => {
        return parseApiTimestamp(b.created_at).getTime() - parseApiTimestamp(a.created_at).getTime()
    })
}