import { Avatar, AvatarProps } from "@chakra-ui/react";
import { Account } from "@loryth/api";


export interface AccountAvatarProps extends AvatarProps {
    account: Account
}

export function AccountAvatar({ account, ...props }: AccountAvatarProps) {
    return (
        <Avatar
            size="sm"
            name={account.username}
            {...props}
        />
    )
}