import {
    Alert, AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    Center,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputRightAddon,
    Stack,
    Text
} from "@chakra-ui/react";
import { AccountApi } from "@loryth/api";
import { isValidEmail } from "@loryth/commons/format";

import { AlertApiError, Link, Page } from "@loryth/components";
import { VisibilityOffIcon, VisibilityOnIcon } from "@loryth/components/icons";
import { useState } from "react";

export function RegistrationPage() {
    const [signUp, { isLoading, isError, error, isSuccess }] = AccountApi.useSignUpMutation()

    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)

    const handleOnSubmit = async (): Promise<void> => {
        try {
            await signUp({ username, email, password }).unwrap()
        } catch (error) {
            console.error("Failed to sign up.", error)
        }
    }

    return (
        <Page title="Registration - Loryth" height="full">
            <Center paddingTop={{ lg: 160 }} height={{ base: "full", lg: "auto" }}>
                <Stack w={420} maxW="100%">
                    <Heading>Welcome!</Heading>
                    <Text>Loryth is still a new platform and we grateful for your participation!</Text>
                    <FormControl>
                        <FormLabel>Username:</FormLabel>
                        <Input value={username} onChange={event => setUsername(event.target.value)}/>
                        <FormHelperText>Other users of this instance will be able to see it.</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Email:</FormLabel>
                        <Input value={email} onChange={event => setEmail(event.target.value)}
                               isInvalid={!isValidEmail(email)}/>
                        <FormHelperText>No spam, we promise!</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Password:</FormLabel>
                        <InputGroup>
                            <Input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                            />
                            <InputRightAddon
                                as={IconButton}
                                variant="ghost"
                                aria-label={
                                    showPassword ? "hide password" : "reveal password"
                                }
                                icon={
                                    <Icon as={showPassword ? VisibilityOffIcon : VisibilityOnIcon}/>
                                }
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </InputGroup>
                        <FormHelperText>Finding it hard to find choose a strong password?
                            <Button as="a" variant="link" size="md" colorScheme="teal" flexWrap="wrap">See our
                                guidelines.</Button></FormHelperText>
                    </FormControl>
                    <Button
                        variant="solid" colorScheme="teal"
                        isDisabled={
                            isLoading
                            || !username
                            || !isValidEmail(email)
                            || !password}
                        isLoading={isLoading}
                        onClick={handleOnSubmit}
                    >
                        Submit
                    </Button>
                    <Text>Do you have an account? <Link to="/login" color="teal.500">Sign in</Link></Text>
                    {isError && (
                        <AlertApiError error={error}/>
                    )}
                    {isSuccess && (
                        <Alert status="info">
                            <AlertIcon/>
                            <AlertTitle>Successfully created an account</AlertTitle>
                            <AlertDescription>One last step! An email has been sent to your inbox, click the
                                confirmation link to verify your email.</AlertDescription>
                        </Alert>
                    )}
                </Stack>
            </Center>
        </Page>
    )
}