import { ProjectApi } from "@loryth/api";
import { LoadingPage, Page } from "@loryth/components";
import { ProjectSettingsPage } from "../project-settings/ProjectSettingsPage";
import { TaskBacklogPage } from "../task-backlog/TaskBacklogPage";

interface ProjectRouteProps {
    params: {
        projectId: string
        tab?: "backlog" | "settings"
    }
}

export function ProjectRoute({ params: { projectId, tab } }: ProjectRouteProps) {
    const { isUninitialized, isLoading, isError, data: project } = ProjectApi.useGetQuery(projectId)

    if (isUninitialized || isLoading) {
        return <LoadingPage/>
    }

    if (isError) {
        return (
            <Page title="Not found - Loryth">
                Oops, something went wrong....
            </Page>
        )
    }

    if (tab === "settings") {
        return <ProjectSettingsPage project={project}/>
    }

    if (tab === undefined || tab === "backlog") {
        return <TaskBacklogPage project={project}/>
    }

}