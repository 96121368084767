import { Box, Heading, Stack, StackDivider } from "@chakra-ui/react";

import {
    NewButton,
    Page,
    PageLayout,
    SearchInput,
    Section,
    SectionBody,
    SectionTitle,
    FilterButton, IconBox
} from "@loryth/components";

import { CollectionPreview } from "./CollectionPreview";
import { CollectionItems, Collections } from "./Collections";


export function CollectionItemListingPage() {
    return (
        <Page>
            <PageLayout>
                <Section>
                    <SectionTitle>Collections</SectionTitle>
                    <SectionBody variant="toolbar">
                        <NewButton/>
                        <SearchInput/>
                        <FilterButton
                            aria-label="show filter"
                        />
                    </SectionBody>

                    <SectionBody variant="elevated">
                        <Stack divider={<StackDivider/>}>
                            {Collections.map(collection => (
                                <CollectionPreview
                                    key={collection.id}
                                    collection={collection}
                                />
                            ))}
                        </Stack>
                    </SectionBody>
                </Section>

                <Section>
                    <SectionTitle>Items</SectionTitle>
                    <SectionBody variant="toolbar">
                        <NewButton/>
                        <SearchInput/>
                        <FilterButton
                            aria-label="show filter"
                        />
                    </SectionBody>
                    <SectionBody variant="elevated">
                        <Stack divider={<StackDivider/>} spacing={2}>
                            {CollectionItems.map(item => (
                                <Box
                                    key={item.id}
                                    display="flex" flexDirection="row"
                                    alignItems="flex-start" gap={2}
                                >
                                    <IconBox
                                        icon={item.icon}
                                        colorScheme={item.color}
                                        // size={32}
                                    />
                                    <Box>
                                        <Heading size="md">{item.title}</Heading>
                                        <Box>{item.description}</Box>
                                    </Box>
                                </Box>
                            ))}
                        </Stack>
                    </SectionBody>
                </Section>
            </PageLayout>
        </Page>
    )
}