import {
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from "@chakra-ui/react";
import { FilesystemNode } from "@loryth/api";

import { FormDialog } from "@loryth/components";
import { FolderIcon, NewIcon, NoteIcon, UploadIcon } from "@loryth/components/icons";

import { NewFolderForm } from "./NewFolderForm";
import { NewNoteForm } from "../notebook-note/NewNoteForm";

import { UploadFileForm } from "./UploadFileForm";

export interface FilesystemNewButtonProps {
    parent: FilesystemNode | null
}

export function FilesystemNewButton({ parent }: FilesystemNewButtonProps) {
    return (
        <Menu>
            <MenuButton
                as={Button}
                variant="outline"
                colorScheme="teal"
                leftIcon={<NewIcon/>}
                flex="1 0 auto"
            >
                New
            </MenuButton>
            <MenuList>
                <FormDialog
                    title="Create a new folder..."
                    form={<NewFolderForm parent={parent}/>}
                    trigger={
                        <MenuItem icon={<FolderIcon/>}>New Folder</MenuItem>
                    }
                />
                <FormDialog
                    title="Create a new file..."
                    form={<NewNoteForm parent={parent}/>}
                    trigger={
                        <MenuItem icon={<NoteIcon/>}>New Note</MenuItem>
                    }
                />
                <FormDialog
                    title="Upload file..."
                    form={<UploadFileForm parent={parent}/>}
                    trigger={
                        <MenuItem icon={<UploadIcon/>}>Upload</MenuItem>
                    }
                />
            </MenuList>
        </Menu>

    )
}