import { HTMLChakraProps } from "@chakra-ui/system"
import { Timer, TimerApi } from "@loryth/api"

import { StopwatchTimer } from "./StopwatchTimer"
import { TimerMetadata } from "./TimerMetadata"
import { useTimerHeartbeat } from "./useTimerHeartbeat"


export interface TimerControllerProps extends HTMLChakraProps<"div"> {
    newTimerMetadata?: Record<string, unknown>
    timer?: Timer
}

export function TimerController({ timer, newTimerMetadata = {}, ...props }: TimerControllerProps) {
    const [createTimer] = TimerApi.useCreateMutation()
    const [pauseTimer] = TimerApi.usePauseMutation()
    const [completeTimer] = TimerApi.useCompleteMutation()
    const [resumeTimer] = TimerApi.useResumeMutation()
    const timerType = TimerMetadata.type.get(timer?.metadata ?? {})

    const duration = useTimerHeartbeat(timer) / 1000

    const handleOnTimerCreate = () => createTimer({
        data: { metadata: newTimerMetadata }
    })

    const handleOnTimerStatusChange = async (status: Timer["status"]) => {
        if (!timer) {
            return
        }

        if (timer.status === "active" && status === "paused") {
            await pauseTimer(timer.id)
        } else if (timer.status === "paused" && status === "active") {
            await resumeTimer(timer.id)
        } else if (status === "completed" && timer.status !== "completed") {
            await completeTimer(timer.id)
        }
    }

    if (timerType !== "stopwatch") {
        console.warn(`Unsupported timer type: ${timerType}`)
    }

    const lapDuration = TimerMetadata.stopwatch.lapDurationSeconds.get(timer?.metadata ?? {})
    return (
        <StopwatchTimer
            status={timer?.status ?? "init"}
            duration={duration}
            lapDuration={lapDuration}
            onStatusChange={status => {
                if (!timer && status === "active") {
                    return handleOnTimerCreate()
                }
                if (timer && status !== "init") {
                    return handleOnTimerStatusChange(status)
                }
            }}
            {...props}
        />
    )
}