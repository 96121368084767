import { extendTheme, StyleFunctionProps, withDefaultVariant } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools"

import "@fontsource/roboto/400.css"
import "@fontsource/roboto-mono/400.css"

import { components } from "./components";

export const theme = extendTheme({
        fonts: {
            body: "'Roboto', sans-serif",
        },
        components,
        styles: {
            global: (props: StyleFunctionProps) => ({
                html: {
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                },
                body: {
                    bg: mode("rgb(245, 245, 245)", "black")(props),

                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                },
                "#root": {
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                },
            })
        }
    },
    // TODO(souperk): Experiment with changing the default color scheme.
    // withDefaultColorScheme({ colorScheme: "teal" }),
    withDefaultVariant({
        variant: "outline",
        components: ["Input", "NumberInput", "PinInput", "Button"]
    }),
)