import { ReactNode } from "react";

import { Grid } from "@chakra-ui/react";

import { ShellContextProvider } from "./ShellContext";


export interface ShellProps {
    children: ReactNode
}

export function Shell({ children }: ShellProps) {

    return (
        <ShellContextProvider>
            <Grid
                className="loryth-shell"
                width="100%"
                height="100%"
                overflow="auto"

                gridTemplateAreas={{
                    base: `
                        "header"
                        "body"
                    `,
                    lg: `
                        "header header"
                        "sidebar body"
                    `,
                }}
                gridTemplateRows="auto 1fr"
                gridTemplateColumns={{
                    base: "1fr",
                    lg: "auto 1fr",
                }}
                columnGap={2}
            >
                {children}
            </Grid>
        </ShellContextProvider>
    )
}