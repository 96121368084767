import { ButtonGroup, ButtonGroupProps, Icon, IconButton } from "@chakra-ui/react";

import { BoxViewIcon, ListViewIcon } from "../icons";


export type ViewMode = "list" | "box"

export interface ViewModeInputProps extends Omit<ButtonGroupProps, "onChange"> {
    value: ViewMode
    onChange: (value: ViewMode) => void
}

export function ViewModeInput({ value, onChange, ...props }: ViewModeInputProps) {
    return (
        <ButtonGroup isAttached {...props}>
            <IconButton
                aria-label="list view"
                icon={<Icon as={ListViewIcon}/>}

                isActive={value === "list"}
                onClick={() => onChange("list")}
            />
            <IconButton
                aria-label="box view"
                icon={<Icon as={BoxViewIcon}/>}

                isActive={value === "box"}
                onClick={() => onChange("box")}
            />
        </ButtonGroup>
    )

}