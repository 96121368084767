import { MouseEvent } from "react"
import { skipToken } from "@reduxjs/toolkit/query";

import { Breadcrumb, BreadcrumbProps, Spinner } from "@chakra-ui/react";

import { FilesystemApi, FilesystemNode } from "@loryth/api";
import { AlertApiError } from "@loryth/components";

import { FilesystemBreadcrumbItem } from "./FilesystemBreadcrumbItem"


export interface FilesystemBreadcrumbProps extends BreadcrumbProps {
    nodeId: string | null
    to?: (node: FilesystemNode | null) => string
    onItemClick?: (event: MouseEvent, node: FilesystemNode | null) => void
}

export function FilesystemBreadcrumb({ nodeId, to, onItemClick, ...props }: FilesystemBreadcrumbProps) {
    const { isUninitialized, isLoading, isError, error, data } = FilesystemApi.useGetPathQuery(nodeId ?? skipToken)
    const getLink = (node: FilesystemNode | null) => {
        if (!to) {
            return undefined
        }

        return to(node)
    }

    if (!nodeId) {
        return (
            <Breadcrumb {...props}>
                <FilesystemBreadcrumbItem
                    to={getLink(null)}
                    onClick={(event) => onItemClick?.(event, null)}
                />
            </Breadcrumb>
        )
    }

    if (isUninitialized || isLoading) {
        return (
            <Spinner/>
        )
    }

    if (isError) {
        return (
            <AlertApiError error={error}/>
        )
    }


    return (
        <Breadcrumb {...props}>
            <FilesystemBreadcrumbItem
                to={getLink(null)}
                onClick={(event) => onItemClick?.(event, null)}
            />
            {data.map(node => (
                <FilesystemBreadcrumbItem
                    key={node.id}
                    node={node}
                    to={getLink(node)}
                    onClick={(event) => onItemClick?.(event, node)}
                />
            ))}
        </Breadcrumb>
    )
}