import { Tag, TagLabel, TagProps } from "@chakra-ui/react"
import { getLabelColor } from "./getLabelColor"


export interface TimerLabelTagProps extends TagProps {
    label: string
}

export function TimerLabelTag({ label, children, ...props }: TimerLabelTagProps) {
    const colorScheme = getLabelColor(label)

    return (
        <Tag
            colorScheme={colorScheme}
            {...props}
        >
            <TagLabel>{label}</TagLabel>
            {children}
        </Tag>
    )
}