import { createContext, SyntheticEvent, useContext } from "react";

import { createStylesContext } from "@chakra-ui/react";

export const [CollapsibleStylesProvider, useCollapsibleStyles] = createStylesContext("Collapsible")


interface CollapsibleContextType {
    isOpen: boolean
    onToggle: (event: SyntheticEvent<HTMLButtonElement>) => void
}

export const CollapsibleContext = createContext<CollapsibleContextType | null>(null)

export function useCollapsibleContext(): CollapsibleContextType {
    const context = useContext(CollapsibleContext)
    if (!context) {
        throw Error("Collapsible context is not defined.")
    }
    return context
}
