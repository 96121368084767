const UnusedSymbol: unique symbol = Symbol("dont-use-this")
export type CommandKey<Payload> = symbol & { [UnusedSymbol]: Payload }

export function createCommandKey<Payload = void>(key: string): CommandKey<Payload> {
    return Symbol(key) as CommandKey<Payload>
}

export interface Command<Payload> {
    key: CommandKey<Payload>
    payload: Payload
}

export type CommandHandler<Payload> = (command: Command<Payload>) => void

export type CommandTrigger<Payload> = Payload extends void ? () => void : (payload: Payload) => void


export type CommandCleanup = () => void

export class CommandRegistry {
    private map = new Map<CommandKey<unknown>, CommandHandler<unknown>>()

    constructor(private parent: CommandRegistry | null) {
    }

    subscribe<Payload>(key: CommandKey<Payload>, handler: CommandHandler<Payload>): CommandCleanup {
        if (this.map.has(key)) {
            throw new Error(`Command '${key.description}' already registered.`)
        }
        this.map.set(key, handler as CommandHandler<unknown>)

        return () => {
            this.map.delete(key)
        }
    }

    trigger<Payload>(key: CommandKey<Payload>, payload: Payload) {
        const command: Command<Payload> = { key, payload }

        if (this.map.has(key)) {
            const handler = this.map.get(key) as CommandHandler<Payload>
            handler(command)
        } else if (this.parent) {
            this.parent.trigger(key, payload)
        }
    }
}