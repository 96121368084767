import { forwardRef, ReactNode, useImperativeHandle, useRef } from "react";

import {
    Button, ButtonGroup,
    Divider, Icon,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";

import { useCommandTrigger, useCommandRegistry, createCommandKey } from "../../commons/command";
import { FormContextProvider } from "../forms/FormContext";
import { CancelIcon, SaveIcon } from "../icons";

import { Modal, ModalProps, ModalRef } from "./Modal";

export const FormSubmitCommand = createCommandKey("form:submit")

export interface FormDialogProps extends Omit<ModalProps, "ref" | "children"> {
    title: string
    form: ReactNode
}

export const FormDialog = forwardRef<ModalRef | undefined, FormDialogProps>(({ title, form, ...props }, ref) => {
    const commandRegistry = useCommandRegistry({ create: true })
    const submitCommand = useCommandTrigger(FormSubmitCommand, commandRegistry)

    const modal = useRef<ModalRef>()
    useImperativeHandle(ref, () => modal.current)

    const handleOnSubmit = () => {
        submitCommand()
    }
    const handleOnClose = () => {
        modal.current?.close()
    }

    return (
        <Modal ref={modal} size="xl" {...props}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton/>
                <Divider/>
                <ModalBody autoFocus>
                    <FormContextProvider
                        value={{
                            onSubmit: submitCommand,
                            onSubmitSuccess() {
                                console.log("submitted: closing dialog")
                                modal.current?.close()
                            },
                            onSubmitError() {
                            }
                        }}
                    >
                        {form}
                    </FormContextProvider>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup colorScheme="teal">
                        <Button
                            variant="solid"
                            onClick={handleOnSubmit}
                            leftIcon={<Icon as={SaveIcon}/>}
                        >
                            Done
                        </Button>
                        <Button
                            onClick={handleOnClose}
                            leftIcon={<Icon as={CancelIcon}/>}
                        >
                            Cancel
                        </Button>
                    </ButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
})
