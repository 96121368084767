import { Center, Spinner, Stack, Text } from "@chakra-ui/react";

import { Page } from "./Page";


export interface LoadingPageProps {
    title?: string
    description?: string
}

export function LoadingPage({
    title = "Loading... - Loryth",
    description = "Please wait while Loryth is loading..."
}: LoadingPageProps) {
    return (
        <Page title={title} height="full">
            <Center minHeight={400}>
                <Stack gap={4} alignItems="center">
                    <Spinner size="xl"/>
                    <Text fontSize="2xl">{description}</Text>
                </Stack>
            </Center>
        </Page>
    )
}