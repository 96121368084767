import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { ApiError } from "@loryth/commons/api";

export interface AlertApiErrorProps {
    error: ApiError | undefined
}

export function AlertApiError({ error }: AlertApiErrorProps) {
    const message = getErrorMessage(error)
    return (
        <Alert status="error">
            <AlertIcon/>
            <AlertTitle>{message}</AlertTitle>
        </Alert>
    )
}

function getErrorMessage(error: ApiError | undefined): string | undefined {
    if (!error) {
        return "Something went wrong..."
    }
    if (isSerializedError(error)) {
        return error.message
    }
    if (isFetchError(error) && error.data && typeof error.data === "object" && "message" in error.data) {
        return error.data.message as string
    }
    return "Something went wrong..."
}

function isSerializedError(error: ApiError): error is SerializedError {
    return !isFetchError(error)
}

function isFetchError(error: ApiError): error is FetchBaseQueryError {
    return "status" in error
}

