import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import { NewIcon } from "../icons";

export function NewButton({ children = "New", ...props }: ButtonProps) {
    return (
        <Button
            variant="solid"
            colorScheme="teal"
            leftIcon={<Icon as={NewIcon}/>}
            {...props}
        >
            {children}
        </Button>

    )
}