import { Task } from "@loryth/api";

export function getStatusColor(status: Task["status"]) {
    switch (status) {
        case "open":
            return "blue"
        case "in-progress":
            return "orange"
        case "completed":
            return "green"
        case "closed":
            return "gray"
        default:
            return undefined;
    }
}