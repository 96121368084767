import { useState } from "react";

import { Button, ButtonGroup, FormControl, FormLabel, Input, Switch } from "@chakra-ui/react";

import { AdminSettings, SettingsApi } from "@loryth/api";
import { Page, PageLayout, Section, SectionBody, SectionTitle } from "@loryth/components";
import { PasswordInput } from "@loryth/components/input";

function AdminSettingsPage() {
    const { isSuccess, data } = SettingsApi.useGetQuery()

    return (
        <Page title="Admin Settings - Loryth">
            <PageLayout>
                {isSuccess && (
                    <AdminSettingsEmailSection settings={data["admin.email.sender"]}/>
                )}
            </PageLayout>
        </Page>
    )
}

export function AdminSettingsEmailSection({ settings }: { settings: AdminSettings["admin.email.sender"] }) {
    const [updateSettings] = SettingsApi.useUpdateMutation()

    const [server, setServer] = useState<string>(settings.server)
    const [port, setPort] = useState<number>(settings.port)
    const [username, setUsername] = useState<string>(settings.username)
    const [password, setPassword] = useState<string>(settings.password)
    const [useTls, setUseTls] = useState<boolean>(settings.use_tls)
    const [useSsl, setUseSsl] = useState<boolean>(settings.use_ssl)

    const handleOnSubmit = async () => {
        try {
            await updateSettings({
                payload: {
                    "admin.email.sender": {
                        ...settings,
                        server,
                        port,
                        username,
                        password,
                        use_tls: useTls,
                        use_ssl: useSsl,
                    }
                }
            }).unwrap()
        } catch (error) {
            console.error("Failed to update settings.", error)
        }
    }

    const handleOnCancel = () => {
        setServer(settings.server)
        setPort(settings.port)
        setUsername(settings.username)
        setPassword(settings.password)
        setUseTls(settings.use_tls)
        setUseSsl(settings.use_ssl)
    }

    return (
        <Section>
            <SectionTitle>Email Notifications</SectionTitle>

            <SectionBody variant="elevated">
                <FormControl>
                    <FormLabel>Server</FormLabel>
                    <Input value={server} onChange={event => setServer(event.target.value)}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Port</FormLabel>
                    <Input
                        type="number"
                        value={port}
                        onChange={event => setPort(parseInt(event.target.value, 10))}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Username</FormLabel>
                    <Input
                        value={username}
                        onChange={event => setUsername(event.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <PasswordInput
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>TLS</FormLabel>
                    <Switch
                        isChecked={useTls}
                        onChange={event => setUseTls(event.target.checked)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>SSL</FormLabel>
                    <Switch
                        isChecked={useSsl}
                        onChange={event => setUseSsl(event.target.checked)}
                    />
                </FormControl>

                <ButtonGroup colorScheme="teal">
                    <Button variant="solid" onClick={handleOnSubmit}>Submit</Button>
                    <Button onClick={handleOnCancel}>Cancel</Button>
                </ButtonGroup>
            </SectionBody>
        </Section>
    )
}

export function AdminSettingsRoute() {
    return <AdminSettingsPage/>
}