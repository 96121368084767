import { Box } from "@chakra-ui/react"
import { HTMLChakraProps } from "@chakra-ui/system"

import { useWidget, WidgetProvider } from "./context"


export interface WidgetProps extends HTMLChakraProps<"div"> {
    initialIsOpen?: boolean
}

export function Widget({ initialIsOpen, ...props }: WidgetProps) {
    const context = useWidget({
        initialIsOpen,
    })
    return (
        <WidgetProvider value={context}>
            <Box
                className="loryth-widget"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                }}
                {...props}
            />
        </WidgetProvider>
    )
}