import { ReactNode, useRef } from "react";

import { Box, BoxProps } from "@chakra-ui/react";

import { SidebarContext } from "./SidebarContext";


export interface SidebarProps extends BoxProps {
    children: ReactNode
}

export function Sidebar({ children, ...props }: SidebarProps) {
    const headerRef = useRef<HTMLDivElement | null>(null)
    const bodyRef = useRef<HTMLDivElement | null>(null)
    const footerRef = useRef<HTMLDivElement | null>(null)

    return (
        <SidebarContext.Provider value={{ headerRef, bodyRef, footerRef }}>
            <Box
                className="loryth-sidebar"
                gridArea="sidebar"
                bg="white"
                display="flex"
                flexDirection="column"
                px={2} py={2} overflow="auto"
                gap={2}
                {...props}
            >
                <Box ref={headerRef}/>
                <Box flex={1} ref={bodyRef}/>
                <Box ref={footerRef}/>
                {children}
            </Box>
        </SidebarContext.Provider>
    )
}
