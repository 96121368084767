import {
    cloneElement,
    forwardRef,
    ReactElement,
    ReactNode,
    useCallback,
    useImperativeHandle,
    useState
} from "react";

import { Box, Modal as ChakraModal, ModalProps as ChakraModalProps } from "@chakra-ui/react"


export interface ModalTriggerProps {
    onClick?: () => void
}

export interface ModalRef {
    isOpen: boolean

    open(): void

    close(): void
}

export interface ModalProps extends Omit<ChakraModalProps, "ref" | "isOpen" | "onClose" | "children"> {
    isOpen?: ChakraModalProps["isOpen"]
    onOpen?: () => void
    onClose?: () => void

    trigger?: ReactElement<ModalTriggerProps>

    children?: ReactNode
}

export const Modal = forwardRef<ModalRef | undefined, ModalProps>(({
    isOpen: propIsOpen,
    onOpen,
    onClose,
    trigger,
    children,
    ...props
}, ref) => {
    const [open, setOpen] = useState(propIsOpen ?? false)

    const handleOnOpen = useCallback(() => {
        if (onOpen) {
            onOpen()
        } else {
            setOpen(true)
        }
    }, [onOpen])

    const handleOnClose = useCallback(() => {
        if (onClose) {
            onClose()
        } else {
            setOpen(false)
        }
    }, [onClose])

    useImperativeHandle(ref, () => {
        return {
            isOpen: open,
            open: handleOnOpen,
            close: handleOnClose,
        }
    }, [open, handleOnOpen, handleOnClose])

    return (
        <>
            {trigger && cloneElement(trigger, { onClick: handleOnOpen })}
            <ChakraModal
                isOpen={propIsOpen ?? open}
                onClose={handleOnClose}
                {...props}
            >
                <Box sx={{ display: "flex", width: "100vw", height: "100vh" }}>
                    {children}
                </Box>
            </ChakraModal>
        </>
    )
})