import { useCallback, useEffect, useRef, useState } from "react";

import { Timer, TimerApi } from "@loryth/api";
import { parseApiTimestamp } from "@loryth/commons/time";
import { useTick } from "@loryth/components";


export function useTimerHeartbeat(timer?: Timer): number {
    const [heartbeat] = TimerApi.useHeartbeatMutation()

    const [duration, setDuration] = useState((timer?.duration ?? 0) * 1000)
    const heartbeatRef = useRef<{
        heartbeat: Date | null
        duration: number
    }>()
    useEffect(() => {
        if (!timer) {
            return
        }

        heartbeatRef.current = {
            heartbeat: parseApiTimestamp(timer.heartbeat),
            duration: timer.duration * 1000,
        }
        setDuration(timer.duration * 1000)
    }, [timer])

    const tick = useCallback(async () => {
        if (!heartbeatRef.current?.heartbeat) {
            return
        }

        const previousTime = heartbeatRef.current.heartbeat
        const currentTime = new Date()
        const timeDelta = (currentTime.getTime() - previousTime.getTime())
        heartbeatRef.current.duration += timeDelta
        heartbeatRef.current.heartbeat = currentTime

        // trigger a component render
        setDuration(heartbeatRef.current.duration)
    }, [heartbeatRef])
    useTick(tick, 500)

    const apiHeartbeat = useCallback(async () => {
        if (timer?.status !== "active") {
            return
        }
        await heartbeat(timer.id)
    }, [heartbeat, timer])
    useTick(apiHeartbeat, 5000)

    return duration
}
