import { Box } from "@chakra-ui/react"


import { FilesystemNode } from "@loryth/api";
import { Page } from "@loryth/components";

import { NotebookNotePage } from "../notebook-note/NotebookNotePage";
import { FilesystemFolderPage } from "./FilesystemFolderPage";
import { FilesystemBreadcrumb } from "./FilesystemBreadcrumb";
import { FilesystemImagePage } from "./FilesystemImagePage";
import { nodeIsImage } from "./utils";


export interface FilesystemNodePageProps {
    node: FilesystemNode
}

export function FilesystemNodePage({ node }: FilesystemNodePageProps) {
    if (node.type === "folder") {
        return <FilesystemFolderPage node={node}/>
    }

    if (node.type === "note") {
        return <NotebookNotePage node={node}/>
    }

    if (nodeIsImage(node)) {
        return <FilesystemImagePage node={node}/>

    }

    return (
        <Page title={`${node.name} - Notebook`}>
            <FilesystemBreadcrumb
                nodeId={node.id}
                to={node => node ? `/files/${node.id}` : "/files"}
            />
            <Box>
                Unknown type of node '{node.type}'.
            </Box>
        </Page>
    )
}

