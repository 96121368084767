import { useCallback } from "react";

import { TaskOrder, TaskStatus } from "@loryth/api";

import { useAppDispatch, useAppSelector } from "../../store";

import { TaskFeature } from "./task-feature.slice";

export function useTaskFilter(filterId: string) {
    const dispatch = useAppDispatch()
    const filter = useAppSelector(
        state => TaskFeature.selectFilter(state, filterId),
    )
    const { status = [], order, types = [], projects = [] } = filter

    const statusIsActive = (value: TaskStatus) => status.includes(value)
    const toggleStatus = useCallback((
        ...values: TaskStatus[]
    ) => {
        dispatch(TaskFeature.toggleFilterStatus({
            filterId,
            status: values,
        }))
    }, [dispatch, filterId])

    const orderIsSelected = (value: TaskOrder["property"]) => order.property === value
    const toggleOrder = useCallback((
        property: TaskOrder["property"],
    ) => {
        dispatch(TaskFeature.toggleFilterOrder({
            filterId,
            property,
        }))
    }, [dispatch, filterId])

    const typeIsSelected = (value: string) => types.includes(value)
    const toggleType = useCallback((
        ...types: string[]
    ) => {
        dispatch(TaskFeature.toggleFilterType({
            filterId,
            types: types,
        }))
    }, [dispatch, filterId])

    const projectIsSelected = (value: string) => projects.includes(value)
    const toggleProject = useCallback((
        ...projects: string[]
    ) => {
        dispatch(TaskFeature.toggleFilterProject({
            filterId,
            projects: projects,
        }))
    }, [dispatch, filterId])

    return {
        status,
        statusIsActive,
        toggleStatus,
        order,
        orderIsSelected,
        toggleOrder,
        types,
        typeIsSelected,
        toggleType,
        projects,
        projectIsSelected,
        toggleProject,
    }
}