import { Text, TextProps } from "@chakra-ui/react"

import { formatDuration } from "../../commons/format";

export interface TimespanProps extends TextProps {
    value: number

    // TODO(souperk): Remove prop
    TextProps?: TextProps
}

export function Timespan({ value, TextProps = {}, ...props }: TimespanProps) {
    return (
        <Text as="span" fontFamily="'Roboto Mono', mono" {...TextProps} {...props}>
            {formatDuration(value / 1000)}
        </Text>
    )
}