import { lorythApi } from "./_api";
import { FileContent, FilesystemNode, FilesystemTree } from "./filesystem";


interface FilesystemTreeGetRequest {
    parentId?: string | null
}

interface FilesystemNodeUpsertRequest {
    id?: string
    node: Omit<FilesystemNode, "id" | "created_at" | "updated_at">
}

interface FilesystemUploadRequest {
    nodeId: string
    contents: File
}


export function url(nodeId: string) {
    return `/api/v1/filesystem/download/${nodeId}`
}

const filesystemApi = lorythApi.enhanceEndpoints({
    addTagTypes: ["filesystem"]
}).injectEndpoints({
    endpoints: builder => ({
        filesystemNodeGet: builder.query<FilesystemNode, string>({
            query: nodeId => `/api/v1/filesystem/${nodeId}`,
            providesTags: ["filesystem"],
        }),
        filesystemNodePathGet: builder.query<FilesystemNode[], string>({
            query: nodeId => `/api/v1/filesystem/${nodeId}/path`,
            providesTags: ["filesystem"],
        }),
        filesystemNodeUpsert: builder.mutation<FilesystemNode, FilesystemNodeUpsertRequest>({
            query: request => ({
                url: request.id ? `/api/v1/filesystem/${request.id}` : "/api/v1/filesystem",
                method: request.id ? "put" : "post",
                body: request.node,
            }),
            invalidatesTags: ["filesystem"],
        }),
        filesystemNodeDelete: builder.mutation<void, string>({
            query: nodeId => ({
                url: `/api/v1/filesystem/${nodeId}`,
                method: "delete",
            }),
            invalidatesTags: ["filesystem"],
        }),
        filesystemDownload: builder.query<FileContent, string>({
            async queryFn(nodeId, _1, _2, baseQuery) {
                const { data, error, meta } = await baseQuery({
                    url: `/api/v1/filesystem/download/${nodeId}`,
                    method: "get",
                    responseHandler: response => {
                        return response.blob()
                    },
                })

                if (error) {
                    return { error, meta }
                }

                const contents = await (data as Blob).text()
                return { data: contents, meta }
            },
            providesTags: ["filesystem"],
        }),
        filesystemUpload: builder.mutation<void, FilesystemUploadRequest>({
            query: (request) => {
                const data = new FormData()
                data.set("file", request.contents)
                return {
                    url: `/api/v1/filesystem/upload/${request.nodeId}`,
                    method: "POST",
                    body: data,
                }
            },
            invalidatesTags: ["filesystem"],
        }),
        filesystemTreeGet: builder.query<FilesystemTree, FilesystemTreeGetRequest>({
            query: ({ parentId }) => (
                parentId ?
                    `/api/v1/filesystem/${parentId}/tree`
                    : "/api/v1/filesystem/root/tree"
            ),
            providesTags: ["filesystem"],
        }),
    })
})


export const FilesystemApi = {
    _internal: filesystemApi,
    useLazyGetQuery: filesystemApi.useLazyFilesystemNodeGetQuery,
    useGetNodeQuery: filesystemApi.useFilesystemNodeGetQuery,
    useUpsertNodeMutation: filesystemApi.useFilesystemNodeUpsertMutation,
    useDeleteNodeMutation: filesystemApi.useFilesystemNodeDeleteMutation,
    useGetTreeQuery: filesystemApi.useFilesystemTreeGetQuery,
    useGetPathQuery: filesystemApi.useFilesystemNodePathGetQuery,
    useUploadMutation: filesystemApi.useFilesystemUploadMutation,
    useDownloadQuery: filesystemApi.useFilesystemDownloadQuery,
    url: url,
}
