import { createContext, useContext } from "react";

interface SelectContextProps {
}

export const SelectContext = createContext<SelectContextProps | null>(null)

export const SelectContextProvider = SelectContext.Provider

export function useSelectContext() {
    const context = useContext(SelectContext)
    if (!context) {
        throw new Error("SelectContext must be initialized")
    }
    return context
}