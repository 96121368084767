import { Tag, TagLabel, TagProps } from "@chakra-ui/react";

import { Account } from "@loryth/api";

import { AccountAvatar } from "./AccountAvatar";


export interface AccountTagProps extends TagProps {
    account: Account
}

export function AccountTag({ account, children, ...props }: AccountTagProps) {
    return (
        <Tag size="lg" {...props}>
            <AccountAvatar
                account={account}
                size="xs"
                ml={-1}
                mr={2}
            />
            <TagLabel>
                {account.username}
            </TagLabel>
            {children}
        </Tag>
    )
}