import { Icon, Menu, MenuButton, MenuItem, MenuList, TagProps, TagRightIcon } from "@chakra-ui/react";

import { Account, AccountApi } from "@loryth/api";
import { AdministratorIcon, ExpandIcon, UserIcon } from "@loryth/components/icons";

import { AccountRoleTag } from "./AccountRoleTag";

export interface AccountRoleMenuProps extends TagProps {
    account: Account
}

export function AccountRoleMenu({ account, ...props }: AccountRoleMenuProps) {
    const [updateAccount] = AccountApi.useAdminUpdateAccountMutation()

    const handleOnRoleChange = async (role: string) => {
        try {
            await updateAccount({
                accountId: account.id,
                data: {
                    role: role,
                }
            }).unwrap()
        } catch (error) {
            console.error("failed to update account", error)
        }
    }
    return (
        <Menu>
            <MenuButton>
                <AccountRoleTag account={account} width={32}  {...props}>
                    <TagRightIcon as={ExpandIcon} ml="auto"/>
                </AccountRoleTag>
            </MenuButton>
            <MenuList>
                <MenuItem
                    icon={<Icon as={UserIcon}/>}
                    onClick={() => handleOnRoleChange("user")}
                    isDisabled={account.role === "user"}
                >
                    User
                </MenuItem>
                <MenuItem
                    icon={<Icon as={AdministratorIcon}/>}
                    onClick={() => handleOnRoleChange("admin")}
                    isDisabled={account.role === "admin"}
                >
                    Administrator
                </MenuItem>
            </MenuList>
        </Menu>
    )
}