import { PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, Stack } from "@chakra-ui/react";
import { ReactNode } from "react";


export interface SelectContentProps {
    title?: string
    children: ReactNode
}

export function SelectContent({ title, children }: SelectContentProps) {
    return (
        <PopoverContent width="full">
            <PopoverArrow/>
            <PopoverCloseButton/>
            {title && (
                <PopoverHeader>{title}</PopoverHeader>
            )}
            <PopoverBody>
                <Stack gap={1}>
                    {children}
                </Stack>
            </PopoverBody>
        </PopoverContent>
    )
}